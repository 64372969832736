import { zodResolver } from "@hookform/resolvers/zod";
import { Box, useMediaQuery, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { profileAvatar, profileHandImage } from "../../../assets/images";
import { EllipseLargeIcon, EllipseSmallIcon } from "../../../icons";
import {
  CustomButton,
  CustomContainer,
  CustomHeading,
  CustomSubHeading,
  CustomText,
} from "../../../ui";
import ProfileInfoForm from "./profile-info-form";
import CompanyDetailForm from "./company-detail-form";
import {
  getCities,
  getCountries,
  setupProfile,
  updateProfile,
  viewProfile,
} from "../../../api/profile-api";
import { showToast } from "../../../utils/showToast.util";
import { useLocation, useNavigate } from "react-router-dom";
import useStorage from "../../../utils/useStorage.util";
import { useQuery } from "@tanstack/react-query";
import { useUserContext } from "../../../context/user-context";
const schema = z.object({
  key: z.string().optional(),
  firstName: z
    .string()
    .min(1, "First name is required")
    .max(50, "First name cannot exceed 50 characters")
    .regex(/^[a-zA-Z ]+$/, "Only alphabetic characters are allowed"),
  lastName: z
    .string()
    .min(1, "Last name is required")
    .max(50, "Last name cannot exceed 50 characters")
    .regex(/^[a-zA-Z ]+$/, "Only alphabetic characters are allowed"),
  email: z.string().email("Invalid email address"),
  phoneNumber: z
    .string()
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number cannot exceed 15 digits"),
  country: z.string().min(1, "Country selection is required"),
  city: z.string().min(1, "City selection is required"),
  companyName: z
    .string()
    .min(1, "Company name is required")
    .max(150, "Company name cannot exceed 150 characters"),
  companyAddress: z
    .string()
    .min(1, "Company address is required")
    .max(255, "Company address cannot exceed 255 characters"),
  companySize: z
    .string()
    .min(1, "Company size must be at least 1")
    .max(10, "Company size cannot exceed 10 digits"),
  industrySector: z
    .string()
    .min(3, "Industry sector must be at least 3 characters")
    .max(50, "Industry sector cannot exceed 50 characters"),
  headquartersLocation: z
    .string()
    .min(1, "Headquarters Location is required")
    .max(50, "Headquarters Location cannot exceed 50 characters"),
});

type FormData = z.infer<typeof schema>;

interface UserProfileProps {}
const CreateProfile: React.FC<UserProfileProps> = () => {
  const [profileImageError, setProfileImageError] = useState<string | null>(
    null
  );
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [profileImagePreview, setProfileImagePreview] = useState<string | null>(
    null
  );
  const location = useLocation();
  const { updateUser } = useUserContext();
  const [isEditMode, setIsEditMode] = useState(false);

  const verifiedEmail = localStorage.getItem("verifiedEmail") || "";

  const isNewProfileSetup = JSON.parse(
    localStorage.getItem("isNewProfileSetup") || "false"
  );

  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: verifiedEmail,
      phoneNumber: "",
      country: "",
      city: "",
      companyName: "",
      companyAddress: "",
      companySize: "",
      industrySector: "",
      headquartersLocation: "",
    },
  });

  const selectedCountryId = methods.watch("country");

  const { data: profileResponse } = useQuery({
    queryKey: ["viewProfile"],
    queryFn: viewProfile,
    enabled: !isNewProfileSetup,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const profileView = profileResponse?.data || [];

  const { data: countriesResponse } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  const countryList = countriesResponse?.data || [];

  const { data: citiesResponse } = useQuery({
    queryKey: ["cities", selectedCountryId],
    queryFn: () => getCities(Number(selectedCountryId)),
    enabled: !!selectedCountryId,
  });

  const cityList = citiesResponse?.data || [];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const key = searchParams.get("key");
    if (key) {
      methods.setValue("key", key);
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [location.search, methods]);

  useEffect(() => {
    if (!isNewProfileSetup && profileResponse?.data) {
      methods.reset({
        firstName: profileView.userDetails?.firstName || "",
        lastName: profileView.userDetails?.lastName || "",
        email: verifiedEmail || profileView.userDetails?.email || "",
        phoneNumber: profileView.userDetails?.phoneNumber || "",
        country: profileView.userDetails?.country?.countryId.toString() || "",
        city: profileView.userDetails?.city?.cityId.toString() || "",
        companyName: profileView.companyDetails?.companyName || "",
        companyAddress: profileView.companyDetails?.companyAddress || "",
        companySize: profileView.companyDetails?.companySize || "",
        industrySector: profileView.companyDetails?.industrySector || "",
        headquartersLocation:
          profileView.companyDetails?.headquartersLocation || "",
      });

      if (profileView?.userDetails?.imageUrl) {
        setProfileImagePreview(profileView.userDetails.imageUrl);
      }
    }
  }, [isNewProfileSetup, methods, profileView, profileResponse, verifiedEmail]);

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setProfileImage(file);
      setProfileImagePreview(URL.createObjectURL(file));
      setProfileImageError(null);
    }
  };

  const onSubmit = async (formDataValues: FormData) => {
    try {
      const formData = new FormData();
      Object.keys(formDataValues).forEach((key) => {
        formData.append(key, (formDataValues as any)[key]);
      });

      formData.append("countryId", formDataValues.country);
      formData.append("cityId", formDataValues.city);

      if (profileImage) {
        formData.append("profileImage", profileImage);
      }

      let response;
      if (isNewProfileSetup) {
        response = await setupProfile(formData);
        showToast(response.message);
      } else {
        response = await updateProfile(formData);
        updateUser(response.data);
        showToast(response.message);
        navigate("/dashboard");
        setIsEditMode(false);
      }

      const { success, data } = response;
      if (success) {
        if (data?.token) {
          useStorage.setItem("planetPulseToken", data?.token);
          updateUser(response.data);
        }
        if (isNewProfileSetup) {
          localStorage.removeItem("isNewProfileSetup");
        }
        navigate("/dashboard");
      }
    } catch (error: any) {
      console.error("Failed to create/update profile:", error);
      showToast(error, "error");
    }
  };

  const handleUploadClick = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleDiscardClick = () => {};

  return (
    <CustomContainer>
      <Grid container gap={matches ? "0px" : "24px"}>
        <Sidebar matches={matches} />
        <Grid item xs={12} md={8} lg={9} sx={{ ml: "auto" }}>
          <CustomContainer paddingY="0">
            <CustomHeading
              gutterBottom
              text={!isEditMode ? "User Information" : "Personal Information"}
              sx={{ color: "#090909" }}
            />
            <CustomSubHeading
              text={
                isNewProfileSetup
                  ? "Create your profile to access tailored tools for managing your business's environmental impact."
                  : "Update your profile to manage your business's environmental impact."
              }
            />
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="fit-content">
                    <Box
                      component="img"
                      mt={4}
                      src={profileImagePreview || profileAvatar}
                      alt="Profile Avatar"
                      sx={{ width: 250, height: 250 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <CustomButton
                        text="Upload Profile"
                        onClick={handleUploadClick}
                        disabled={!isEditMode && !isNewProfileSetup}
                      />
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        disabled={!isEditMode && !isNewProfileSetup}
                      />
                    </Box>
                    {profileImageError && (
                      <CustomText
                        text={profileImageError}
                        color="error"
                        textAlign="center"
                      />
                    )}
                  </Box>
                  <Box display="flex" justifyContent="end" mb={"180px"}>
                    {!isEditMode && !isNewProfileSetup ? (
                      <CustomButton
                        text="Edit Profile"
                        onClick={handleEditClick}
                      />
                    ) : (
                      <>
                        <CustomButton
                          text="Discard Changes"
                          bgColor={theme.palette.primary.main}
                          onClick={() => {}}
                          sx={{ ml: 2 }}
                        />
                      </>
                    )}
                  </Box>
                </Box>
                <ProfileInfoForm
                  isViewProfile={!isEditMode && !isNewProfileSetup}
                  countriesData={countryList}
                  cityList={cityList}
                  isNewProfileSetup={isNewProfileSetup}
                />
                <CustomHeading
                  gutterBottom
                  text="Company Details"
                  mt={5}
                  sx={{ color: "#090909" }}
                />
                <CustomSubHeading text="Update your company's information to keep your profile current" />
                <CompanyDetailForm
                  isViewProfile={!isEditMode && !isNewProfileSetup}
                />

                <Box display="flex" justifyContent="end" mt={3}>
                  <CustomButton
                    text={
                      !isNewProfileSetup ? "Save Profile" : "Submit Profile"
                    }
                    type="submit"
                  />
                </Box>
              </form>
            </FormProvider>
          </CustomContainer>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default CreateProfile;

interface SidebarProps {
  matches?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ matches }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Grid
      item
      xs={12}
      md={4}
      lg={3}
      sx={{
        backgroundColor: theme.palette.secondary.main,
        position: { md: "fixed", xs: "relative" },
        minHeight: matches ? "calc(100vh - 48px)" : "56vh",
        borderRadius: "12px",
        overflow: "hidden",
      }}
    >
      <CustomContainer paddingY="40px">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <CustomHeading
              gutterBottom
              text="Create Your Profile"
              color="secondary"
              shade="contrastText"
              fontSize="2.25rem"
            />
            <CustomSubHeading
              text="Create your profile to access tailored tools for managing your business's environmental impact."
              gutterBottom
              color="secondary"
              shade="contrastText"
            />
          </Box>

          <Box
            component="img"
            src={profileHandImage}
            alt="Hand holding a plant"
            sx={{
              position: "absolute",
              bottom: isLargeScreen ? "0" : "-22px",
              left: "0",
              width: "80%",
              marginBottom: isLargeScreen ? "-30px" : "0",
              transition: "bottom 0.3s ease, marginBottom 0.3s ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "-8px",
              right: "0",
            }}
          >
            <EllipseLargeIcon />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom: "99px",
              right: "27px",
              display: "flex",
            }}
          >
            <EllipseSmallIcon />
          </Box>
        </Box>
      </CustomContainer>
    </Grid>
  );
};
