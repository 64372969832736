import { z } from "zod";

export const MobileSourceSchema = z.object({
  typeOfVehicle: z.string().min(1, "Type of Vehicle is required"),
  vehicleQuantity: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z.number().nullable()
  ),
  fuelConsumption: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine(
      (val) => val !== null && val > 0,
      "Fuel Consumption must be a positive number"
    ),
  fuelType: z.string().min(1, "Fuel Type is required"),
  distanceTravelled: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine(
      (val) => val !== null && val > 0,
      "Distance Travel must be a positive number"
    ),
  emissionFactor: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
  ),
  mobileSourceDataCollectionMethod: z
    .string()
    .min(1, "Data Collection Method is required"),
  protocol: z.string().min(1, "Protocol is required"),
  unit: z.string().min(1, "Unit of Fuel Type is required"),
  locationName: z.string().min(1, "Location is required"),
  assetName: z.string().min(1, "Asset Name is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});
