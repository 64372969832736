import { List, ListItem, ListItemText } from "@mui/material";
import { CustomText } from "../../ui";

interface PasswordCriteria {
  length: boolean;
  upperLower: boolean;
  digit: boolean;
  specialChar: boolean;
}

const PasswordValidator: React.FC<{ password: string }> = ({ password }) => {
  const passwordCriteria: PasswordCriteria = {
    length: password.length >= 8,
    upperLower: /[a-z]/.test(password) && /[A-Z]/.test(password),
    digit: /\d/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  return (
    <List sx={{ listStyleType: "disc", paddingInlineStart: "20px", pt: 0 }}>
      <ListItem
        sx={{
          display: "list-item",
          p: 0,
          "&::marker": {
            color: passwordCriteria.length ? "primary.600" : "grey.200",
          },
        }}
      >
        <ListItemText
          primary={
            <CustomText
              text="Min 8 characters"
              color={passwordCriteria.length ? "primary" : "grey"}
              shade={passwordCriteria.length ? "600" : "200"}
            />
          }
        />
      </ListItem>
      <ListItem
        sx={{
          display: "list-item",
          p: 0,
          "&::marker": {
            color: passwordCriteria.upperLower ? "primary.600" : "grey.200",
          },
        }}
      >
        <ListItemText
          primary={
            <CustomText
              text="A mix of upper- and lower-case letters"
              color={passwordCriteria.upperLower ? "primary" : "grey"}
              shade={passwordCriteria.upperLower ? "600" : "200"}
            />
          }
        />
      </ListItem>
      <ListItem
        sx={{
          display: "list-item",
          p: 0,
          "&::marker": {
            color: passwordCriteria.digit ? "primary.600" : "grey.200",
          },
        }}
      >
        <ListItemText
          primary={
            <CustomText
              text="At least one digit"
              color={passwordCriteria.digit ? "primary" : "grey"}
              shade={passwordCriteria.digit ? "600" : "200"}
            />
          }
        />
      </ListItem>
      <ListItem
        sx={{
          display: "list-item",
          p: 0,
          "&::marker": {
            color: passwordCriteria.specialChar ? "primary.600" : "grey.200",
          },
        }}
      >
        <ListItemText
          primary={
            <CustomText
              text="At least one special character"
              color={passwordCriteria.specialChar ? "primary" : "grey"}
              shade={passwordCriteria.specialChar ? "600" : "200"}
            />
          }
        />
      </ListItem>
    </List>
  );
};

export default PasswordValidator; 
