import { axiosInstance } from "../utils/axios";
import { API_ENDPOINTS } from "./apiEndPoints";

export interface SignupData {
  email: string;
  password: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface ResetPasswordData {
  key: string;
  newPassword: string;
}

export interface VerifyEmail {
  key: string;
  email?: string;
}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}

export const signupUser = async (data: SignupData) => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.SIGNUP, data);
    return response.data;
  } catch (error: any) {
    console.error("Signup failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Signup failed.";
  }
};

export const verifyEmail = async (data: VerifyEmail) => {
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.VERIFY_EMAIL}?key=${data.key}`
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Verify Email key failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Verify Email key failed.";
  }
};

export const loginUser = async (data: SignupData) => {
  try {
    console.log("Login Payload:", data);
    const response = await axiosInstance.post(API_ENDPOINTS.LOGIN, data);
    console.log("Login Response:", response.data);
    return response.data;
  } catch (error: any) {
    console.error("Login failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Login failed.";
  }
};

export const logout = async () => {
  try {
    const response = await axiosInstance.post(API_ENDPOINTS.LOGOUT);
    return response.data;
  } catch (error: any) {
    console.error("Logout failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Logout failed.";
  }
};

export const forgotPassword = async (data: ForgotPasswordData) => {
  try {
    console.log("ForgotPassword Payload:", data);
    const response = await axiosInstance.post(
      API_ENDPOINTS.FORGOT_PASSWORD,
      null,
      {
        params: data,
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Forgot Password failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Forgot Password failed.";
  }
};

export const resetPassword = async (data: ResetPasswordData) => {
  try {
    console.log("Reset password Payload:", data);
    const response = await axiosInstance.post(API_ENDPOINTS.RESET_PASSWORD, {
      key: data.key,
      newPassword: data.newPassword,
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Reset Password failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Reset Password failed.";
  }
};
