import Axios from "axios";
import { logoutUser } from "./logout.util";
import useStorage from "./useStorage.util";
const defaultOptions = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
export const axiosInstance = Axios.create(defaultOptions);
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const token = useStorage.getItem("planetPulseToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    } catch (error) {
      console.error("Error retrieving token:", error);
    }
    return config;
  },
  (error) => Promise.reject(error)
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);
