

import React, { FC } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomModal from "../../../../../ui/custom-modal/custom-modal";
import { CustomTextField } from "../../../../../ui";
import { formatDateToDDMMYYYY, formatTime } from "../../../../../utils/getStatusStyle";

interface AddNoteModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  noteValue: string;
  isViewingNote: boolean;
  time: string;
}

const AddNoteModal: FC<AddNoteModalProps> = ({
  open,
  onClose,
  onSave,
  noteValue,
  onNoteChange,
  isViewingNote,
  time,
}) => {
  const handleSubmit = () => {
    if (!isViewingNote) {
      onSave();
    }
    onClose();
  };

  return (
    <CustomModal
      title={isViewingNote ? "View Note" : "Add Note"}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      actions={
        isViewingNote
          ? [{ text: "Close", onClick: onClose, bgColor: "transparent" }]
          : [
              { text: "Discard", onClick: onClose, bgColor: "transparent" },
              { text: "Save", type: "submit", onClick: handleSubmit },
            ]
      }
    >
      {isViewingNote ? (
     
          <Box sx={{ mt: 3 }}>
            <Box sx={{ backgroundColor: "#F3F4F6", padding: "16px", borderRadius: "8px", mt: 2 }}>
              <Typography variant="body1" sx={{ color: "#4B5563", textAlign: "left" }}>
                {noteValue}
              </Typography>
              <Typography variant="body2" sx={{ display: "block", textAlign: "right", color: "#9CA3AF", mt: 1, fontSize: "14px" }}>
              {formatDateToDDMMYYYY(time) + " " + formatTime(time) || ""}
              </Typography>
            </Box>
          </Box>
      ) : (
        // Edit mode with CustomTextField
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomTextField
                name="note"
                label="Add Note"
                placeholder="Enter your note here"
                type="textarea"
                value={noteValue}
                onChange={onNoteChange}
                multiline
                
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </CustomModal>
  );
};

export default AddNoteModal;
