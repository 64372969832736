import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../../../../ui";
import { useLocationContext } from "../../../../../context/location-context";
import EmissionsAddedTable from "../../scope2-emission/emission-table/emissions-added-table";
import Scope1LocationAccordion from "../../../../../ui/scop1-location-accordion/scope1-location-accordion";
import {
  addAttachmentToScope1LocationEmission,
  addNoteToScope1LocationEmission,
  deleteScope1LocationEmission,
  getNoteForScope1LocationEmission,
  getScope1LocationEmission,
  getScope1LocationPreferences,
  saveScope1LocationEmission,
} from "../../../../../api/scope1-location-api";
import { showToast } from "../../../../../utils/showToast.util";
import AddNoteModal from "./scope1-location-add-view-note";
import AddViewAttachmentModal from "./scope1-location-add-view-attachment";
import BulkImportData from "./scope1-location-bulk-import";
import CardEmission from "../../../../../ui/card-emission/card-emission";
import { useNavigate } from "react-router-dom";

interface LocationEmissionProps {
  setIsConfigurationTabsView?: any;
  locationId?: any;
}

const LocationEmissions: FC<LocationEmissionProps> = ({
  setIsConfigurationTabsView,
  locationId,
}) => {
  const { setScope1LocationPreferences } = useLocationContext();
  const [isNoteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [selectedEmissionId, setSelectedEmissionId] = useState<number | null>(
    null
  );
  const [note, setNote] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationEmissionType, setLocationEmissionType] = useState<any>("");
  const [emissionData, setEmissionData] = useState<any[]>([]);
  const [scope1LocationEmissionData, setScope1LocationEmissionData] = useState<
    any[]
  >([]);

  const [isViewingNote, setIsViewingNote] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [isAttachmentModalOpen, setAttachmentModalOpen] =
    useState<boolean>(false);
  const [attachmentModalMode, setAttachmentModalMode] = useState<
    "add" | "view"
  >("add");
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      gridElectricityAmount: "",
      onSiteRenewablesAmount: "",
    },
  });
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getScope1LocationPreferences(locationId);
        setScope1LocationPreferences(response.data);
      } catch (error: any) {
        console.error("Error fetching initial data:", error);
        showToast(error, "error");
      }
    };
    fetchInitialData();
    fetchLocationData();
  }, [locationId, locationEmissionType]);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const columns = [
    { key: "facilityName", label: "Facility Name", flexBasis: "40%" },
    { key: "measuringValue", label: "Amount", flexBasis: "15%" },
    { key: "emissions", label: "Emissions", flexBasis: "15%" },
    { key: "actions", label: "Actions", flexBasis: "15%" },
  ];

  const columns1 = [
    { key: "locationEmissionType", label: "Facility Name" },
    { key: "measuringValue", label: "Amount" },
    { key: "createdDate", label: "Created At" },
    { key: "calculatedEmission", label: "Emissions" },
  ];

  const rows = [
    {
      facilityName: "Stationary Combustion",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "stationaryCombustion",
    },
  ];

  const handleConfiguration = () => {
    setIsConfigurationTabsView({ show: true, active: undefined });
  };

  const fetchLocationData = async () => {
    try {
      const response = await getScope1LocationEmission(locationId);
      setScope1LocationEmissionData(response.data);
    } catch (error: any) {
      console.error("Error fetching initial data:", error);
      showToast(error, "error");
    }
  };

  const onSubmit = async (emission: any) => {
    try {
      const emissionLocationData = emissionData.map((value: any) => ({
        locationEmissionType: value.scopeOneLocationEmissionType,
        measuringValue: Number(value.measuringValue),
        protocol: "CSRD",
      }));

      const response = await saveScope1LocationEmission({
        locationId: Number(locationId),
        data: emissionLocationData,
      });
      setEmissionData(response.data);
      showToast(response.message);
      await fetchLocationData();
    } catch (error: any) {
      console.error("Error saving building emission:", error);
      showToast(error, "error");
    }
  };

  const handleAddNew = () => {
    setExpandedRowIndex((prevIndex) => (prevIndex === 0 ? null : 0));
  };

  const handleLocationEmissionTypeChange = (type: string) => {
    setLocationEmissionType(type);
  };

  const handleEmissionsUpdate = (updatedEmissions: any) => {
    setEmissionData(updatedEmissions);
  };
  const handleDeleteEmission = async (emissionId: number) => {
    try {
      const response = await deleteScope1LocationEmission(emissionId);
      setScope1LocationEmissionData((prevData) =>
        prevData.filter((item) => item.locationEmissionId !== emissionId)
      );
      showToast(response.message);
    } catch (error) {
      showToast("Error deleting emission", "error");
    }
  };
  const handleSaveNote = async () => {
    if (selectedEmissionId == null || !note.trim()) return;
    try {
      const response = await addNoteToScope1LocationEmission({
        emissionId: selectedEmissionId,
        note: note,
      });
      showToast(response.message);
      setNoteModalOpen(false);
      setNote("");
    } catch (error: any) {
      showToast(error, "error");
    }
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event?.target?.value || "");
  };
  const handleAddNoteModalOpen = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setNoteModalOpen(true);
    setNote("");
    setIsViewingNote(false);
  };
  const handleViewNote = async (emissionId: number) => {
    try {
      const response = await getNoteForScope1LocationEmission(emissionId);
      setNote(response.data.note);
      setTime(response.data.createdTime);
      setSelectedEmissionId(emissionId);
      setIsViewingNote(true);
      setNoteModalOpen(true);
    } catch (error: any) {
      console.error("Error fetching note:", error);
      showToast(error, "error");
    }
  };
  const handleAddAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("add");
    setAttachmentModalOpen(true);
  };
  const handleViewAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("view");
    setAttachmentModalOpen(true);
  };
  const handleSaveAttachment = async (files: File[], emissionId: number) => {
    try {
      if (emissionId == null) return;

      for (const file of files) {
        const response = await addAttachmentToScope1LocationEmission(
          emissionId,
          file
        );
        setShouldRefetch(true);
        showToast(response.message);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };
  const handleBulkImport = () => {
    setIsModalOpen(true);
    navigate("?emission=building?action=bulk-import", { replace: true });
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            text="Configuration"
            bgColor="transparent"
            textColor="#344054"
            rounded="8px"
            variant="outlined"
            onClick={handleConfiguration}
            sx={{
              borderColor: "#D0D5DD",
              outlineColor: "#EAECF0",
              color: "#344054",
              "&:hover": {
                borderColor: "transparent",
                backgroundColor: "#FFF",
              },
              minWidth: "auto",
              boxShadow: "none",
            }}
          />

          <CardEmission
            headerText=""
            headerBtnText="Add New"
            handleAddNew={handleAddNew}
            btnActions={[
              { text: "Bulk Import Data", onClick: handleBulkImport },
            ]}
            setIsModalOpen={setIsModalOpen}
          />
        </Box>

        <>
          <Box sx={{ margin: "14px 18px" }}>
            <Scope1LocationAccordion
              columns={columns}
              rows={rows}
              expandedRowIndex={expandedRowIndex}
              onExpandRow={setExpandedRowIndex}
              onEmissionsUpdate={handleEmissionsUpdate}
              onEmissionTypeChange={handleLocationEmissionTypeChange}
              locationId={locationId}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <CustomButton
              text="Cancel"
              bgColor="transparent"
              rounded="8px"
              variant="outlined"
              onClick={() => {}}
              sx={{
                borderColor: "#D0D5DD",
                outlineColor: "#EAECF0",
                margin: "8px 16px",
                marginRight: "2px",
                padding: "10px",
                color: "#344054",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#FFF",
                },
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              text="Save"
              textColor="#000"
              iconPosition="end"
              rounded="8px"
              sx={{
                margin: "8px 16px",
                padding: "10px",
                backgroundColor: "#FFDE59",
                color: "#000",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Save
            </CustomButton>
          </Box>
        </>

        <Box sx={{ marginBottom: "2px" }}>
          <EmissionsAddedTable
            columns={columns1}
            rows={scope1LocationEmissionData}
            emissionType={locationEmissionType}
            tableType={"Location"}
            rowActions={[
              {
                text: "Delete Emission",
                onClick: (row: any) =>
                  handleDeleteEmission(row.locationEmissionId),
              },
              {
                text: "Add Note",
                onClick: (row: any) =>
                  handleAddNoteModalOpen(row.locationEmissionId),
              },
              {
                text: "View Note",
                onClick: (row: any) => handleViewNote(row.locationEmissionId),
              },
              {
                text: "Add Attachment",
                onClick: (row: any) => {
                  handleAddAttachment(row.locationEmissionId);
                },
              },
              {
                text: "View Attachment",
                onClick: (row: any) => {
                  handleViewAttachment(row.locationEmissionId);
                },
              },
            ]}
          />
        </Box>
        <BulkImportData
          isModalOpen={isModalOpen}
          onClose={handleClose}
          locationId={locationId}
          fileType = "SCOPE_ONE_LOCATION"
        />

        <AddNoteModal
          open={isNoteModalOpen}
          onClose={() => setNoteModalOpen(false)}
          onSave={handleSaveNote}
          onNoteChange={handleNoteChange}
          noteValue={note}
          isViewingNote={isViewingNote}
          time={time}
        />
        <AddViewAttachmentModal
          open={isAttachmentModalOpen}
          onClose={() => setAttachmentModalOpen(false)}
          onSave={(files: any) =>
            handleSaveAttachment(files, selectedEmissionId!)
          }
          emissionId={selectedEmissionId!}
          mode={attachmentModalMode}
          shouldRefetch={shouldRefetch}
          onRefetchComplete={() => setShouldRefetch(false)}
        />
      </form>
    </FormProvider>
  );
};

export default LocationEmissions;
