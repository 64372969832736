export const API_ENDPOINTS = {
  // ENDPOINTS AUTH
  SIGNUP: "/register",
  VERIFY_EMAIL: "/verify-email",
  LOGIN: "/login",
  LOGOUT: "/logout",
  FORGOT_PASSWORD: "/reset-password-request",
  RESET_PASSWORD: "/reset-password",

  // ASSETS ENDPOINTS
  ASSET_TYPE: "/asset-type",
  ASSET: "/asset",
  ASSETS_FOR_LOCATION: "/asset/get-assets-for-location",

  //LOCATION ENDPOINTS
  LOCATION: "/location",
  LOCATION_COUNTRIES: "/location/countries",
  LOCATION_STATE: (countryId: number) => `/location/${countryId}/state`,
  LOCATION_CITY: (stateId: number) => `/location/${stateId}/city`,
  SET_DEFAULT_LOCATION: (locationId: number) =>
    `/location/${locationId}/set-default`,
  DELETE_LOCATION: (locationId: number) => `/location/${locationId}`,

  //EMISSIONS ENDPOINTS

  CATEGORY: "/category",
  SUB_CATEGORY: (id: number) => `/category/${id}`,
  ASSET_TYPES: "/asset-type",
  EMISSION_SOURCE: "/emission-source",
  EMISSION_SOURCE_STATUS: "/emission-source/update-status",
  EMISSION_SOURCE_BY_ID: (id: number) => `/emission-source/${id}`,

  // FUEL COMBUSTION ENDPOINTS
  FUEL_COMBUSTION: "/fuel-combustion-emission",
  FUEL_TYPE: "/fuel-type",
  ASSET_WITH_LOACTION: "/asset/for-fuel-combustion-emission",
  MEASUREMENT_VALUE: (fuelTypeId: number) => `/unit/${fuelTypeId}`,
  EMISSION_FACTOR: (fuelTypeId: number, measureId?: number) =>
    `/emission-factor/${fuelTypeId}/${measureId}`,
  FUEL_COMBUSTION_BY_ID: (id: number) => `/fuel-combustion-emission/${id}`,
  FUEL_COMBUSTION_BY_FILE_ID: "/fuel-combustion-emission",
  FUEL_COMBUSTION_REASON: (id: number) =>
    `/fuel-combustion-emission/${id}/reason`,
  EMISSION_FILE: "/emission-file",
  SAMPLE_DOWNLOAD_TEMPLATE: (sampleName: string) =>
    `/aws/download-sample?sampleName=${sampleName}`,
  UPLOAD_CSV: (uploadUrl: string) => uploadUrl,
  FUEL_COMBUSTION_CALCULATE: "/fuel-combustion-emission/calculate-emission",

  //PROCESS EMISSION ENDPOINTS
  PROCESS_EMISSION: "/process-emission",
  PROCESS_EMISSION_CALCULATE: "/process-emission/calculate-emission",
  PROCESS_TYPE: "/process-type",
  MATERIAL: "/material",
  PROCESS_EMISSION_FACTOR: (
    processId: number,
    materialId?: number,
    unitId?: number
  ) => `/process-emission-factor/${processId}/${materialId}/${unitId}`,
  PROCESS_EMISSION_BY_ID: (id: number) => `/process-emission/${id}`,
  PROCESS_EMISSION_REASON: (id: number) => `/process-emission/${id}/reason`,

  // FUGITIVE EMISSION ENDPOINTS
};
