import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Box } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../../../../ui";
import { useBuildingContext } from "../../../../../context/building-context";
import EmissionsAddedTable from "../../scope2-emission/emission-table/emissions-added-table";
import {
  addAttachmentToScope1BuildingEmission,
  addNoteToScope1BuildingEmission,
  deleteScope1BuildingEmission,
  getNoteForScope1BuildingEmission,
  getScope1BuildingEmission,
  getScope1BuildingPreferences,
  saveScope1BuildingEmission,
} from "../../../../../api/scope1-building-api";
import Scope1BuildingAccordion from "../../../../../ui/scop1-building-accordion/scope1-building-accordion";
import { showToast } from "../../../../../utils/showToast.util";
import AddNoteModal from "./scope1-building-add-view-note";
import AddViewAttachmentModal from "./scope1-building-add-view-attachment";
import CardEmission from "../../../../../ui/card-emission/card-emission";
import BulkImportData from "./scope1-building-bulk-import";
import { useNavigate } from "react-router-dom";

interface Scope1BuildingEmissionsProps {
  setIsConfigurationTabsView?: Dispatch<
    SetStateAction<{ show: boolean; active: number | undefined }>
  >;
  buildingId: number;
}

const Scope1BuildingEmissions: FC<Scope1BuildingEmissionsProps> = ({
  setIsConfigurationTabsView,
  buildingId,
}) => {
  const { setScope1BuildingPreferences } = useBuildingContext();
  const [buildingEmissionType, setBuildingEmissionType] = useState<any>("");
  const [emissionData, setEmissionData] = useState<any[]>([]);
  const [scope1BuildingEmission, setScope1BuildingEmission] = useState<any[]>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [isNoteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [selectedEmissionId, setSelectedEmissionId] = useState<number | null>(
    null
  );
  const [note, setNote] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isViewingNote, setIsViewingNote] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [isAttachmentModalOpen, setAttachmentModalOpen] =
    useState<boolean>(false);
  const [attachmentModalMode, setAttachmentModalMode] = useState<
    "add" | "view"
  >("add");
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      gridElectricityAmount: "",
      onSiteRenewablesAmount: "",
    },
  });
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getScope1BuildingPreferences(buildingId);
        setScope1BuildingPreferences(response.data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
    fetchBuildingData();
  }, [buildingId, buildingEmissionType, setScope1BuildingPreferences]);

  const columns = [
    { key: "facilityName", label: "Facility Name", flexBasis: "40%" },
    { key: "amount", label: "Amount", flexBasis: "10%" },
    { key: "emissions", label: "Emissions", flexBasis: "12%" },
    { key: "actions", label: "Actions", flexBasis: "15%" },
  ];

  const columns1 = [
    { key: "buildingEmissionType", label: "Facility Name" },
    { key: "measuringValue", label: "Amount" },
    { key: "createdDate", label: "Created At" },
    { key: "calculatedEmission", label: "Emissions" },
  ];

  const [rows, setRows] = useState([
    {
      facilityName: "Stationary Combustion",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "stationaryCombustion",
    },
    {
      facilityName: "Fire Suppressants",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "fireSuppressants",
    },
    {
      facilityName: "Refrigerators",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "refrigerants",
    },
  ]);

  const handleConfiguration = () => {
    setIsConfigurationTabsView &&
      setIsConfigurationTabsView({ show: true, active: undefined });
  };

  const fetchBuildingData = useCallback(async () => {
    if (!hasMore) return;

    try {
      const response = await getScope1BuildingEmission(buildingId, page);
      const newEmissions = response.data || [];

      if (newEmissions.length === 0) {
        setHasMore(false);
      } else {
        setScope1BuildingEmission(response.data);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error: any) {
      console.error("Error fetching building emission data:", error);
      showToast(error, "error");
    }
  }, [buildingId, page, hasMore]);

  const onSubmit = async () => {
    try {
      const emissionBuildingData = emissionData.map((value: any) => ({
        buildingEmissionType: value.buildingEmissionType,
        measuringValue: Number(value.measuringValue),
        protocol: "CSRD",
      }));

      const response = await saveScope1BuildingEmission({
        buildingId: Number(buildingId),
        data: emissionBuildingData,
      });
      setEmissionData(response.data);
      // if (response.data) {
      //   setScope1BuildingEmission((prevData) => [
      //     ...response.data,
      //     ...prevData,
      //   ]);
      // } else {
      //   console.error("Response data is invalid or empty.");
      // }

      showToast(response.message);
      await fetchBuildingData();
      // setPage(1);
      // setHasMore(true);
    } catch (error: any) {
      console.error("Error saving building emission:", error);
      showToast(error, "error");
    }
  };
  const handleAddNew = () => {
    setExpandedRowIndex((prevIndex) => (prevIndex === 0 ? null : 0));
  };

  const handleEmissionBuildingTypeChange = (emissionBuildingType: string) => {
    setBuildingEmissionType(emissionBuildingType);
  };

  const handleEmissionTypeChange = (type: string) => {
    setBuildingEmissionType(type);
  };

  const handleEmissionsUpdate = (updatedEmissions: any) => {
    setEmissionData(updatedEmissions);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;

    if (bottom) {
      fetchBuildingData();
    }
  };

  const handleDeleteEmission = async (emissionId: number) => {
    try {
      const response = await deleteScope1BuildingEmission(emissionId);
      setScope1BuildingEmission((prevData) =>
        prevData.filter((item) => item.buildingEmissionId !== emissionId)
      );
      showToast(response.message);
    } catch (error) {
      showToast("Error deleting emission", "error");
    }
  };

  const handleSaveNote = async () => {
    if (selectedEmissionId == null || !note.trim()) return;
    try {
      const response = await addNoteToScope1BuildingEmission({
        emissionId: selectedEmissionId,
        note: note,
      });
      showToast(response.message);
      setNoteModalOpen(false);
      setNote("");
    } catch (error: any) {
      showToast(error, "error");
    }
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event?.target?.value || "");
  };
  const handleAddNoteModalOpen = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setNoteModalOpen(true);
    setNote("");
    setIsViewingNote(false);
  };
  const handleViewNote = async (emissionId: number) => {
    try {
      const response = await getNoteForScope1BuildingEmission(emissionId);
      setNote(response.data.note);
      setTime(response.data.createdTime);
      setSelectedEmissionId(emissionId);
      setIsViewingNote(true);
      setNoteModalOpen(true);
    } catch (error: any) {
      console.error("Error fetching note:", error);
      showToast(error, "error");
    }
  };

  const handleAddAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("add");
    setAttachmentModalOpen(true);
  };
  const handleViewAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("view");
    setAttachmentModalOpen(true);
  };
  const handleSaveAttachment = async (files: File[], emissionId: number) => {
    try {
      if (emissionId == null) return;
      for (const file of files) {
        const response = await addAttachmentToScope1BuildingEmission(
          emissionId,
          file
        );
        setShouldRefetch(true);
        showToast(response.message);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };
  const handleBulkImport = () => {
    setIsModalOpen(true);
    navigate("?emission=building?action=bulk-import", { replace: true });
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            text="Configuration"
            bgColor="transparent"
            textColor="#344054"
            rounded="8px"
            variant="outlined"
            onClick={handleConfiguration}
            sx={{
              borderColor: "#D0D5DD",
              outlineColor: "#EAECF0",
              color: "#344054",
              "&:hover": {
                borderColor: "transparent",
                backgroundColor: "#FFF",
              },
              minWidth: "auto",
              boxShadow: "none",
            }}
          />

          <CardEmission
            headerText=""
            headerBtnText="Add New"
            handleAddNew={handleAddNew}
            btnActions={[
              { text: "Bulk Import Data", onClick: handleBulkImport },
            ]}
            setIsModalOpen={setIsModalOpen}
          />
        </Box>

        <>
          <Box sx={{ margin: "14px 18px" }}>
            <Scope1BuildingAccordion
              columns={columns}
              rows={rows}
              setRows={setRows}
              expandedRowIndex={expandedRowIndex}
              onExpandRow={setExpandedRowIndex}
              onEmissionTypeChange={handleEmissionTypeChange}
              onEmissionsUpdate={handleEmissionsUpdate}
              buildingId={buildingId}
              onEmissionBuildingTypeChange={handleEmissionBuildingTypeChange}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <CustomButton
              text="Cancel"
              bgColor="transparent"
              rounded="8px"
              variant="outlined"
              onClick={() => {}}
              sx={{
                borderColor: "#D0D5DD",
                outlineColor: "#EAECF0",
                margin: "8px 16px",
                marginRight: "2px",
                padding: "10px",
                color: "#344054",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#FFF",
                },
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              text="Save"
              textColor="#000"
              iconPosition="end"
              rounded="8px"
              sx={{
                margin: "8px 16px",
                padding: "10px",
                backgroundColor: "#FFDE59",
                color: "#000",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Save
            </CustomButton>
          </Box>
        </>

        <BulkImportData
          isModalOpen={isModalOpen}
          onClose={handleClose}
          buildingId={buildingId}
          fileType="SCOPE_ONE_BUILDING"
        />

        <Box>
          <EmissionsAddedTable
            columns={columns1}
            rows={scope1BuildingEmission}
            emissionType={buildingEmissionType}
            tableType={"Building"}
            onScroll={handleScroll}
            rowActions={[
              {
                text: "Delete Emission",
                onClick: (row: any) =>
                  handleDeleteEmission(row.buildingEmissionId),
              },
              {
                text: "Add Note",
                onClick: (row: any) =>
                  handleAddNoteModalOpen(row.buildingEmissionId),
              },
              {
                text: "View Note",
                onClick: (row: any) => handleViewNote(row.buildingEmissionId),
              },
              {
                text: "Add Attachment",
                onClick: (row: any) => {
                  handleAddAttachment(row.buildingEmissionId);
                },
              },
              {
                text: "View Attachment",
                onClick: (row: any) =>
                  handleViewAttachment(row.buildingEmissionId),
              },
            ]}
          />
          <AddNoteModal
            open={isNoteModalOpen}
            onClose={() => setNoteModalOpen(false)}
            onSave={handleSaveNote}
            onNoteChange={handleNoteChange}
            noteValue={note}
            isViewingNote={isViewingNote}
            time={time}
          />

          <AddViewAttachmentModal
            open={isAttachmentModalOpen}
            onClose={() => setAttachmentModalOpen(false)}
            onSave={(files: any) =>
              handleSaveAttachment(files, selectedEmissionId!)
            }
            emissionId={selectedEmissionId!}
            mode={attachmentModalMode}
            shouldRefetch={shouldRefetch}
            onRefetchComplete={() => setShouldRefetch(false)}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default Scope1BuildingEmissions;
