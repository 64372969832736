import React, { useState, FC, useEffect } from "react";
import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import { FormProvider, useForm } from "react-hook-form";
import AddEditMobileEmission from "./add-edit-mobile-emission";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import ViewReasonMobileEmission from "./view-reason-mobile-emission";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  getMobileSourceEmission,
  getMobileSourceEmissionByFileId,
} from "../../../../../api/mobile-source-api";
import {
  getAssetsByLocationId,
  getLocattions,
  getUploadedFiles,
} from "../../../../../api/fuel-api";
import { useQuery } from "@tanstack/react-query";
import { MobileSourceSchema } from "../../../../../validations/mobile-source-schema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { formattedDate } from "../../../../../utils/getStatusStyle";

type FormData = z.infer<typeof MobileSourceSchema>;
const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];

const MobileSourcesEmissions: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isGridView, setIsGridView] = useState(false);
  const [mobileSourceEmissionData, setMobileSourceEmissionData] = useState<
    any[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [mobileSourceEmissionId, setMobileSourceEmissionId] = useState<
    any | null
  >(null);
  const [fileData, setFileData] = useState<any[]>([]);

  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const itemsPerPage = 8;

  const methods = useForm<FormData>({
    resolver: zodResolver(MobileSourceSchema),
    shouldUnregister: true,
    mode: "onChange",
    defaultValues: {
      typeOfVehicle: "",
      vehicleQuantity: null,
      fuelConsumption: null,
      fuelType: "",
      distanceTravelled: null,
      emissionFactor: null,
      mobileSourceDataCollectionMethod: "",
      protocol: "",
      unit: "",
      locationName: "",
      assetName: "",
      reason: "",
    },
  });

  const { watch } = methods;
  const locationName = watch("locationName");
  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: getLocattions,
  });

  const { data: assetsData } = useQuery({
    queryKey: ["assets", locationName],
    queryFn: () => getAssetsByLocationId(Number(locationName)),
    enabled: !!locationName,
  });
  const assets = Array.isArray(assetsData) ? assetsData : [];

  const fetchMobileEmissionData = async (
    page: number,
    itemsPerPage: number,
    protocol?: string,
    sort?: string
  ) => {
    try {
      const response = await getMobileSourceEmission({
        page: page - 1,
        size: itemsPerPage,
        protocol: protocol ? protocol.toUpperCase() : "CSRD",
        sort: "createdDate,desc",
      });
      setMobileSourceEmissionData(response.data);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch fuel combustion data:", error);
    }
  };

  useEffect(() => {
    if (protocol !== "") {
      fetchMobileEmissionData(currentPage, itemsPerPage, protocol);
    }
  }, [protocol, currentPage]);

  const mobileData = mobileSourceEmissionData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    startDate: formattedDate(item.startDate),
  }));

  const handleRowUpdate = (updatedRow: any) => {
    setMobileSourceEmissionData((prevData) =>
      prevData.map((row) =>
        row.mobileSourceEmissionId === updatedRow.mobileSourceEmissionId
          ? { ...row, ...updatedRow }
          : row
      )
    );
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedProtocol = event.target.value;
    if (protocol !== selectedProtocol) {
      setProtocol(selectedProtocol);
    }
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setMobileSourceEmissionId(row.mobileSourceEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setMobileSourceEmissionId(row.mobileSourceEmissionId);
    navigate(`?action=viewReason`);
  };

  const fetchUploadedFiles = async (page: number) => {
    try {
      const response = await getUploadedFiles({
        search: "",
        typeOfEmission: "MOBILE",
        page: page - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileData(response.data);
      fetchMobileEmissionData(currentPage, itemsPerPage);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    fetchUploadedFiles(currentPage);
  }, [currentPage]);

  const file = fileData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getMobileSourceEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };

  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const fields = [
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      col: 3,
      icon: <ChevronDown />,
      onChange: handleProtocolChange,
      options: protocols,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 3,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 3,
      defaultValue: locationName ? "" : watch("assetName"),
    },
  ];

  return (
    <Card
      headerText="Mobile Source Emissions"
      headerBtnText="Add New"
      searchPlaceholder="Hinted search text"
      tableDataLength={mobileSourceEmissionData?.length}
      btnActions={[{ text: "Edit", onClick: () => {} }]}
      currentPage={currentPage}
      uploadUrl="/mobile-source-emission/upload"
      fileType="MOBILE"
    >
      <AddEditMobileEmission
        mobileSourceEmissionId={mobileSourceEmissionId}
        locations={locations}
        assets={assets}
        methods={methods}
        handleRowUpdate={handleRowUpdate}
        fetchMobileEmissionData={fetchMobileEmissionData}
        locationName={locationName}
        currentPage={currentPage}
        fetchUploadedFiles={fileData}
      />
      <ViewReasonMobileEmission
        mobileSourceEmissionId={mobileSourceEmissionId}
      />
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.col} key={index}>
              <TextField
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                select={field.select}
                fullWidth
                onChange={field.onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ChevronDown />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: any) => {
                    if (!value) {
                      return (
                        <span style={{ color: theme.palette.grey[200] }}>
                          {field.placeholder}
                        </span>
                      );
                    }
                    const selectedOption = field.options.find(
                      (option: any) => option.value === value
                    );
                    return selectedOption ? selectedOption.label : value;
                  },
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          <Stack direction="row" gap={1} marginLeft={"auto"}>
            <IconButton
              sx={{
                backgroundColor: isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(false)}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: !isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(true)}
            >
              <LayoutGridIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={mobileData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={mobileData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Mobile Source Emission Files
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Hinted search text"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>

        <Box mt={3}>
          <CustomTable
            columns={columns2}
            data={file}
            rowActions={[
              {
                text: "View Details",
                onClick: (row: any) => {
                  handleViewDetails(row.fileId);
                },
              },
            ]}
          />
        </Box>
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Mobile Source Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default MobileSourcesEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "fuelType", label: "Fuel Type" },
  { key: "typeOfVehicle", label: "Vehicles Type" },
  { key: "vehicleQuantity", label: "Vehicles Qt" },
  { key: "fuelConsumption", label: "Consumption" },
  { key: "distanceTravelled", label: "Distance" },
  { key: "startDate", label: "Upload Date" },
  { key: "location", label: "Location" },
  { key: "assetName", label: "Asset Name" },
  { key: "mobileSourceDataCollectionMethod", label: "Data Collection" },
  { key: "emissionFactor", label: "Emission Factor" },
  { key: "calculatedEmission", label: "Calculated Emission" },
];
const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
