import * as React from "react";

function DeleteFileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6667 8.2487V7.21536C20.6667 5.76856 20.6667 5.04516 20.3851 4.49256C20.1374 4.00647 19.7422 3.61127 19.2561 3.3636C18.7035 3.08203 17.9801 3.08203 16.5333 3.08203H14.4667C13.0199 3.08203 12.2965 3.08203 11.7439 3.3636C11.2578 3.61127 10.8626 4.00647 10.6149 4.49256C10.3333 5.04516 10.3333 5.76856 10.3333 7.21536V8.2487M12.9167 15.3529V21.8112M18.0833 15.3529V21.8112M3.875 8.2487H27.125M24.5417 8.2487V22.7154C24.5417 24.8856 24.5417 25.9707 24.1193 26.7996C23.7478 27.5287 23.155 28.1215 22.4259 28.493C21.597 28.9154 20.5119 28.9154 18.3417 28.9154H12.6583C10.4881 28.9154 9.40303 28.9154 8.57412 28.493C7.84499 28.1215 7.25219 27.5287 6.88068 26.7996C6.45833 25.9707 6.45833 24.8856 6.45833 22.7154V8.2487" stroke="#090909" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
  );
}

export default DeleteFileIcon;
