import { axiosInstance } from "../utils/axios";
import { API_ENDPOINTS } from "./apiEndPoints";

export interface CreateEmissionData {
  sourceName: string;
  description: string;
  controlStatus: string;
  categoryId: number;
  subCategoryId: number;
  assetId: number;
  scope: string;
}

export interface GetEmissionSourceData {
  search?: string;
  controlStatuses?: string[];
  categoryIds?: number[];
  assetIds?: number[];
  statuses?: string[];
  page?: number;
  size?: number;
  sort?: string;
}

export interface EmissionSourceStatusData {
  emissionSourceId: number;
  status: string;
}

export const emissionCategory = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.CATEGORY);
    return response.data;
  } catch (error: any) {
    console.error(
      "Category fetch failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Category fetch failed.";
  }
};

export const emissionSubCategory = async (id: number) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.SUB_CATEGORY(id));
    return response.data;
  } catch (error: any) {
    console.error(
      "Sub Category fetch failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Sub Category fetch failed.";
  }
};

export const emissionAssetTypes = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.ASSET_TYPES);
    return response.data;
  } catch (error: any) {
    console.error(
      "Asset Types fetch failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Asset Types fetch failed.";
  }
};

export const createEmission = async (data: CreateEmissionData) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.EMISSION_SOURCE,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Emission failed.";
  }
};

export const getEmissionSource = async (data: GetEmissionSourceData) => {
  try {
    let queryParams = `search=${encodeURIComponent(data.search || "")}`;

    if (data.controlStatuses && data.controlStatuses.length > 0) {
      queryParams += `&controlStatuses=${data.controlStatuses.join(",")}`;
    }
    if (data.categoryIds && data.categoryIds.length > 0) {
      queryParams += `&categoryIds=${data.categoryIds.join(",")}`;
    }
    if (data.assetIds && data.assetIds.length > 0) {
      queryParams += `&assetIds=${data.assetIds.join(",")}`;
    }
    if (data.statuses && data.statuses.length > 0) {
      queryParams += `&statuses=${data.statuses.join(",")}`;
    }
    queryParams += `&page=${data.page?.toString()}`;
    queryParams += `&size=${data.size?.toString()}`;
    queryParams += `&sort=${data.sort}`;

    const url = `${API_ENDPOINTS.EMISSION_SOURCE}?${queryParams.replace(/%20/g, " ")}`;

    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Emission Source failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Emission Source failed.";
  }
};

export const emissionSourceStatus = async (
  payload: EmissionSourceStatusData
) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.EMISSION_SOURCE_STATUS,
      payload
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Emission Source Status failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Update Emission Source Status failed."
    );
  }
};

export const deleteEmissionSource = async (emissionSourceId: number) => {
  try {
    const response = await axiosInstance.delete(
      API_ENDPOINTS.EMISSION_SOURCE_BY_ID(emissionSourceId)
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Delete Emission Source failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Delete Emission Source failed.";
  }
};
