import { axiosInstance } from "../utils/axios";

export interface CalculateBuildingEmissionData {
  buildingId: number;
  buildingEmissionType: string;
  measuringValue: number;
}
export interface GetBuildingEmissionParams {
  buildingEmissionType: string;
  protocol?: string;
  buildingId: number;
}
export interface AddBuildingNoteParams {
  emissionId: number;
  note: string;
}

export interface SaveBuildingEmissionParams {
  buildingId: number;
  data: {
    buildingEmissionType: string;
    measuringValue: number;
    protocol?: string;
  }[];
}
export interface AddNoteData {
  emissionId: number;
  note: string;
}

export const getAllBuilding = async () => {
  try {
    const response = await axiosInstance.get(`/asset/building`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get All Building failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get All Building failed.";
  }
};

export const createOrUpdateBuildingPreferences = async (
  buildingId: number,
  preferences: any
) => {
  try {
    const response = await axiosInstance.put(
      `/scope-two/building-preferences/${buildingId}`,
      preferences
    );
    console.log("response of create or update building preferences:", response);
    return response.data;
  } catch (error: any) {
    console.error(
      "Error updating building preferences",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Error updating building preferences."
    );
  }
};

export const getBuildingPreferences = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-two/building-preferences/${id}`
    );
    console.log(response, "response of get building preferences");
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Building Preferences By Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Building Preferences By Id failed."
    );
  }
};

export const calculateBuildingEmission = async (
  data: CalculateBuildingEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      "/scope-two/calculate-building-emission",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error calculating building emission:", error);
    throw error;
  }
};

export const getBuildingEmission = async (data: GetBuildingEmissionParams) => {
  try {
    const response = await axiosInstance.get(`/scope-two/building-emission`, {
      params: {
        buildingEmissionType: data.buildingEmissionType,
        protocol: data.protocol,
        buildingId: data.buildingId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const saveScope2BuildingEmission = async (
  data: SaveBuildingEmissionParams
) => {
  try {
    const response = await axiosInstance.post(
      `/scope-two/building-emission`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const getScope2BuildingEmission = async (buildingId: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-two/building-emission?buildingId=${buildingId}`
    );
    console.log(
      `response of get scope 1 building emission for buildingId=${buildingId}`,
      response
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const addBuildingNote = async (data: AddBuildingNoteParams) => {
  try {
    const response = await axiosInstance.post(
      "/scope-two/building-emission/add-note",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error Add note:", error);
    throw error;
  }
};

export const deleteScope2BuildingEmission = async (emisssionId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-two/building-emission?emissionId=${emisssionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting building emission data:", error);
    throw error;
  }
};

export const addNoteToScope2BuildingEmission = async (data: AddNoteData) => {
  try {
    const response = await axiosInstance.post(
      `/scope-two/building-emission/add-note`,
      data
    );
    console.log("response of add note in Scope 1 building", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding note to building emission:", error);
    throw error;
  }
};

export const getNoteForScope2BuildingEmission = async (emissionId: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-two/building-emission/${emissionId}/view-note`
    );
    console.log("response of view note in Scope 1 building", response.data);
    return response.data;
  } catch (error) {
    console.error("Error Getting note to building emission:", error);
    throw error;
  }
};

export const addAttachmentToScope2BuildingEmission = async (
  emissionId: number,
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/scope-two/building-emission/${emissionId}/upload-attachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(
      "response of upload attachment in Scope 1 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading attachment to building emission:", error);
    throw error;
  }
};

export const viewScope2BuildingAttachment = async (emissionId: number) => {
  try {
    const response = await axiosInstance.post(
      `/scope-two/building-emission/${emissionId}/view-attachment`
    );
    console.log(
      "response of view attachment in Scope 2 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error viewing attachment from building emission:", error);
    throw error;
  }
};

export const deleteScope2BuildingAttachment = async (attachmentId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-two/building-attachment?attachmentId=${attachmentId}`
    );
    console.log(
      "response of delete attachment in Scope 2 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting attachment from building emission:", error);
    throw error;
  }
};
