import { Stack } from "@mui/material";
import { FC, Fragment, useState } from "react";
import Users from "./users/users";
import Roles from "./roles/roles";
import useCustomTabs from "../../../hooks/useCustomTabs";
import { TabsCard } from "../../../ui";

const TeamManagement: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const tabItems = [
    { label: "Users", content: <Users /> },
    {
      label: "Roles",
      content: <Roles onModalStateChange={setIsModalOpen} />,
    },
  ];

  const { TabsHeader, TabContent } = useCustomTabs(tabItems);

  return (
    <Fragment>
      <Stack spacing={3}>
        {!isModalOpen && (
          <TabsCard>
            <TabsHeader />
          </TabsCard>
        )}
        <TabContent />
      </Stack>
    </Fragment>
  );
};

export default TeamManagement;
