// import { create } from "zustand";

// interface AppState {
//   dashboardTitle: string;
//   setDashboardTitle: (title: string) => void;
// }

// const useAppStore = create<AppState>((set) => ({
//   dashboardTitle: "Dashboard",
//   setDashboardTitle: (title: string) => set({ dashboardTitle: title }),

// }));

// export default useAppStore;

import { create } from "zustand";

// Default pagination settings
export const DEFAULT_PAGINATION = { page: 1, limit: 10, total: 0 };

export interface AppState {
  // Dashboard Title Management
  dashboardTitle: string;
  setDashboardTitle: (title: string) => void;

  // Pagination State Management
  pagination: {
    [moduleId: string]: {
      page: number;
      limit: number;
      total: number;
    };
  };
  updatePagination: (
    moduleId: string,
    updates: Partial<AppState["pagination"][string]>
  ) => void;
  getPaginationParams: (moduleId: string) => { page: number; limit: number };
}

const useAppStore = create<AppState>((set, get) => ({
  // Dashboard Title Management
  dashboardTitle: "Dashboard",
  setDashboardTitle: (title: string) => set({ dashboardTitle: title }),

  // Pagination State Management
  pagination: {},

  updatePagination: (moduleId, updates) =>
    set((state) => ({
      pagination: {
        ...state.pagination,
        [moduleId]: {
          ...DEFAULT_PAGINATION,
          ...state.pagination[moduleId], // Preserve existing pagination state
          ...updates, // Apply new updates
        },
      },
    })),

  getPaginationParams: (moduleId) => {
    const state = get().pagination[moduleId] || DEFAULT_PAGINATION;
    return { page: state.page, limit: state.limit };
  },
}));

export default useAppStore;
