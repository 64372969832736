import React, { ComponentType } from "react";
import { Navigate } from "react-router-dom";
import { useUserContext } from "../context/user-context";

// Define the props for PublicRoute component
interface PublicRouteProps<T extends object = {}> {
  component: ComponentType<T>;
  componentProps?: T;
}

function PublicRoute<T extends object = {}>({
  component: Component,
  componentProps,
}: PublicRouteProps<T>) {
  const { user } = useUserContext();

  if (user) {
    return <Navigate to="/dashboard" />;
  }
  return <Component {...(componentProps as T & JSX.IntrinsicAttributes)} />;
}

export default PublicRoute;
