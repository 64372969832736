import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { VerticalDotsIcon } from "../../icons";
import { styles } from "./custom-table.style";
import { noDataAvatar } from "../../assets/images";
import CustomButton from "../custom-button/custom-button";
import TruncatedTextWithTooltip from "../custom-tooltip/custom-tooltip";

import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  getStatusDotColor,
  getStatusStyle,
} from "../../utils/getStatusStyle";
interface Column {
  key: string;
  label: string;
}

export interface RowData {
  [key: string]: string | number;
  id: number | string;
}

interface RowAction {
  text: string;
  onClick: (row: RowData) => void;
}

interface CustomTableProps {
  columns: Column[];
  data: RowData[];
  rowActions?: RowAction[];
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  rowActions,
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<RowData | null>(null);

  const navigate = useNavigate();

  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    row: RowData
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleActionClick = (action: RowAction) => {
    if (selectedRow) {
      action.onClick(selectedRow);
      handleMenuClose();
    }
  };

  const handleOpen = () => {
    navigate(`?action=add`, { replace: true });
  };
  const handleOpenImportModal = () => {
    navigate(`?action=import-csv`, { replace: true });
  };

  return (
    <React.Fragment>
      {data.length > 0 && (
        <React.Fragment>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "none !important",
              border: "1px solid #EAECF0",
              borderCollapse: "unset",
              borderRadius: "8px",
            }}
          >
            <Table
              sx={{
                borderRadius: "8px",
                boxShadow: "none !important",
              }}
            >
              <TableHead>
                <TableRow sx={styles.tableHeader}>
                  {columns.map((column) => (
                    <TableCell key={column.key} sx={styles.tableTextHead}>
                      {column.label}
                    </TableCell>
                  ))}
                  {rowActions && rowActions?.length > 0 && (
                    <TableCell sx={styles.tableTextHead}>Action</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow
                    key={row.id || index}
                    sx={{
                      ...styles.tableBody,
                      ...(index % 2 === 0 ? styles.evenRow : styles.oddRow),
                    }}
                  >
                    {columns.map((column) => (
                      <TableCell
                        sx={{ color: "#6E7793", fontSize: "14px" }}
                        key={column.key}
                      >
                        {column.key === "description" ? (
                          <TruncatedTextWithTooltip
                            text={row[column.key] as string}
                            maxLength={20}
                          />
                        ) : column.key === "status" ? (
                          <span
                            style={getStatusStyle(row[column.key] as string)}
                          >
                            <span
                              style={{
                                ...styles.dot,
                                backgroundColor: getStatusDotColor(
                                  row[column.key] as string
                                ),
                              }}
                            ></span>
                            {capitalizeFirstLetter(row[column.key] as string)}
                          </span>
                        ) : (
                          row[column.key]
                        )}
                      </TableCell>
                    ))}
                    {rowActions && rowActions?.length > 0 && (
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          onClick={(event) => handleMenuOpen(event, row)}
                        >
                          <VerticalDotsIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {rowActions && rowActions?.length > 0 && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {rowActions?.map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => handleActionClick(action)}
                  >
                    {action.text}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </TableContainer>
          <Pagination
            sx={{
              mt: 3,
              mx: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            color="secondary"
            shape="rounded"
            count={totalPages}
            page={currentPage}
            onChange={onPageChange}
            showFirstButton
            showLastButton
          />
        </React.Fragment>
      )}
      {data.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: { md: "512px", xs: "300px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <img src={noDataAvatar} alt="no-data" />
            <Box sx={{ display: "flex", gap: 2 }}>
              <CustomButton
                text="Add Data Manually"
                onClick={() => handleOpen()}
              />
              <CustomButton
                text="Bulk Import Data"
                onClick={() => handleOpenImportModal()}
                bgColor="transparent"
              />
            </Box>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default CustomTable;
