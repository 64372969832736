import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import CustomModal from "../../../../../ui/custom-modal/custom-modal";
import { CustomTextField } from "../../../../../ui";

interface AddNoteModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  noteValue: string;
  isViewingNote: boolean;
}

const AddNoteModal: FC<AddNoteModalProps> = ({
  open,
  onClose,
  onSave,
  noteValue,
  onNoteChange,
  isViewingNote,
}) => {
  const handleSubmit = () => {
    if (!isViewingNote) {
      onSave();
    }
    onClose();
  };

  const fields = {
    name: "note",
    label: "Add Note",
    placeholder: "Enter your note here",
    type: "textarea",
    value: noteValue,
    onChange: onNoteChange,
    disabled: isViewingNote,
  };

  return (
    <CustomModal
      title={isViewingNote ? "View Note" : "Add Note"}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      actions={
        isViewingNote
          ? [{ text: "Close", onClick: onClose, bgColor: "transparent" }]
          : [
              { text: "Discard", onClick: onClose, bgColor: "transparent" },
              { text: "Save", type: "submit", onClick: handleSubmit },
            ]
      }
    >
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomTextField {...fields} />
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
};

export default AddNoteModal;
