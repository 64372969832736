import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "./custom-accordion-configuration.style";
import {
  createOrUpdateBuildingPreferences,
  getBuildingPreferences,
} from "../../api/scope2-building-api";
import { showToast } from "../../utils/showToast.util";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "../custom-switch/custom-switch";

interface CustomBuildingAccordionConfigurationProps {
  buildingId?: number | undefined | any;
  savePreferences: boolean;
  navigateUrl: string;
  // setIsTabsView: (value: any) => void;
  // setIsConfigurationTabsView: (value: any) => void;
}

const CustomBuildingAccordionConfiguration: FC<
  CustomBuildingAccordionConfigurationProps
> = ({ buildingId, savePreferences, navigateUrl }) => {
  const navigate = useNavigate();
  const [expandedElectricity, setExpandedElectricity] =
    useState<boolean>(false);
  const [expandedPurchases, setExpandedPurchases] = useState<boolean>(false);


  const [preferences, setPreferences] = useState<any>({
    electricityPreference: {
      gridElectricity: false,
      onSiteRenewables: false,
    },
    purchasedSteamHeatAndCooling: {
      steam: false,
      heating: false,
      coolingPreference: {
        electricDrivenChiller: false,
        naturalGasAdsorptionChiller: false,
        engineDrivenNaturalGasChiller: false,
        unknown: false,
      },
    },
  });

  useEffect(() => {
    const fetchBuildingPreferences = async () => {
      try {
        const response = await getBuildingPreferences(buildingId);
        setPreferences(response.data);
      } catch (error: any) {
        console.error("Error fetching building preferences:", error);
      }
    };

    if (buildingId) {
      fetchBuildingPreferences();
    }
  }, [buildingId]);

  const handleSavePreferences = async () => {
    try {
      const response = await createOrUpdateBuildingPreferences(
        buildingId,
        preferences
      );
      showToast(response.message);
      navigate(navigateUrl);
    } catch (error: any) {
      console.error("Error saving preferences", error);
      showToast(error, "error");
    }
  };

  useEffect(() => {
    if (savePreferences) {
      handleSavePreferences();
    }
  }, [savePreferences, handleSavePreferences]);

  const handleElectricitySwitchChange = () => {
    const newValue = !(
      preferences.electricityPreference.gridElectricity ||
      preferences.electricityPreference.onSiteRenewables
    );
    setPreferences((prev: any) => ({
      ...prev,
      electricityPreference: {
        gridElectricity: newValue,
        onSiteRenewables: newValue,
      },
    }));
    setExpandedElectricity(newValue);
  };

  const handlePurchasesSwitchChange = () => {
    const newValue = !(
      preferences.purchasedSteamHeatAndCooling.steam ||
      preferences.purchasedSteamHeatAndCooling.heating ||
      Object.values(
        preferences.purchasedSteamHeatAndCooling.coolingPreference
      ).some(Boolean)
    );
    setPreferences((prev: any) => ({
      ...prev,
      purchasedSteamHeatAndCooling: {
        steam: newValue,
        heating: newValue,
        coolingPreference: {
          electricDrivenChiller: newValue,
          naturalGasAdsorptionChiller: newValue,
          engineDrivenNaturalGasChiller: newValue,
          unknown: newValue,
        },
      },
    }));
    setExpandedPurchases(newValue);
  };

  const handleCheckboxChange = (
    section: string,
    key: string,
    subKey?: string
  ) => {
    setPreferences((prev: any) => {
      if (subKey) {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: {
              ...prev[section][key],
              [subKey]: !prev[section][key][subKey],
            },
          },
        };
      } else {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [key]: !prev[section][key],
          },
        };
      }
    });
  };

  return (
    <>
      {/* First Accordion */}
      <Accordion
        expanded={expandedElectricity}
        onChange={() => setExpandedElectricity(!expandedElectricity)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={styles.container}
            display="flex"
            alignItems="center"
            flex="1"
          >
            {/* Electricity Switch */}
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={
                    preferences.electricityPreference.gridElectricity ||
                    preferences.electricityPreference.onSiteRenewables
                  }
                  onChange={handleElectricitySwitchChange}
                  inputProps={{ "aria-label": "Electricity switch" }}
                />
              }
              label="Electricity"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>

        {(preferences.electricityPreference.gridElectricity ||
          preferences.electricityPreference.onSiteRenewables) && (
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "40px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences.electricityPreference.gridElectricity}
                    onChange={() =>
                      handleCheckboxChange(
                        "electricityPreference",
                        "gridElectricity"
                      )
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Grid Electricity checkbox" }}
                  />
                }
                label="Grid electricity"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences.electricityPreference.onSiteRenewables}
                    onChange={() =>
                      handleCheckboxChange(
                        "electricityPreference",
                        "onSiteRenewables"
                      )
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "On-site Renewables checkbox" }}
                  />
                }
                label="On-site renewables"
              />
            </Box>
          </AccordionDetails>
        )}
      </Accordion>

      {/* Second Accordion */}
      <Accordion
        expanded={expandedPurchases}
        onChange={() => setExpandedPurchases(!expandedPurchases)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={styles.container}
            display="flex"
            alignItems="center"
            flex="1"
          >
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={
                    preferences.purchasedSteamHeatAndCooling.steam ||
                    preferences.purchasedSteamHeatAndCooling.heating ||
                    Object.values(
                      preferences.purchasedSteamHeatAndCooling.coolingPreference
                    ).some(Boolean)
                  }
                  onChange={handlePurchasesSwitchChange}
                  inputProps={{ "aria-label": "Purchases Steam, Heat switch" }}
                />
              }
              label="Purchased Steam, heat and Cooling"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>

        {(preferences.purchasedSteamHeatAndCooling.steam ||
          preferences.purchasedSteamHeatAndCooling.heating ||
          Object.values(
            preferences.purchasedSteamHeatAndCooling.coolingPreference
          ).some(Boolean)) && (
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                marginLeft: "40px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences.purchasedSteamHeatAndCooling.steam}
                    onChange={() =>
                      handleCheckboxChange(
                        "purchasedSteamHeatAndCooling",
                        "steam"
                      )
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Steam checkbox" }}
                  />
                }
                label="Steam"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={preferences.purchasedSteamHeatAndCooling.heating}
                    onChange={() =>
                      handleCheckboxChange(
                        "purchasedSteamHeatAndCooling",
                        "heating"
                      )
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Heat checkbox" }}
                  />
                }
                label="Heat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Object.values(
                      preferences.purchasedSteamHeatAndCooling.coolingPreference
                    ).some(Boolean)}
                    onChange={() => {
                      const newValue = !Object.values(
                        preferences.purchasedSteamHeatAndCooling
                          .coolingPreference
                      ).some(Boolean);
                      setPreferences((prev: any) => ({
                        ...prev,
                        purchasedSteamHeatAndCooling: {
                          ...prev.purchasedSteamHeatAndCooling,
                          coolingPreference: {
                            electricDrivenChiller: newValue,
                            naturalGasAdsorptionChiller: newValue,
                            engineDrivenNaturalGasChiller: newValue,
                            unknown: newValue,
                          },
                        },
                      }));
                    }}
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": {
                        color: "#E8CA51",
                      },
                    }}
                    inputProps={{ "aria-label": "Cooling checkbox" }}
                  />
                }
                label="Cooling"
              />
            </Box>

            {/* Additional checkboxes for cooling */}
            {Object.values(
              preferences.purchasedSteamHeatAndCooling.coolingPreference
            ).some(Boolean) && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "60px",
                  marginTop: "10px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        preferences.purchasedSteamHeatAndCooling
                          .coolingPreference.electricDrivenChiller
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          "purchasedSteamHeatAndCooling",
                          "coolingPreference",
                          "electricDrivenChiller"
                        )
                      }
                      sx={{
                        color: "#8E98A8",
                        "&.Mui-checked": {
                          color: "#E8CA51",
                        },
                      }}
                      inputProps={{
                        "aria-label": "Electric driven chiller checkbox",
                      }}
                    />
                  }
                  label="Electric driven chiller"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        preferences.purchasedSteamHeatAndCooling
                          .coolingPreference.naturalGasAdsorptionChiller
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          "purchasedSteamHeatAndCooling",
                          "coolingPreference",
                          "naturalGasAdsorptionChiller"
                        )
                      }
                      sx={{
                        color: "#8E98A8",
                        "&.Mui-checked": {
                          color: "#E8CA51",
                        },
                      }}
                      inputProps={{
                        "aria-label": "Natural gas adsorption chiller checkbox",
                      }}
                    />
                  }
                  label="Natural gas adsorption chiller"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        preferences.purchasedSteamHeatAndCooling
                          .coolingPreference.engineDrivenNaturalGasChiller
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          "purchasedSteamHeatAndCooling",
                          "coolingPreference",
                          "engineDrivenNaturalGasChiller"
                        )
                      }
                      sx={{
                        color: "#8E98A8",
                        "&.Mui-checked": {
                          color: "#E8CA51",
                        },
                      }}
                      inputProps={{
                        "aria-label":
                          "Engine-driven natural gas chiller checkbox",
                      }}
                    />
                  }
                  label="Engine-driven natural gas chiller"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        preferences.purchasedSteamHeatAndCooling
                          .coolingPreference.unknown
                      }
                      onChange={() =>
                        handleCheckboxChange(
                          "purchasedSteamHeatAndCooling",
                          "coolingPreference",
                          "unknown"
                        )
                      }
                      sx={{
                        color: "#8E98A8",
                        "&.Mui-checked": {
                          color: "#8E98A8",
                        },
                      }}
                      inputProps={{ "aria-label": "Unknown checkbox" }}
                    />
                  }
                  label="Unknown"
                />
              </Box>
            )}
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default CustomBuildingAccordionConfiguration;
