import React from "react";
// import { Box, IconButton, Grid } from "@mui/material";
// import { FormProvider, useForm } from "react-hook-form";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   CustomButton,
//   CustomHeading,
//   CustomSubHeading,
//   CustomTextField,
// } from "../../../../ui";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import RolesPermissionSection from "./create-role-permissions";
// import { permissionsData } from "../../../../utils/getStatusStyle";
// import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface CreateRoleProps {
  isModalOpen?: boolean;
}
const schema = z.object({
  roleName: z
    .string()
    .min(1, "Role Name is required")
    .max(50, "Role Name must be less than 50 characters"),
  roleDescription: z
    .string()
    .min(1, "Role Description is required")
    .max(255, "Role Description must be less than 255 characters"),
  permissions: z.record(z.boolean()),
});

const CreateRole: React.FC<CreateRoleProps> = ({ isModalOpen }) => {
  return <div>Create Role</div>;
};

// const CreateRole: React.FC<CreateRoleProps> = ({ isModalOpen }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   type FormData = z.infer<typeof schema>;

//   const methods = useForm<FormData>({
//     resolver: zodResolver(schema),
//     defaultValues: {
//       roleName: "",
//       roleDescription: "",
//       permissions: {},
//     },
//     mode: "onChange",
//   });
//   const onSubmit = (data: FormData) => {
//     console.log("data", data);

//     const payload = {
//       roleName: data?.roleName,
//       roleDescription: data?.roleDescription,
//       permissions: permissionsData?.reduce((acc: any, section) => {
//         acc[section?.title ? section?.title : " "] =
//           section?.permissions?.reduce((subAcc: any, permission) => {
//             subAcc[permission.label] = permission.actions?.reduce(
//               (actionsAcc: any, action) => {
//                 actionsAcc[action] = false;
//                 return actionsAcc;
//               },
//               {}
//             );
//             return subAcc;
//           }, {});
//         return acc;
//       }, {}),
//     };
//     console.log("payload on SUbmit ", payload);
//   };

//   const handleArrowBackClick = () => {
//     navigate(location.pathname, { replace: true });
//   };
//   const handleClose = () => {
//     methods.reset();
//     navigate(location.pathname, { replace: true });
//   };
//   const fields = [
//     {
//       name: "roleName",
//       label: "Role Name",
//       placeholder: "Enter Role Name",
//       col: 6,
//     },
//     {
//       name: "roleDescription",
//       label: "Description",
//       placeholder: "Role Description",
//       col: 6,
//     },
//   ];

//   return (
//     <Box mt={1}>
//       <FormProvider {...methods}>
//         <form onSubmit={methods.handleSubmit(onSubmit)}>
//           <Box mb={3}>
//             <IconButton
//               onClick={handleArrowBackClick}
//               sx={{ color: "#000", marginBottom: "20px" }}
//             >
//               <ArrowBackIcon />
//             </IconButton>
//             <CustomHeading text="Role Details" variant="h5" />
//             <CustomSubHeading text="Please Provide the Following Details to create a New Role in the system!" />
//             <Grid container spacing={3} mt={0}>
//               {fields.map((field, index) => (
//                 <Grid item xs={12} sm={field.col} key={index}>
//                   <CustomTextField {...field} />
//                 </Grid>
//               ))}
//             </Grid>
//             <Box mt={3}>
//               <CustomHeading
//                 text="Role & Permissions"
//                 variant="h5"
//               ></CustomHeading>
//               <CustomSubHeading text="Please Provide the Following Details to create a New Role in the system!" />
//               <Box mt={3}>
//                 {permissionsData.map((section, index) => (
//                   <RolesPermissionSection
//                     key={index}
//                     title={section.title}
//                     permissions={section.permissions}
//                   />
//                 ))}
//               </Box>
//             </Box>
//             <Box
//               mt={3}
//               display="flex"
//               justifyContent="flex-end"
//               alignItems="center"
//               gap="16px"
//             >
//               <CustomButton
//                 text="Discard"
//                 bgColor="trasnparent"
//                 onClick={handleClose}
//               />
//               <CustomButton text="Assign Role" type="submit" />
//             </Box>
//           </Box>
//         </form>
//       </FormProvider>
//     </Box>
//   );
// };

export default CreateRole;
