import { z } from "zod";
export const Fuelschema = z.object({
  fuelType: z.string().min(1, "Fuel Type is required"),
  measurementValue: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine(
        (val) => val === null || val.toString().length >= 3,
        "Minimum 3 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  unit: z.string().min(1, "Unit is required"),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  emissionFactor: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z.number().nullable()
  ),
  protocol: z.string().min(1, "Protocol is required"),
  locationName: z.string().min(1, "Location Name is required"),
  assetName: z.string().min(1, "Asset Name is required"),
  reason: z.string().optional(),
});
