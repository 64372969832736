import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { styled } from "@mui/material/styles";

interface CustomDatePickerProps {
  name: string;
  control: any;
  label?: string;
  value?: Dayjs | null;
  format?: string;
  openTo?: "day" | "month" | "year";
  disabled?: boolean;
  disableFuture?: boolean;
  views?: Array<"year" | "month" | "day">;
  onChange?: (date: Dayjs | null) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  name,
  control,
  label = "Select Date",
  format = "YYYY-MM-DD",
  openTo = "day",
  disabled = false,
  disableFuture = true,
  views = ["year", "month", "day"],
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <StyledDatePicker
              label={label}
              views={views}
              format={format}
              openTo={openTo}
              disabled={disabled}
              value={field.value ? dayjs(field.value) : null}
              maxDate={disableFuture ? dayjs() : undefined}
              onChange={(newValue: Dayjs | null) => {
                const formattedDate = newValue
                  ? dayjs(newValue).format(format)
                  : null;
                field.onChange(formattedDate);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      )}
    />
  );
};

export default CustomDatePicker;

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {},
    "&:hover .MuiOutlinedInput-notchedOutline": {},
    "&.Mui-error .MuiOutlinedInput-notchedOutline": {},
  },

  "& .MuiInputBase-input": {
    padding: "14.5px 14px",
    fontFamily: "PoppinsRegular",
    "&::placeholder": {
      color: "#8E98A",
      fontSize: "0.875rem",
    },
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {},
  },
  "& .MuiInputLabel-shrink": {
    color: "#475467",
    fontSize: "0.875rem",
    fontFamily: "PoppinsRegular",
  },
  "& .Mui-disabled": {},
  "& .MuiSelect-select": {},
  "& .MuiFormHelperText-root": {},
  "& input:-webkit-autofill": {},
  "& .MuiSelect-icon": {
    display: "none",
  },
}));
