import React, { useState, FC, ReactNode } from "react";
import { Box, MenuItem, Menu, Stack, Typography } from "@mui/material";
import CustomButton from "../custom-button/custom-button";
import PlusIcon from "../../icons/PlusIcon";
import { useNavigate } from "react-router-dom";

interface CardProps {
  children?: ReactNode;
  headerText?: string;
  spacing?: number;
  headerBtnText?: string;
  btnActions?: BtnAction[];
  handleAddNew?: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}

const CardEmission: FC<CardProps> = ({
  children,
  headerText,
  spacing = 3,
  headerBtnText,
  btnActions,
  handleAddNew,
  setIsModalOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuItemClick = (action: string) => {
    setAnchorEl(null);
    if (action === "add-new") {
      handleAddNew && handleAddNew();
    } else if (action === "bulk-import") {
      setIsModalOpen(true);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Stack sx={styles["card-container"]} spacing={spacing}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {headerText && (
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            {headerText}
          </Typography>
        )}
        {headerBtnText && (
          <CustomButton
            text={headerBtnText}
            onClick={handleOpenMenu}
            icon={PlusIcon}
            iconPosition="end"
            sx={{ pl: 3 }}
          />
        )}
      </Box>
      {children && <Box>{children}</Box>}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        sx={{
          "& .MuiPaper-root": {
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            border: "1px solid #ECECEC",
            width: "240px",
          },
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick("add-new")}
          sx={menuItemStyles}
        >
          Add New
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItemClick("bulk-import")}
          sx={menuItemStyles}
        >
          Bulk Import Data
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default CardEmission;

const styles = {
  "card-container": {
    borderRadius: "12px",
    border: "0px solid #D0D5DD",
    padding: "24px 16px",
    background: "#FFF",
    boxShadow: "0px 0px 1px 0px rgba(0, 0, 0, 0.13)",
    marginTop: "0px",
  },
};

const menuItemStyles = {
  fontFamily: "Poppins, sans-serif",
  fontSize: "16px",
  color: "#5A5A5A",
  padding: "12px 16px",
  "&:hover": {
    backgroundColor: "#F7F7F7",
  },
};

interface BtnAction {
  text: string;
  onClick: () => void;
}
