import React, { FC, useEffect, useState } from "react";
import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import AddEditFugitiveEmission from "./add-edit-fugitive-emission";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../../../../styles/theme";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import { FormProvider, useForm } from "react-hook-form";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import {
  getFugitiveEmission,
  getFugitiveEmissionByFileId,
} from "../../../../../api/fugitive-emission-api";
import ViewReasonFugitiveEmission from "./view-reason-fugitive-emission";
import {
  getAssetsByLocationId,
  getLocattions,
  getUploadedFiles,
} from "../../../../../api/fuel-api";
import { Close as CloseIcon, Label } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import { Fugitiveschema } from "../../../../../validations/fugitive-schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { formattedDate } from "../../../../../utils/getStatusStyle";

type FormData = z.infer<typeof Fugitiveschema>;

const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];

const FugitiveEmissions: FC = () => {
  const navigate = useNavigate();
  const [isGridView, setIsGridView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [fugitiveEmissionId, setFugitiveEmissionId] = useState<any | null>(
    null
  );
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const itemsPerPage = 8;

  const methods = useForm<FormData>({
    resolver: zodResolver(Fugitiveschema),
    mode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      source: "",
      emissionFrequency: null,
      duration: null,
      emissionFactor: null,
      potentialEmissionPoint: "",
      dataCollectionMethod: "",
      protocol: "",
      reason: "",
      locationName: "",
      assetName: "",
    },
  });

  const { watch } = methods;
  const locationName = watch("locationName")
    ? Number(watch("locationName"))
    : null;

  const assetId = watch("assetName") ? Number(watch("assetName")) : null;
  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: getLocattions,
  });

  const { data: assetsData } = useQuery({
    queryKey: ["assets", locationName],
    queryFn: () => getAssetsByLocationId(Number(locationName)),
    enabled: !!locationName,
  });
  const assets = Array.isArray(assetsData) ? assetsData : [];

  const { data: fugitiveEmissionResponse, refetch: refetchFugitiveEmission } =
    useQuery({
      queryKey: [
        "fugitiveEmission",
        currentPage,
        protocol,
        locationName,
        assetId,
      ],
      queryFn: () =>
        getFugitiveEmission({
          page: currentPage - 1,
          size: itemsPerPage,
          locationId: locationName || undefined,
          assetId: assetId || undefined,
          protocol: protocol ? protocol.toUpperCase() : "CSRD",
          sort: "createdDate,desc",
        }),
    });

  const fugitiveEmissionData = fugitiveEmissionResponse?.data || [];
  const totalPages = fugitiveEmissionResponse?.totalPages || 1;

  const fugitiveData = fugitiveEmissionData.map((item: any, i: number) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    startDate: formattedDate(item.startDate),
  }));

  const handleRowUpdate = (updatedRow: any) => {
    refetchFugitiveEmission();
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProtocol(event.target.value);
    refetchFugitiveEmission();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setFugitiveEmissionId(row.fugitiveEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setFugitiveEmissionId(row.fugitiveEmissionId);
    navigate(`?action=viewReason`);
  };

  const { data: uploadedFilesResponse, refetch: refetchUploadedFiles } =
    useQuery({
      queryKey: ["FugitiveUploadedFiles", currentPage],
      queryFn: () =>
        getUploadedFiles({
          search: "",
          typeOfEmission: "FUGITIVE",
          page: currentPage - 1,
          size: itemsPerPage,
          sort: "createdDate,desc",
        }),
    });
  const uploadedFilesData = uploadedFilesResponse?.data || [];
  const fileData = uploadedFilesData.map((item: any, i: number) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  useEffect(() => {
    refetchUploadedFiles();
  }, [currentPage, refetchUploadedFiles]);

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getFugitiveEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };
  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const fields = [
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      col: 4,
      icon: <ChevronDown />,
      onChange: handleProtocolChange,
      options: protocols,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 3,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 3,
      defaultValue: locationName ? "" : watch("assetName"),
    },
  ];

  return (
    <Card
      headerText="Fugitive Emissions"
      headerBtnText="Add New"
      searchPlaceholder="Hinted search text"
      btnActions={[{ text: "Edit", onClick: () => {} }]}
      tableDataLength={fugitiveEmissionData.length}
      currentPage={currentPage}
      uploadUrl="/fugitive-emission/upload"
      fileType="FUGITIVE"
    >
      <AddEditFugitiveEmission
        methods={methods}
        fugitiveEmissionId={fugitiveEmissionId}
        handleRowUpdate={handleRowUpdate}
        currentPage={currentPage}
        locations={locations}
        assets={assets}
        refetchFugitiveEmission={refetchFugitiveEmission}
        refetchUploadedFiles={refetchUploadedFiles}
      />
      <ViewReasonFugitiveEmission fugitiveEmissionId={fugitiveEmissionId} />
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.col} key={index}>
              <TextField
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                select={field.select}
                fullWidth
                onChange={field.onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ChevronDown />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: any) => {
                    if (!value) {
                      return (
                        <span style={{ color: theme.palette.grey[200] }}>
                          {field.placeholder}
                        </span>
                      );
                    }
                    const selectedOption = field.options.find(
                      (option: any) => option.value === value
                    );
                    return selectedOption ? selectedOption.label : value;
                  },
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          <Stack direction="row" gap={1} marginLeft={"auto"}>
            <IconButton
              sx={{
                backgroundColor: isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(false)}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: !isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(true)}
            >
              <LayoutGridIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={fugitiveData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={fugitiveData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}

      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Fugitive Emission
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Hinted search text"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>
        <CustomTable
          columns={columns2}
          data={fileData}
          rowActions={[
            {
              text: "View Details",
              onClick: (row: any) => {
                handleViewDetails(row.fileId);
              },
            },
          ]}
        />

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Fugitive Emission Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default FugitiveEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "fugitiveEmissionSource", label: "Fuel Type" },
  { key: "emissionEventFrequency", label: "Emission Frequency" },
  { key: "durationOfFugitiveEvent", label: "Duration" },
  { key: "location", label: "Location" },
  { key: "assetName", label: "Asset Name" },
  { key: "fugitiveDataCollectionMethod", label: "Data Collection" },
  { key: "startDate", label: "Upload Date" },
  { key: "fugitiveEmissionFactor", label: "Emission Factor" },
  { key: "calculatedEmission", label: "Calculated Emission" },
  { key: "status", label: "Status" },
];

const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
