import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import { ChevronDown } from "../../../../../icons";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import UploadCsvModal from "../../../../../ui/upload-csv-modal";
import { showToast } from "../../../../../utils/showToast.util";
import { sampleDownloadTemplate } from "../../../../../api/fuel-api";

interface BulkImportDataProps {
  isModalOpen: boolean;
  onClose: () => void;
  locationId: number;
  fileType: string;
}

const schema = z.object({
  locationName: z.string().min(1, "Location is required").max(255),
});

type FormData = z.infer<typeof schema>;

const BulkImportData: React.FC<BulkImportDataProps> = ({
  isModalOpen,
  onClose,
  locationId,
  fileType,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      locationName: "",
    },
  });

  const onSubmit = (formData: FormData) => {
    console.log("formData", formData);
  };
  const handleBulkUploadClick = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };
  const handleDownloadTemplate = async () => {
    try {
      const fileData = await sampleDownloadTemplate(fileType || "");
      if (fileData.data.url) {
        window.open(fileData.data.url, "_blank");
      }
      onClose();
    } catch (error: any) {
      console.error("Download failed", error);
      showToast(error, "error");
    }
  };
  const fields = [
    {
      name: "location",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
      ],
      col: 12,
    },
    {
      name: "startDate",
      label: "Start Date",
      placeholder: "Start Date",
      col: 6,
    },
    {
      name: "endDate",
      label: "End Date",
      placeholder: "End Date",
      col: 6,
    },
  ];

  const measurementItems = [
    {
      label: "Without contractual instruments",
      options: [
        {
          name: "gridElectricity",
          label: "Grid electricity",
          units: ["kg CO₂e/kWh Unit"],
          isTextOnly: true,
        },
      ],
    },
    {
      label: "With contractual instruments",
      options: [
        {
          name: "withContractNot",
          label: "I do not have custom factor",
          units: ["kg CO₂e/kWh Unit "],
          isTextOnly: true,
        },
        {
          name: "withContractDO",
          label: "I do have custom factor",
          units: ["kg CO₂e/kWh Unit "],
          isTextOnly: true,
        },
      ],
    },
    {
      label: "Direct line microgrid (fossil fuel)",
      options: [
        {
          name: "withContractDontFossil",
          label: "I do not have custom factor",
          units: ["kg CO₂e/kWh Unit "],
          isTextOnly: true,
        },
        {
          name: "withContractDOFossil",
          label: "I do have custom factor",
          units: ["kg CO₂e/kWh Unit "],
          isTextOnly: true,
        },
      ],
    },
    {
      label: "Onsite renewables",
      options: [
        {
          name: "on-site-renewables",
          label: "",
          units: ["kg CO₂e/kWh Unit"],
          isTextOnly: true,
        },
      ],
    },
    {
      label: "Onsite fossil fuel",
      options: [
        {
          name: "on-site-fossil-fuel",
          label: "",
          units: ["kg CO₂e/kWh Unit"],
          isTextOnly: true,
        },
      ],
    },
    {
      label: "Purchased Steam, Heat, and Cooling",
      options: [
        {
          name: "purchases-steam-heating-colling",
          label: "",
          units: ["kg CO₂e/kWh Unit"],
          isTextOnly: true,
        },
      ],
    },
  ];

  return (
    <CustomModal
      open={isModalOpen}
      onClose={onClose}
      title="Import Data"
      actions={[
        { text: "Cancel", onClick: onClose, bgColor: "transparent" },
        {
          text: "Bulk Upload",
          type: "submit",
          onClick: handleBulkUploadClick,
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Bulk Import Data" variant="h5" />
        <CustomSubHeading text="On the Bulk Import Data screen, select your fields, download the template, fill it in, and upload to quickly import your data!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {fields.map((field) => (
              <Grid item xs={12} sm={field.col} key={field.name}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>

      <Box mt={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CustomHeading text="Measurement & Units" variant="h5" />
          <CustomButton onClick={handleDownloadTemplate}>
            Download Template
          </CustomButton>
        </Box>
        <CustomSubHeading text="Set your preferred units and download the template." />
      </Box>

      {measurementItems.map((section) => (
        <Box key={section.label} mt={2}>
          <Typography
            sx={{
              color: "var(--Black, #090909)",
              fontFamily: "PoppinsRegular",
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "24px",
            }}
          >
            {section.label}
          </Typography>
          {section.options.map((item) => (
            <Box key={item.name} mt={2} mb={1}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                variant="h6"
              >
                {item.label}
              </Typography>
              {item.isTextOnly ? (
                <Typography
                  sx={{
                    color: "var(--Gray, #6B7280)",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {item.units[0]}
                </Typography>
              ) : null}
            </Box>
          ))}
        </Box>
      ))}
      <Box>
        {isUploadModalOpen && (
          <UploadCsvModal
            open={isUploadModalOpen}
            onClose={closeUploadModal}
            id={locationId}
          />
        )}
      </Box>
    </CustomModal>
  );
};

export default BulkImportData;
