import { z } from "zod";

export const Fugitiveschema = z.object({
  source: z.string().min(1, "Source is required"),
  emissionFrequency: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Emission Frequency must be a positive number",
    }),
  duration: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Emission Frequency must be a positive number",
    }),
  potentialEmissionPoint: z
    .string()
    .min(1, "Potential Emission Point is required"),
  emissionFactor: z
    .preprocess(
      (val) => (val === "" ? null : Number(val)),
      z.number().nullable()
    )
    .refine((val) => val !== null && val > 0, {
      message: "Emission Factor must be a positive number",
    })
    .refine(
      (val) => val === null || val.toString().length >= 3,
      "Minimum 3 digits are required"
    )
    .refine(
      (val) => val === null || val.toString().length <= 15,
      "Maximum 15 digits are allowed"
    )
    .refine(
      (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
      "Decimal places up to 3 are allowed"
    ),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  protocol: z.string().min(1, "Protocol is required"),
  locationName: z.string().min(1, "Location is required"),
  assetName: z.string().min(1, "Asset Name is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});
