import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { MutationConfig } from "../../../../utils/queryClient";
import {
  updateLocation,
  updateLocationData,
} from "../../../../api/locations-api";

export const useUpdateLocation = (
  config?: MutationConfig<typeof updateLocation>
): UseMutationResult<void, AxiosError, updateLocationData> => {
  return useMutation<void, AxiosError, updateLocationData>({
    mutationFn: updateLocation,
    ...config,
  });
};
