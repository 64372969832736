import { Permission } from "../components/dashboard/team-management/roles/create-role-permissions";
import { styles } from "../ui/custom-table/custom-table.style";

export const getStatusStyle = (status: string): React.CSSProperties => {
  const normalizedStatus = status?.trim().toLowerCase();

  switch (normalizedStatus) {
    case "inactive":
      return styles.statusInactive;
    case "active":
      return styles.statusActive;
    case "completed":
      return styles.statusCompleted;
    case "processing":
      return styles.statusProcessing;
    case "error":
      return styles.statusError;
    default:
      return styles.statusInactive;
  }
};

export const getStatusDotColor = (status: string): string => {
  const normalizedStatus = status?.trim().toLowerCase();

  switch (normalizedStatus) {
    case "inactive":
      return "#DA3E33"; // Red
    case "active":
    case "completed":
      return "#12B76A"; // Green
    case "processing":
      return "#04A2FB"; // Blue
    case "error":
      return "#DA3E33"; // Red
    default:
      return "gray"; // Gray if none match
  }
};

export const capitalizeFirstLetter = (status: string) => {
  if (!status) return "";
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
};

export const formatDateToDDMMYYYY = (dateInput: string | Date): string => {
  const dateObj = new Date(dateInput);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();

  return `${day}-${month}-${year}`;
};

export const formattedDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
};

export const formatTime = (timeString: string): string => {
  if (!timeString) return "";

  try {
    const date = new Date(timeString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  } catch (error) {
    console.error("Error formatting time:", error);
    return "";
  }
};

// export const permissionsData: Permission[] = [

// ];

// export const permissionsData: Permission[] = [
//   {
//     title: "Identification of Emission Sources Module",
//     permissions: [
//       {
//         label: "Asset and Location Management",
//         key: "assetAndLocationManagement",
//         actions: [
//           "Create an Asset",
//           "Edit Asset details",
//           "Delete an Asset",
//           "Make a location as default",
//           "Edit location details",
//           "View access",
//           "Edit access",
//           "View list of assets (sort, search, filter)",
//           "View list of locations (search, sort, filter)",
//           "Add new location",
//           "Delete access",
//           "Create access",
//         ],
//       },
//       {
//         label: "Emission Source Management",
//         key: "emissionSourceManagement",
//         actions: [
//           "Create an Asset",
//           "Edit Asset details",
//           "Delete an Asset",
//           "Make a location as default",
//           "Edit location details",
//           "View access",
//           "Edit access",
//           "View list of assets (sort, search, filter)",
//           "View list of locations (search, sort, filter)",
//           "Add new location",
//           "Delete access",
//           "Create access",
//         ],
//       },
//     ],
//   },
//   {
//     title: "Measurement and Quantification Module",
//     permissions: [
//       {
//         label: "Manage Emission Factors for a protocol",
//         key: "measurementAndQuantificationModule",
//         actions: [
//           "Import emission factors for a protocol",
//           "Change status of a protocol",
//           "Delete a file including all emission factors",
//           "View access",
//           "Edit access",
//           "View list of emission factors of a protocol",
//           "Convert emissions measurements into standard units for scope 1 emissions",
//           "Delete access",
//           "Create access",
//         ],
//       },
//     ],
//   },
//   {
//     title: "Data Collection Module",
//     permissions: [
//       {
//         label: "Data Collection for scope-1",
//         key: "dataCollectionForScopeOne",
//         actions: [
//           "Enter data manually for fuel combustion",
//           "Enter details manually for fugitive emissions",
//           "Import file for fuel combustion emissions",
//           "Import file for bulk import of fugitive emissions",
//           "Import file for bulk import of mobile sources emissions",
//           "View access",
//           "Edit access",
//           "Bulk Import data for Locations emission calculation",
//           "Enter details for manually adding process emissions",
//           "Bulk Import data for building emission calculation",
//           "Import file for bulk import of process emissions",
//           "Delete access",
//           "Create access",
//           "Add data manually for calculating building emissions",
//           "Enter details manually for adding mobile sources emissions",
//           "Bulk Import data for Locations emission calculation",
//         ],
//       },
//       {
//         label: "Data Collection for scope-2",
//         key: "dataCollectionForScopeTwo",
//         actions: [
//           "Add data manually for calculating building emissions",
//           "Bulk Import data for Locations emission calculation",
//           "View access",
//           "Edit access",
//           "Add data manually for calculating emissions related to location",
//           "Add new location",
//           "Delete access",
//           "Create access",
//         ],
//       },
//     ],
//   },

//   {
//     title: "Documentation and Reporting Module",
//     permissions: [
//       {
//         label: "Manage Templates",
//         key: "documentationAndReportingModule",
//         actions: [
//           "View list of templates (Search, filter, sort)",
//           "View detail of a template/document",
//           "Customize a template/document",
//           "View access",
//           "Edit access",
//           "Share template/document with others",
//           "Delete a custom template/document",
//           "Set reminders for template/doc completion",
//           "Delete access",
//           "Create access",
//         ],
//       },
//     ],
//   },

//   {
//     title: "Shared Workspaces",
//     permissions: [
//       {
//         label: "Workspaces",
//         key: "workSpace",
//         actions: [
//           "Create Workspace",
//           "Edit Workspace",
//           "View Workspace",
//           "Create folder in workspace",
//           "Delete folder in workspace",
//           "View access",
//           "Edit access",
//           "Remove document from folder",
//           "Share doc (via link, copy link)",
//           "Share Workspace (share via link, copy link)",
//           "Invite user to access workspace",
//           "Edit folder name in workspace",
//           "Delete access",
//           "Create access",
//           "Delete Workspace",
//           "View folders in workspace",
//           "Upload document in folder",
//           "View documents in folder",
//         ],
//       },
//     ],
//   },

//   {
//     title: "Team Management",
//     permissions: [
//       {
//         label: "Manage Users",
//         key: "manageUsers",
//         actions: [
//           "Invite User",
//           "Edit user details",
//           "View access",
//           "Edit access",
//           "Search, sort and Filter invited users",
//           "Delete a team member/user account",
//           "Change status of invited user",
//           "Delete access",
//           "Create access",
//         ],
//       },
//       {
//         label: "Manage Roles",
//         key: "manageRoles",
//         actions: [
//           "Create a New Role",
//           "Edit Role Details",
//           "Delete a role",
//           "View List of all Roles",
//           "Search, sort  and Filter the Roles",
//         ],
//       },
//     ],
//   },

//   {
//     title: "Calculation of Emissions Module",
//     permissions: [
//       {
//         label: "Evaluations for Scope-1",
//         key: "emissionsForScopeOne",
//         subsections: [
//           {
//             label: "Fuel Combustion Emissions",
//             key: "fuelCombustionEmissionPermissions",
//             actions: [
//               "View list of fuel combustion details",
//               "Edit fuel combustion details",
//             ],
//           },
//           {
//             label: "Process Emissions",
//             key: "processEmissionPermissions",
//             actions: [
//               "View list of process emissions",
//               "Edit process emissions details",
//             ],
//           },
//           {
//             label: "Fugitive Emissions",
//             key: "fugitiveEmissionPermissions",
//             actions: [
//               "View list of fugitive emissions",
//               "Edit fugitive emissions details",
//             ],
//           },
//           {
//             label: "Mobile Sources Emissions",
//             key: "mobileSourceEmissionPermissions",
//             actions: [
//               "View list of mobile sources emissions data",
//               "Edit mobile sources emission details",
//             ],
//           },
//           {
//             label: "Building Emissions",
//             key: "buildingEmissionPermissions",
//             actions: [
//               "Configure Building parameters/preferences",
//               "Upload attachment as proof with a building",
//               "View attachments of a building",
//               "Update building emissions details",
//               "Add note for a building",
//               "View note of a building",
//             ],
//           },
//           {
//             label: "Location Emissions",
//             key: "locationEmissionPermissions",
//             actions: [
//               "Configure location Preferences",
//               "Upload attachment as proof with location emission",
//               "View attachments of a location",
//               "Update location emissions details",
//               "Add note for a location",
//               "View note of a location",
//             ],
//           },
//         ],
//       },

//       {
//         label: "Evaluations for Scope-2",
//         subsections: [
//           {
//             label: "Emissions related to buildings",
//             key: "emissionsRelatedToBuilding",
//             actions: [
//               "Configure Building parameters/preferences",
//               "Upload attachment as proof with a building",
//               "View attachments of a building",
//               "Update building emissions details",
//               "Add note for a building",
//               "View note of a building",
//             ],
//           },
//           {
//             label: "Emissions related to locations",
//             key: "emissionsRelatedToLocation",
//             actions: [
//               "Configure location Preferences",
//               "Add note for location emission",
//               "View attachments of a location",
//               "Update location emission details",
//               "Upload attachment as proof with location emission",
//               "View note of a location emission",
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];
