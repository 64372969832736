import React, { FC, useState, useEffect } from "react";
import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import AddEditFuelCombustion from "./add-edit-fuel-combustion";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import {
  getAssetsByLocationId,
  getFuelCombustion,
  getFuelCombustionByFileId,
  getLocattions,
  getUploadedFiles,
} from "../../../../../api/fuel-api";
import ViewReasonFuelCombustion from "./view-reason-fule-combustion";
import { Close as CloseIcon } from "@mui/icons-material";
import ImportBulkDataModal from "./import-data-modal";
import { useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Fuelschema } from "../../../../../validations/fuel-validations-schema";
import { formattedDate } from "../../../../../utils/getStatusStyle";

type FormData = z.infer<typeof Fuelschema>;

const protocols = [
  { value: "csrd", label: "CSRD" },
  { value: "secr", label: "SECR" },
];
const FuelCombustion: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isGridView, setIsGridView] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentFilePage, setCurrentFilePage] = useState<number>(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [combustionEmissionId, setCombustionEmissionId] = useState<any | null>(
    null
  );
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isModalOpen = searchParams.get("action") === "import-csv";
  const itemsPerPage = 8;

  const methods = useForm<FormData>({
    resolver: zodResolver(Fuelschema),
    mode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      fuelType: "",
      measurementValue: null,
      unit: "",
      emissionFactor: null,
      dataCollectionMethod: "",
      locationName: "",
      protocol: "",
      reason: "",
      assetName: "",
    },
  });

  const { watch } = methods;
  const locationName = watch("locationName")
    ? Number(watch("locationName"))
    : null;

  const assetId = watch("assetName") ? Number(watch("assetName")) : null;

  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: getLocattions,
  });

  const { data: assetsData } = useQuery({
    queryKey: ["assets", locationName],
    queryFn: () => getAssetsByLocationId(locationName || 0),
    enabled: !!locationName,
  });

  const assets = Array.isArray(assetsData) ? assetsData : [];
  const { data: fuelCombustionResponse, refetch: refetchFuelCombustion } =
    useQuery({
      queryKey: [
        "fuelCombustion",
        currentPage,
        protocol,
        locationName,
        assetId,
      ],
      queryFn: () =>
        getFuelCombustion({
          page: currentPage - 1,
          size: itemsPerPage,
          protocol: protocol ? protocol.toUpperCase() : "CSRD",
          locationId: locationName || undefined,
          assetId: assetId || undefined,
          sort: "createdDate,desc",
        }),
    });
  const fuelCombustionData = fuelCombustionResponse?.data || [];
  const totalPages = fuelCombustionResponse?.totalPages || 1;

  const handleRowUpdate = (updatedRow: any) => {
    console.log("updatedRow", updatedRow);
    refetchFuelCombustion(updatedRow);
  };

  const fuelData = fuelCombustionData.map((item: any, i: number) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    startDate: formattedDate(item.startDate),
  }));

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProtocol(event.target.value);
    refetchFuelCombustion();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setCombustionEmissionId(row.fuelCombustionEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setCombustionEmissionId(row.fuelCombustionEmissionId);
    navigate(`?action=viewReason`);
  };

  const { data: uploadedFilesResponse, refetch: refetchUploadedFiles } =
    useQuery({
      queryKey: ["uploadedFiles", currentFilePage],
      queryFn: () =>
        getUploadedFiles({
          search: "",
          typeOfEmission: "FUEL",
          page: currentFilePage - 1,
          size: itemsPerPage,
          sort: "createdDate,desc",
        }),
    });
  const uploadedFilesData = uploadedFilesResponse?.data || [];
  const file = uploadedFilesData.map((item: any, i: number) => ({
    ...item,
    id: (currentFilePage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  useEffect(() => {
    refetchUploadedFiles();
  }, [currentFilePage, refetchUploadedFiles]);

  const handleFilePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentFilePage(value);
  };

  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getFuelCombustionByFileId({
        fileId,
        page: currentPage - 1,
        size: itemsPerPage,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  const fields = [
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      col: 3,
      icon: <ChevronDown />,
      onChange: handleProtocolChange,
      options: protocols,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 3,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 3,
      defaultValue: locationName ? "" : watch("assetName"),
    },
  ];

  return (
    <Card
      headerText="Fuel Combustion List"
      headerBtnText="Add New"
      btnActions={[{ text: "Edit", onClick: () => {} }]}
      tableDataLength={fuelCombustionData.length}
      currentPage={currentFilePage}
      uploadUrl="/fuel-combustion-emission/upload"
      fileType="FUEL"
    >
      <AddEditFuelCombustion
        methods={methods}
        combustionEmissionId={combustionEmissionId}
        handleRowUpdate={handleRowUpdate}
        refetchFuelCombustion={refetchFuelCombustion}
        refetchUploadedFiles={refetchUploadedFiles}
        currentPage={currentPage}
        currentFilePage={currentFilePage}
        locations={locations}
        locationName={locationName}
        assets={assets}
      />
      <ImportBulkDataModal isModalOpen={isModalOpen} onClose={handleClose} />
      <ViewReasonFuelCombustion combustionEmissionId={combustionEmissionId} />

      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.col} key={index}>
              <TextField
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                select={field.select}
                fullWidth
                onChange={field.onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ChevronDown />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: any) => {
                    if (!value) {
                      return (
                        <span style={{ color: theme.palette.grey[200] }}>
                          {field.placeholder}
                        </span>
                      );
                    }
                    const selectedOption = field.options.find(
                      (option: any) => option.value === value
                    );
                    return selectedOption ? selectedOption.label : value;
                  },
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          <Stack direction="row" gap={1} marginLeft={"auto"}>
            <IconButton
              sx={{
                backgroundColor: isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(false)}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: !isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(true)}
            >
              <LayoutGridIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={fuelData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => handleEditClick(row),
            },
            {
              text: "View Reason",
              onClick: (row) => handleViewReasonClick(row),
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={fuelData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => handleEditClick(row),
            },
            {
              text: "View Reason",
              onClick: (row) => handleViewReasonClick(row),
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Fuel Combustion Files
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Search"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>
        <CustomTable
          columns={columns2}
          data={file}
          rowActions={[
            {
              text: "View Details",
              onClick: (row: any) => {
                handleViewDetails(row.fileId);
              },
            },
          ]}
          currentPage={currentFilePage}
          totalPages={totalPages}
          onPageChange={handleFilePageChange}
        />

        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Fuel Combustion Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};

export default FuelCombustion;

const columns = [
  { key: "id", label: "Sr." },
  { key: "fuelType", label: "Fuel Type" },
  { key: "measuringValue", label: "Measuring Value & Unit" },
  { key: "dataCollectionMethod", label: "Data Collection" },
  { key: "startDate", label: "Upload Date" },
  { key: "emissionFactor", label: "Emission Factor" },
  { key: "location", label: "Location" },
  { key: "assetName", label: "Asset Name" },
  { key: "status", label: "Status" },
  { key: "calculatedEmission", label: "Calculated Emission" },
];

const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
