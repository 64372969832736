import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ChevronDown, LayoutGridIcon, ListIcon } from "../../../../../icons";

import { Card, CustomTable, CustomTextField } from "../../../../../ui";
import AddEditProcessEmission from "./add-edit-process-emission";
import { CustomGridCard } from "../../../../../ui/custom-grid-card/custom-grid-card";
import {
  getProcessEmissionByFileId,
  getProcessEmissionByProtocol,
} from "../../../../../api/process-api";
import ViewReasonProcessEmission from "./view-reason-process-emission";
import {
  getAssetsByLocationId,
  getLocattions,
  getUploadedFiles,
} from "../../../../../api/fuel-api";
import { Close as CloseIcon } from "@mui/icons-material";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProcessSchema } from "../../../../../validations/process-emission-schema";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { formattedDate } from "../../../../../utils/getStatusStyle";

type FormData = z.infer<typeof ProcessSchema>;

const protocols = [
  { value: "CSRD", label: "CSRD" },
  { value: "SECR", label: "SECR" },
];

const ProcessEmissions: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isGridView, setIsGridView] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [protocol, setProtocol] = useState<string>("CSRD");
  const [processEmissionId, setProcessEmissionId] = useState<any | null>(null);
  const [fileTableData, setFileTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [fileData, setFileData] = useState<any[]>([]);

  const itemsPerPage = 8;

  const methods = useForm<FormData>({
    resolver: zodResolver(ProcessSchema),
    mode: "onChange",
    defaultValues: {
      processTypeId: "",
      materialId: "",
      materialUnitId: "",
      emissionFactorId: null,
      quantityOfMaterial: null,
      processDescription: "",
      protocol: "",
      locationName: "",
      assetName: "",
      dataCollectionMethod: "",
      reason: "",
    },
  });

  const { watch } = methods;
  const locationName = watch("locationName")
    ? Number(watch("locationName"))
    : null;
  const assetId = watch("assetName") ? Number(watch("assetName")) : null;
  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: getLocattions,
  });

  const { data: assetsData } = useQuery({
    queryKey: ["assets", locationName],
    queryFn: () => getAssetsByLocationId(Number(locationName)),
    enabled: !!locationName,
  });
  const assets = Array.isArray(assetsData) ? assetsData : [];

  const { data: processEmissionResponse, refetch: refetchProcessEmission } =
    useQuery({
      queryKey: [
        "processEmission",
        currentPage,
        protocol,
        locationName,
        assetId,
      ],
      queryFn: () =>
        getProcessEmissionByProtocol({
          page: currentPage - 1,
          size: itemsPerPage,
          locationId: locationName || undefined,
          assetId: assetId || undefined,
          protocol: protocol ? protocol.toUpperCase() : "CSRD",
          sort: "createdDate,desc",
        }),
    });

  const processEmissionData = processEmissionResponse?.data || [];
  const totalPages = processEmissionResponse?.totalPages || 1;

  const processData = processEmissionData.map((item: any, i: number) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    startDate: formattedDate(item.startDate),
  }));

  const handleRowUpdate = (updatedRow: any) => {
    refetchProcessEmission();
  };

  const handleProtocolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProtocol(event.target.value);
    refetchProcessEmission();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleEditClick = (row: any) => {
    setProcessEmissionId(row.processEmissionId);
    navigate(`?action=edit`);
  };

  const handleViewReasonClick = (row: any) => {
    setProcessEmissionId(row.processEmissionId);
    navigate(`?action=viewReason`);
  };

  const fetchUploadedFiles = async (page: number) => {
    try {
      const response = await getUploadedFiles({
        search: "",
        typeOfEmission: "PROCESS",
        page: page - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileData(response.data);
      refetchProcessEmission();
    } catch (error) {
      console.error("Error fetching uploaded files process:", error);
    }
  };
  useEffect(() => {
    fetchUploadedFiles(currentPage);
  }, [currentPage]);

  const file = fileData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
    uploadDate: formattedDate(item.uploadDate),
  }));

  const fileTable = fileTableData.map((item, i) => ({
    ...item,
    id: (currentPage - 1) * itemsPerPage + i + 1,
  }));

  const handleViewDetails = async (fileId: number) => {
    try {
      const response = await getProcessEmissionByFileId({
        fileId,
        page: currentPage - 1,
        size: 10,
        sort: "createdDate,desc",
      });
      setFileTableData(response.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching process emission details:", error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false); // close modal
  };
  const fields = [
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      col: 3,
      icon: <ChevronDown />,
      onChange: handleProtocolChange,
      options: protocols,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 3,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 3,
      defaultValue: locationName ? "" : watch("assetName"),
    },
  ];

  return (
    <Card
      headerText="Process Emission List"
      headerBtnText="Add New"
      tableDataLength={processEmissionData?.length}
      currentPage={currentPage}
      uploadUrl="/process-emission/upload"
      fileType="PROCESS"
    >
      <AddEditProcessEmission
        processEmissionId={processEmissionId}
        locations={locations}
        locationName={locationName}
        assets={assets}
        methods={methods}
        handleRowUpdate={handleRowUpdate}
        refetchProcessEmission={refetchProcessEmission}
        currentPage={currentPage}
        fetchUploadedFile={fetchUploadedFiles}
      />
      <ViewReasonProcessEmission processEmissionId={processEmissionId} />
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          {fields.map((field, index) => (
            <Grid item xs={12} sm={field.col} key={index}>
              <TextField
                name={field.name}
                placeholder={field.placeholder}
                label={field.label}
                select={field.select}
                fullWidth
                onChange={field.onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ChevronDown />
                    </InputAdornment>
                  ),
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: any) => {
                    if (!value) {
                      return (
                        <span style={{ color: theme.palette.grey[200] }}>
                          {field.placeholder}
                        </span>
                      );
                    }
                    const selectedOption = field.options.find(
                      (option: any) => option.value === value
                    );
                    return selectedOption ? selectedOption.label : value;
                  },
                }}
              >
                {field.options.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
          <Stack direction="row" gap={1} marginLeft={"auto"}>
            <IconButton
              sx={{
                backgroundColor: isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(false)}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: !isGridView ? "none" : "##FCFCFD",
                borderRadius: "8px",
                padding: "10px",
              }}
              onClick={() => setIsGridView(true)}
            >
              <LayoutGridIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Box>
      {isGridView ? (
        <CustomGridCard
          columns={columns}
          row={processData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      ) : (
        <CustomTable
          columns={columns}
          data={processData}
          rowActions={[
            {
              text: "Edit Details",
              onClick: (row) => {
                handleEditClick(row);
              },
            },
            {
              text: "View Reason",
              onClick: (row) => {
                handleViewReasonClick(row);
              },
            },
          ]}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      <Box mt={4}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <Typography
            sx={{
              fontSize: "1.875rem",
              lineHeight: "1",
              fontFamily: "PoppinsSemibold",
              color: "#000",
            }}
          >
            Process Emission Files
          </Typography>

          <FormProvider {...methods}>
            <form>
              <CustomTextField
                name="search"
                margin="normal"
                type="text"
                placeholder="Search"
                icon="search"
                sx={{ margin: 0 }}
              />
            </form>
          </FormProvider>
        </Box>
        <CustomTable
          columns={columns2}
          data={file}
          rowActions={[
            {
              text: "View Details",
              onClick: (row: any) => {
                handleViewDetails(row.fileId);
              },
            },
          ]}
        />
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Process Emission Details</Typography>
              <IconButton onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <CustomTable columns={columns} data={fileTable} />
          </DialogContent>
        </Dialog>
      </Box>
    </Card>
  );
};
export default ProcessEmissions;

const columns = [
  { key: "id", label: "Sr." },
  { key: "processType", label: "Process Type" },
  { key: "processDescription", label: "Process Description" },
  { key: "quantityOfRawMaterial", label: "Value & Unit" },
  { key: "location", label: "Location" },
  { key: "assetName", label: "Asset Name" },
  { key: "dataCollectionMethod", label: "Data Collection" },
  { key: "startDate", label: "Upload Date" },
  { key: "processEmissionFactor", label: "Emission Factor" },
  { key: "status", label: "Status" },
  { key: "calculatedEmission", label: "Calculated Emission" },
];

const columns2 = [
  { key: "fileId", label: "File ID" },
  { key: "fileName", label: "File Name" },
  { key: "startDate", label: "Start Date" },
  { key: "endDate", label: "End Date" },
  { key: "uploadDate", label: "Upload Date" },
  { key: "totalEmissions", label: "Total Emissions" },
];
