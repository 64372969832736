import DashboardLayout from "../layouts/dashboard-layout/dashboard-layout";
import CreateProfilePage from "../pages/auth/create-profile";
import EmailVerificationPage from "../pages/auth/email-verification-page";
import ForgetPasswordPage from "../pages/auth/forget-password-page";
import LoginPage from "../pages/auth/login-page";
import ResetPasswordPage from "../pages/auth/reset-password-page";
import SignUpPage from "../pages/auth/singup-page";
import DashboardPage from "../pages/dashboard-page";
import EmissionSourcesPage from "../pages/emission-sources-page";
import ManagePage from "../pages/manage-page";
import ProfilePage from "../pages/profile-page";
import ReportingPage from "../pages/reporting-page";
import Scope1EmissionPage from "../pages/scope1-emission";
import Scope2EmissionPage from "../pages/scope2-emission";
import TeamManagementPage from "../pages/team-management-page";
import WorkspacePage from "../pages/workspace-page";
import PrivateRoute from "./private-route";
import ProtectedRoute from "./protected-route";
import PublicRoute from "./public-route";

// const Dashboard = React.lazy(() =>
//   import("../components/Client/Dashboard/ClientDashboard/Dashboard")
// );

/**
 * Array of routes for the auth section.
 * Each route object contains information about the path and corresponding component.
 */ 
export const routes = [
  // public
  //   { path: "unauthorized", element: <UnautherizedPage /> }
  // login
  {
    path: "login",
    element: <PublicRoute component={LoginPage} />,
  },
  {
    path: "/",
    element: <PublicRoute component={LoginPage} />,
  },
  {
    path: "signup",
    element: <PublicRoute component={SignUpPage} />,
  },
  {
    path: "email-verification",
    element: <PublicRoute component={EmailVerificationPage} />,
  },
  {
    path: "forgot-password",
    element: <PublicRoute component={ForgetPasswordPage} />,
  },
  {
    path: "password-reset",
    element: <PublicRoute component={ResetPasswordPage} />,
  },
  {
    path: "create-profile",
    element: <PublicRoute component={CreateProfilePage} />,
  },
  {
    path: "profile",
    element: <PrivateRoute component={ProfilePage} />,
  },
  // dashboard
  {
    path: "",
    element: <PrivateRoute  component={DashboardLayout} />,
    children: [
      {
        path: "/dashboard",
        element: <PrivateRoute component={DashboardPage} />,
      },
      
      {
        path: "measure/emission-sources",
        element: <PrivateRoute component={EmissionSourcesPage} />,
      },
      {
        path: "measure/scope1-emissions",
        element: <PrivateRoute  component={Scope1EmissionPage} />,
      },
      {
        path: "measure/scope2-emissions",
        element: <PrivateRoute component={Scope2EmissionPage} />,
      },
      {
        path: "manage",
        element: <PrivateRoute component={ManagePage} />,
      },
      {
        path: "team-management",
        element: <PrivateRoute component={TeamManagementPage} />,
      },
      {
        path: "workspace",
        element: <PrivateRoute component={WorkspacePage} />,
      },
      {
        path: "reporting",
        element: <PrivateRoute component={ReportingPage} />,
      },
    ],
  },
];
