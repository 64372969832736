import { axiosInstance } from "../utils/axios";

export interface CalculateLocationEmissionData {
  scopeOneLocationEmissionType: string;
  locationId: number;
  measuringValue: number;
}

export interface SaveLocationEmissionParams {
  locationId: number;
  data: {
    locationEmissionType: string;
    measuringValue: number;
  }[];
}

export interface GetLocationEmissionParams {
  locationEmissionType: string;
  protocol?: string;
  locationId: number;
}

export interface AddNoteData {
  emissionId: number;
  note: string;
}

export const getAllLocation = async () => {
  try {
    const response = await axiosInstance.get(`/location`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get All Location failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get All Location failed.";
  }
};

export const scope1CreateOrUpdateLocationPreferences = async (
  locationId: number,
  preferences: any
) => {
  try {
    const response = await axiosInstance.put(
      `/scope-one/location-preferences/${locationId}`,
      preferences
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Error updating location preferences",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Error updating location preferences."
    );
  }
};

export const getScope1LocationPreferences = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-one/location-preferences/${id}`
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Location Preferences By Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Location Preferences By Id failed."
    );
  }
};

export const calculateScope1LocationEmission = async (
  data: CalculateLocationEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      "/scope-one/calculate-location-emission",
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error calculating building emission:", error);
    throw error;
  }
};

export const saveScope1LocationEmission = async (
  data: SaveLocationEmissionParams
) => {
  try {
    const response = await axiosInstance.post(
      `/scope-one/location-emission`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const getScope1LocationEmission = async (locationId: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-one/location-emission?locationId=${locationId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching location emission data:", error);
    throw error;
  }
};

export const deleteScope1LocationEmission = async (emisssionId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-one/location-emission?emissionId=${emisssionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting building emission data:", error);
    throw error;
  }
};

export const addNoteToScope1LocationEmission = async (data: AddNoteData) => {
  try {
    const response = await axiosInstance.post(
      `/scope-one/location-emission/add-note`,
      data
    );
    console.log("response of add note in Scope 1 location", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding note to location emission:", error);
    throw error;
  }
};

export const getNoteForScope1LocationEmission = async (emissionId: number) => {
  try {
    const response = await axiosInstance.get(
      `scope-one/location-emission/${emissionId}/view-note`
    );
    console.log("response of view note in Scope 1 location", response.data);
    return response.data;
  } catch (error) {
    console.error("Error Getting note to location emission:", error);
    throw error;
  }
};

export const addAttachmentToScope1LocationEmission = async (
  emissionId: number,
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/scope-one/location-emission/${emissionId}/upload-attachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading attachment to building emission:", error);
    throw error;
  }
};

export const viewScope1LocationAttachment = async (emissionId: number) => {
  try {
    const response = await axiosInstance.post(
      `/scope-one/location-emission/${emissionId}/view-attachment`
    );
    console.log(
      "response of view attachment in Scope 1 location",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error viewing attachment from location emission:", error);
    throw error;
  }
};

export const deleteScope1LocationAttachment = async (attachmentId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-one/location-attachment?attachmentId=${attachmentId}`
    );
    console.log(
      "response of delete attachment in Scope 1 location",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting attachment from location emission:", error);
    throw error;
  }
};
