import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, Controller } from "react-hook-form";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ChevronDown } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import UploadCsvModal from "../../../../../ui/upload-csv-modal";
import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "../../../../../ui/custom-date-picker/custom-date-picker";

const schema = z.object({
  locationName: z.string().min(1, "Location is required").max(255),
  startDate: z.string().nullable(),
  endDate: z.string().nullable(),
});

type FormData = z.infer<typeof schema>;

interface ImportDataModalProps {
  isModalOpen: boolean;
  onClose: () => void;
}

const ImportBulkDataModal: React.FC<ImportDataModalProps> = ({
  isModalOpen,
  onClose,
}) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const theme = useTheme();
  const methods = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      locationName: "",
      startDate: null,
      endDate: null,
    },
  });

  const { control, handleSubmit } = methods;

  const onSubmit = (formData: FormData) => {
    console.log("formData", formData);
  };

  const handleBulkUploadClick = () => {
    setIsUploadModalOpen(true);
  };

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const fields = [
    {
      name: "location",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
      ],
      col: 6,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "option1", label: "Option 1" },
        { value: "option2", label: "Option 2" },
        { value: "option3", label: "Option 3" },
      ],
      col: 6,
    },
  ];

  const measurementItems = [
    {
      label: "Natural Gas",
      options: [
        {
          name: "Unit",
          label: "Select Unit",
          units: ["Cubic meters (m³)", "Kilowatt-hours (kWh)"],
          select: true,
          icon: <ChevronDown />,
        },
      ],
    },
    {
      label: "LPG (Liquefied Petroleum Gas)",
      options: [
        {
          name: "Unit",
          label: "Select Unit",
          units: ["Liters (L)", "Kilograms (kg)"],
          select: true,
          icon: <ChevronDown />,
        },
      ],
    },
    {
      label: "Diesel",
      options: [
        {
          name: "Unit",
          label: "Select Unit",
          units: ["Liters (L)", "Kilograms (kg)"],
          select: true,
          icon: <ChevronDown />,
        },
      ],
    },
  ];

  return (
    <CustomModal
      open={isModalOpen}
      onClose={onClose}
      title="Import Data"
      actions={[
        { text: "Cancel", onClick: onClose, bgColor: "transparent" },
        {
          text: "Bulk Upload",
          type: "submit",
          onClick: handleBulkUploadClick,
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Bulk Import Data" variant="h5" />
        <CustomSubHeading text="On the Bulk Import Data screen, select your fields, download the template, fill it in, and upload to quickly import your data!" />
      </Box>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {fields.map((field) => (
              <Grid item xs={12} sm={field.col} key={field.name} sx={{ pt: 0 }}>
                <CustomTextField {...field} />
              </Grid>
            ))}

            <Grid item xs={12} sm={6}>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <CustomDatePicker
                    control={control}
                    name="startDate"
                    label="Start Date"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue: Dayjs | null) => {
                      field.onChange(
                        newValue ? newValue.format("YYYY-MM-DD") : null
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <CustomDatePicker
                    control={control}
                    name="endDate"
                    label="End Date"
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue: Dayjs | null) => {
                      field.onChange(
                        newValue ? newValue.format("YYYY-MM-DD") : null
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>

      <Box mt={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CustomHeading text="Measurement & Units" variant="h5" />
          <CustomButton onClick={() => {}}>Download Template</CustomButton>
        </Box>
        <CustomSubHeading text="Set your preferred units and download the template." />
      </Box>
      {measurementItems.map((section) => (
        <Box key={section.label} mt={2}>
          <Typography
            sx={{
              color: theme.palette.grey[800],
              fontFamily: "PoppinsRegular",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {section.label}
          </Typography>

          {section.options.map((item) => (
            <Box key={item.name} mt={1} mb={1}>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Grid container spacing={2} mt={0}>
                    <Grid item xs={12}>
                      <CustomTextField
                        name={item.name}
                        label="Unit"
                        select={item.select}
                        options={item.units.map((unit) => ({
                          value: unit,
                          label: unit,
                        }))}
                        icon={item.icon}
                        placeholder="Select Unit"
                      />
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Box>
          ))}
        </Box>
      ))}

      {isUploadModalOpen && (
        <UploadCsvModal open={isUploadModalOpen} onClose={closeUploadModal} />
      )}
    </CustomModal>
  );
};

export default ImportBulkDataModal;
