import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  InputAdornment,
  Select,
  Typography,
} from "@mui/material";
import { FormProvider } from "react-hook-form";
import { z } from "zod";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import {
  getEmissionFactor,
  getFuelType,
  getMeasurementValue,
  getFuelCombustionById,
  updateFuelCombustion,
  CalculateFuelEmissionData,
  calculateFuelEmission,
} from "../../../../../api/fuel-api";
import { ChevronDown, InfoCircleICon } from "../../../../../icons";
import { useFuel } from "./useFuel";
import { showToast } from "../../../../../utils/showToast.util";
import { useQuery } from "@tanstack/react-query";
import { Fuelschema } from "../../../../../validations/fuel-validations-schema";

type FormData = z.infer<typeof Fuelschema>;

interface AddEditFuelCombustionProps {
  methods: any;
  locations: any;
  assets: any;
  combustionEmissionId: any;
  handleRowUpdate: any;
  refetchFuelCombustion: () => void;
  refetchUploadedFiles: () => void;
  currentPage: number;
  currentFilePage: number;
  locationName: any;
}

const AddEditFuelCombustion: React.FC<AddEditFuelCombustionProps> = ({
  methods,
  locations,
  assets,
  combustionEmissionId,
  handleRowUpdate,
  refetchFuelCombustion,
  refetchUploadedFiles,
  locationName,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [calculatedEmissions, setCalculatedEmission] = useState<any>("");
  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen =
    searchParams.get("action") === "add" ||
    searchParams.get("action") === "edit";

  const { watch, reset } = methods;
  const fuelType = watch("fuelType");
  const unit = watch("unit");
  const { mutate: createFuelCombustion } = useFuel({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message, "success");
      refetchFuelCombustion();
      refetchUploadedFiles();
      handleClose();
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const fetchCombustionById = async () => {
    try {
      const data = await getFuelCombustionById(combustionEmissionId);
      reset({
        fuelType: data.fuelType?.id.toString(),
        locationName: data.location.id.toString(),
        assetName: data.asset.id.toString(),
        measurementValue: data.measuringValue,
        unit: data.measuringUnit?.id.toString(),
        emissionFactor: data.emissionFactor?.factor,
        protocol: data.protocol,
        dataCollectionMethod: data.dataCollectionMethod,
        reason: data.reason,
      });
      setCalculatedEmission(data.calculatedEmission);
    } catch (error: any) {
      console.error(error, "error");
    }
  };

  useEffect(() => {
    if (isEditing && combustionEmissionId > 0) {
      fetchCombustionById();
    }
  }, [isEditing, combustionEmissionId, methods]);

  useEffect(() => {
    if (!isEditing && isModalOpen) {
      reset({
        fuelType: "",
        measurementValue: null,
        unit: "",
        emissionFactor: null,
        dataCollectionMethod: "",
        protocol: "",
        locationName: "",
        assetName: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isModalOpen, isEditing, reset]);

  const { data: fuelTypesData } = useQuery({
    queryKey: ["fuel"],
    queryFn: getFuelType,
  });
  const { data: measureData } = useQuery({
    queryKey: ["measure", fuelType],
    queryFn: () => getMeasurementValue(Number(fuelType)),
    enabled: !!fuelType,
  });
  const { data: emissionFactorData } = useQuery({
    queryKey: ["emissionFactor", fuelType, unit],
    queryFn: () =>
      getEmissionFactor({
        fuelTypeId: Number(fuelType),
        measureId: Number(unit),
      }),
    enabled: !!fuelType && !!unit,
  });

  useEffect(() => {
    if (measureData && measureData.length > 0) {
      methods.setValue("unit", measureData[0].id.toString());
      methods.setValue("measurementValue", null);
    }
  }, [measureData, methods]);

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateFuelEmissionData = {
        measuringValue: Number(data.measurementValue),
        emissionFactorId: emissionFactorData?.id,
        measuringUnitId: Number(data.unit),
      };
      const response = await calculateFuelEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
    } catch (error: any) {
      console.error("Calculate Emission failed", error);
      showToast(error, "error");
    }
  };

  const saveEmission = async (data: FormData) => {
    try {
      const payload = {
        locationId: data.locationName ? Number(data.locationName) : 0,
        assetId: data.assetName ? Number(data.assetName) : 0,
        fuelTypeId: data.fuelType ? Number(data.fuelType) : 0,
        measuringUnitId: data.unit ? Number(data.unit) : 0,
        emissionFactorId: emissionFactorData?.id ?? 0,
        measuringValue: data.measurementValue ?? 0,
        protocol: data.protocol?.toUpperCase() ?? "",
        dataCollectionMethod: data.dataCollectionMethod ?? "",
        reason: data.reason ?? "",
      };
      if (isEditing) {
        const response = await updateFuelCombustion(
          combustionEmissionId,
          payload
        );
        showToast(response.message, "success");
        handleRowUpdate?.({
          combustionEmissionId,
          ...data,
        });
      } else {
        await createFuelCombustion(payload);
      }
      refetchFuelCombustion();
      handleClose();
    } catch (error: any) {
      console.error("Save Emission Error:", error);
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };
  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const fields = [
    {
      name: "fuelType",
      label: "Type of Fuel",
      placeholder: "Select Fuel Type",
      select: true,
      icon: <ChevronDown />,
      options: fuelTypesData?.map((fuelType: any) => ({
        value: fuelType.id.toString(),
        label: fuelType.name,
      })),
      col: 12,
    },
    {
      name: "measurementValue",
      label: "Measurement Value with Unit",
      placeholder: "Enter value",
      col: 12,
      disabled: !fuelType,
      customEndAdornment: (
        <InputAdornment position="end">
          <Select
            value={
              watch("unit") ||
              (measureData && measureData.length > 0
                ? measureData[0].id.toString()
                : "")
            }
            onChange={(e) => methods.setValue("unit", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
              ".MuiSelect-select": {
                paddingRight: "0 !important",
              },
            }}
            IconComponent={() => (
              <ChevronDown style={{ width: 30, height: 30 }} />
            )}
            renderValue={(selected) => {
              const selectedMeasure = measureData?.find(
                (measure: any) => measure.id.toString() === selected
              );
              return selectedMeasure ? selectedMeasure.unitName : "";
            }}
          >
            {measureData?.map((measure: any) => (
              <MenuItem key={measure.id} value={measure.id.toString()}>
                {measure.unitName}
              </MenuItem>
            ))}
          </Select>
        </InputAdornment>
      ),
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "CSRD", label: "CSRD" },
        { value: "SECR", label: "SECR" },
      ],
      col: 12,
    },
    {
      name: "emissionFactor",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      value: emissionFactorData?.factor,
      type: "number",
      disabled: true,
      col: 6,
    },

    {
      name: "dataCollectionMethod",
      label: "Data Collection Method",
      placeholder: "Select Data Collection Method",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "METER READING", label: "Meter Reading" },
        { value: "DIRECT MEASUREMENT", label: "Direct Measurement" },
      ],
      col: 6,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 6,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 6,
      defaultValue: locationName ? "" : watch("assetName"),
    },
    ...extraFields,
  ];

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(saveEmission)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Fuel Combustion Emissions" variant="h5" />
        <CustomSubHeading text="Please provide the following details to calculate the fuel combustion emissions." />
      </Box>
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={3} mt={0}>
            {fields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            <InfoCircleICon />
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmissions !== null
              ? `${calculatedEmissions.calculatedEmission} ${calculatedEmissions.measuringUnit}`
              : ""}
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddEditFuelCombustion;
