import React, { FC, useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import CustomModal from "../../../../../ui/custom-modal/custom-modal";
import UploadCSVIcon from "../../../../../icons/UploadIcon";
import { CustomHeading } from "../../../../../ui";
import DeleteFileIcon from "../../../../../icons/DeleteFileIcon";
import FileAttachmentIcon from "../../../../../icons/FileAttachmentIcon";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteScope1LocationAttachment,
  viewScope1LocationAttachment,
} from "../../../../../api/scope1-location-api";

interface AddAttachmentModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (files: File[]) => void;
  emissionId: number | null;
  mode: "add" | "view";
  shouldRefetch: boolean;
  onRefetchComplete: () => void;
}

const AddViewAttachmentModal: FC<AddAttachmentModalProps> = ({
  open,
  onClose,
  onSave,
  emissionId,
  mode,
  shouldRefetch,
  onRefetchComplete,
}) => {
  const MAX_ATTACHMENTS = 3;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [attachments, setAttachments] = useState<any[]>([]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { data: attachmentsData, refetch } = useQuery({
    queryKey: ["attachments", emissionId],
    queryFn: () => viewScope1LocationAttachment(emissionId!),
    enabled: open,
  });

  useEffect(() => {
    if (attachmentsData) {
      setAttachments(attachmentsData?.data?.attachments);
    }
  }, [attachmentsData]);

  const deleteAttachmentMutation = useMutation({
    mutationFn: deleteScope1LocationAttachment,
    onSuccess: () => {
      refetch();
    },
    onError: (error: any) => {
      console.error(error.response.data.message);
    },
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (attachments?.length >= MAX_ATTACHMENTS) return;
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (attachments?.length >= MAX_ATTACHMENTS) return;
    const file = event.dataTransfer.files[0] || null;
    setSelectedFile(file);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      onSave([selectedFile]);
      setSelectedFile(null);
    }
    onClose();
  };

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachmentMutation.mutate(attachmentId);
  };
  useEffect(() => {
    if (shouldRefetch) {
      refetch().then(() => {
        onRefetchComplete();
      });
    }
  }, [shouldRefetch, refetch, onRefetchComplete]);

  return (
    <CustomModal
      title={mode === "add" ? "Add Attachment" : "Preview Attachments"}
      open={open}
      onClose={onClose}
      maxWidth="sm"
      actions={
        mode === "add"
          ? [
              { text: "Discard", onClick: onClose, bgColor: "transparent" },
              {
                text: "Save Attachment",
                onClick: handleSubmit,
                disabled: !selectedFile,
              },
            ]
          : [{ text: "Close", onClick: onClose, bgColor: "transparent" }]
      }
    >
      {mode === "add" && (
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "2px dashed #D0D5DD",
            borderRadius: "8px",
            padding: "20px",
            cursor: "pointer",
            textAlign: "center",
          }}
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
          onClick={() => fileInputRef.current?.click()}
        >
          <UploadCSVIcon />
          <Typography sx={{ fontWeight: 500, color: "#ffde59" }}>
            Upload Attachment
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#8E98A8" }}>
            or drag and drop here
          </Typography>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </Box>
      )}

      {mode === "add" && attachments?.length >= MAX_ATTACHMENTS && (
        <Typography
          sx={{ mt: 2, color: "red", textAlign: "left" }}
          variant="body2"
        >
          Attachment limit exceeded. Remove an attachment to add a new one.
        </Typography>
      )}
      <Box sx={{ mt: 3 }}>
        <CustomHeading
          text="Attachments"
          variant="h6"
          sx={{ fontSize: "18px" }}
        />
        <List sx={{ mt: 3 }}>
          {attachments?.map((attachment) => (
            <ListItem
              key={attachment.id}
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                mb: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <FileAttachmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={attachment?.fileName}
                secondary={attachment?.fileSize}
              />
              {mode !== "view" && (
                <IconButton
                  edge="end"
                  onClick={() =>
                    handleDeleteAttachment(attachment?.attachmentId)
                  }
                  disabled={deleteAttachmentMutation.isPending}
                >
                  <DeleteFileIcon />
                </IconButton>
              )}
            </ListItem>
          ))}
          {mode === "add" && selectedFile && (
            <ListItem
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                mb: 1,
                p: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <FileAttachmentIcon />
              </ListItemIcon>
              <ListItemText
                primary={selectedFile.name}
                secondary={(selectedFile.size / 1024).toFixed(2) + " KB"}
              />

              <IconButton
                edge="end"
                onClick={() => setSelectedFile(null)}
                disabled={true}
              >
                <DeleteFileIcon />
              </IconButton>
            </ListItem>
          )}
        </List>
      </Box>
    </CustomModal>
  );
};

export default AddViewAttachmentModal;
