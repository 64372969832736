import React, { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  styled,
  SwitchProps,
  TextField,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createOrUpdateLocationPreferences } from "../../api/scope2-location-api";
import { useLocationContext } from "../../context/location-context";
import CustomTextField from "../custom-textfield/custom-textfield";
import { showToast } from "../../utils/showToast.util";
import { useNavigate } from "react-router-dom";
import { IOSSwitch } from "../custom-switch/custom-switch";
import { AnyARecord } from "dns";


interface CustomAccordionConfigurationProps {
  locationId?: number | undefined | any;
  savePreferences: any;
  navigatUrl: string;
}

const CustomLocationAccordionConfiguration: FC<
  CustomAccordionConfigurationProps
> = ({ locationId, savePreferences, navigatUrl }) => {
  const navigate = useNavigate();

  const [expandedElectricity, setExpandedElectricity] =
    useState<boolean>(false);
  const [electricitySwitchChecked, setElectricitySwitchChecked] =
    useState<boolean>(false);
  const [contractType, setContractType] = useState<string>(""); // "WITH_CONTRACT" or "WITHOUT_CONTRACT"
  const [onsiteRenewablesChecked, setOnsiteRenewablesChecked] =
    useState<boolean>(false);
  const [onsiteFossilFuelChecked, setOnsiteFossilFuelChecked] =
    useState<boolean>(false);
  const [directLineMicrogridChecked, setDirectLineMicrogridChecked] =
    useState<boolean>(false);

  const [hasCustomFactorMicroGridChecked, setHasCustomFactorMicroGridChecked] =
    useState<boolean>(false);
  const [customFactorValueMicroGrid, setCustomFactorValueMicroGrid] =
    useState<any>("");
  const [gridElectricityChecked, setGridElectricityChecked] =
    useState<boolean>(false);
  const [hasCustomFactor, setHasCustomFactor] = useState<boolean>(false);
  const [customFactorValue, setCustomFactorValue] = useState<number | string>(
    ""
  );
  const [supplierName, setSupplierName] = useState<string>(""); // New state for supplier name
  const [energySupplyContractsChecked, setEnergySupplyContractsChecked] =
    useState<boolean>(false); // State for "Energy supply contracts"

  const { locationPreferences } = useLocationContext();

  // Save preferences function
  const handleSavePreferences = async () => {
    if (contractType === "WITH_CONTRACT" && !supplierName.trim()) {
      showToast("Supplier name is required.");
      return;
    }

    // Construct the preferences payload
    const preferences: any = {
      electricityPreference: {
        scopeTwoLocationContractualInstrumentOption:
          contractType === "WITH_CONTRACT"
            ? "WITH CONTRACTUAL INSTRUMENTS"
            : "WITHOUT CONTRACTUAL INSTRUMENTS",
        scopeTwoLocationWithoutContractualInstruments: {
          gridElectricity: gridElectricityChecked,
        },
        scopeTwoLocationWithContractualInstrumentsDto: {
          energySupplyContracts: energySupplyContractsChecked,
          supplierName: supplierName || "",
          doHaveCustomFactor: hasCustomFactor,
          customFactorValue: Number(customFactorValue) || 0,
        },
        scopeTwoLocationDirectLineMicrogridFossilFuel: {
          doHaveCustomFactor: directLineMicrogridChecked
            ? hasCustomFactor
            : false,
          customFactorValue:
            directLineMicrogridChecked && hasCustomFactor
              ? Number(customFactorValue)
              : 0.0,
        },
        onsiteRenewables: onsiteRenewablesChecked,
        onsiteFossilFuel: onsiteFossilFuelChecked,
      },
    };

    try {
      const response = await createOrUpdateLocationPreferences(
        locationId,
        preferences
      );
      showToast(response.message);
      navigate(navigatUrl);
    } catch (error: any) {
      console.error("Error saving preferences", error);
      showToast(error, "error")
    }
  };

  useEffect(() => {
    if (savePreferences) {
      handleSavePreferences();
    }
  }, [savePreferences]);

  const handleElectricitySwitchChange = () => {
    const newValue = !electricitySwitchChecked;
    setElectricitySwitchChecked(newValue);
    setExpandedElectricity(newValue);
  };

  return (
    <Box sx={{ p: 3 }}> 
      <Accordion
        expanded={expandedElectricity}
        onChange={() => setExpandedElectricity(!expandedElectricity)}
        disableGutters
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center" flex="1">
            <FormControlLabel
              control={
                <IOSSwitch
                  checked={electricitySwitchChecked}
                  onChange={handleElectricitySwitchChange}
                  inputProps={{ "aria-label": "Electricity switch" }}
                />
              }
              label="Electricity"
              sx={{ gap: 2 }}
            />
          </Box>
        </AccordionSummary>

        {electricitySwitchChecked && (
          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                marginLeft: "20px",
              }}
            >
              <RadioGroup
                value={contractType}
                onChange={(e) => setContractType(e.target.value)}
              >
                <FormControlLabel
                  value="WITHOUT_CONTRACT"
                  control={<Radio />}
                  label="Without contractual instruments"
                />
                {contractType === "WITHOUT_CONTRACT" && (
                  <Box sx={{ marginLeft: "60px", marginTop: "8px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={gridElectricityChecked}
                          onChange={() =>
                            setGridElectricityChecked(!gridElectricityChecked)
                          }
                          sx={{
                            color: "#8E98A8",
                            "&.Mui-checked": { color: "#E8CA51" },
                          }}
                          inputProps={{
                            "aria-label": "Grid Electricity checkbox",
                          }}
                        />
                      }
                      label="Grid electricity"
                    />
                  </Box>
                )}

                <FormControlLabel
                  value="WITH_CONTRACT"
                  control={<Radio />}
                  label="With contractual instruments"
                />
                {contractType === "WITH_CONTRACT" && (
                  <Box sx={{ marginLeft: "60px", marginTop: "8px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={energySupplyContractsChecked}
                          onChange={() =>
                            setEnergySupplyContractsChecked(
                              !energySupplyContractsChecked
                            )
                          }
                          sx={{
                            color: "#8E98A8",
                            "&.Mui-checked": { color: "#E8CA51" },
                          }}
                          inputProps={{
                            "aria-label": "Energy supply contracts checkbox",
                          }}
                        />
                      }
                      label="Energy supply contracts"
                    />
                    {energySupplyContractsChecked && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          marginLeft: "20px",
                          marginTop: "8px",
                        }}
                      >
                        <CustomTextField
                          name="supplierName"
                          label="Supplier Name"
                          variant="outlined"
                          size="small"
                          value={supplierName}
                          onChange={(e) => setSupplierName(e.target.value)}
                          sx={{ width: "250px" }}
                          required
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={hasCustomFactor}
                                onChange={() => setHasCustomFactor(true)}
                                inputProps={{
                                  "aria-label": "I do have custom factor",
                                }}
                              />
                            }
                            label="I do have custom factor"
                          />
                          <CustomTextField
                            label="Enter Custom Factor"
                            variant="outlined"
                            name="customFactor"
                            value={customFactorValue}
                            onChange={(e) =>
                              setCustomFactorValue(e.target.value)
                            }
                            disabled={!hasCustomFactor}
                            sx={{ width: "150px" }}
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={!hasCustomFactor}
                                onChange={() => setHasCustomFactor(false)}
                                inputProps={{
                                  "aria-label": "I do not have a custom factor",
                                }}
                              />
                            }
                            label="I do not have a custom factor"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </RadioGroup>

              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={directLineMicrogridChecked}
                      onChange={() =>
                        setDirectLineMicrogridChecked(
                          !directLineMicrogridChecked
                        )
                      }
                      sx={{
                        color: "#8E98A8",
                        "&.Mui-checked": { color: "#E8CA51" },
                      }}
                      inputProps={{
                        "aria-label":
                          "Direct line microgrid (fossil fuel) checkbox",
                      }}
                    />
                  }
                  label="Direct line microgrid (fossil fuel)"
                />

                {directLineMicrogridChecked && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                    >
                      <FormControlLabel
                        control={
                          <Radio
                            checked={hasCustomFactorMicroGridChecked}
                            onChange={() =>
                              setHasCustomFactorMicroGridChecked(true)
                            }
                            inputProps={{
                              "aria-label": "I do have custom factor microgrid",
                            }}
                          />
                        }
                        label="I do have custom factor microgrid"
                      />
                      <CustomTextField
                        name="customFactor"
                        label="Enter Custom Factor"
                        variant="outlined"
                        value={customFactorValueMicroGrid}
                        onChange={(e) =>
                          setCustomFactorValueMicroGrid(e.target.value)
                        }
                        disabled={!hasCustomFactor}
                        sx={{ width: "150px" }}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={!hasCustomFactor}
                            onChange={() =>
                              setHasCustomFactorMicroGridChecked(false)
                            }
                            inputProps={{
                              "aria-label": "I do not have a custom factor",
                            }}
                          />
                        }
                        label="I do not have a custom factor"
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              {/* Other Checkboxes */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onsiteRenewablesChecked}
                    onChange={() =>
                      setOnsiteRenewablesChecked(!onsiteRenewablesChecked)
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": { color: "#E8CA51" },
                    }}
                    inputProps={{ "aria-label": "On-site Renewables checkbox" }}
                  />
                }
                label="Onsite renewables"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={onsiteFossilFuelChecked}
                    onChange={() =>
                      setOnsiteFossilFuelChecked(!onsiteFossilFuelChecked)
                    }
                    sx={{
                      color: "#8E98A8",
                      "&.Mui-checked": { color: "#E8CA51" },
                    }}
                    inputProps={{ "aria-label": "Onsite Fossil Fuel checkbox" }}
                  />
                }
                label="Onsite fossil fuel"
              />
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default CustomLocationAccordionConfiguration;
