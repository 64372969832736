import React, { useEffect } from "react";
import { Card, CustomTable } from "../../../../ui";
import { RowData } from "../../../../ui/custom-table/custom-table";

import { useLocation } from "react-router-dom";
import CreateRole from "./create-role";
interface RolesProps {
  onModalStateChange: (isModalOpen: boolean) => void;
}

const Roles: React.FC<RolesProps> = ({ onModalStateChange }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isModalOpen = searchParams.get("action") === "create";

  const handleEditRole = (row: RowData) => {
    console.log("Edit role:", row);
  };

  const handleDeleteRole = (row: RowData) => {
    console.log("Delete role:", row);
  };

  useEffect(() => {
    onModalStateChange(isModalOpen);
  }, [isModalOpen, onModalStateChange]);

  const rowActions = [
    { text: "Edit Details", onClick: handleEditRole },
    { text: "Delete Role", onClick: handleDeleteRole },
  ];

  return (
    <>
      {isModalOpen ? (
        <CreateRole isModalOpen={isModalOpen} />
      ) : (
        <>
          <Card
            headerText="Roles List"
            headerBtnText="Create Role"
            tableDataLength={rows?.length}
          >
            <CustomTable
              columns={columns}
              data={rows}
              rowActions={rowActions}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default Roles;

const columns = [
  { key: "id", label: "Sr." },
  { key: "roleName", label: "Role Name" },
  { key: "description", label: "Role Description" },
  { key: "noOfUsers", label: "No Of Users" },
];

const rows = [
  {
    id: 1,
    roleName: "Manoj shahid",
    description: "This role is for the management of the system",
    noOfUsers: "11",
  },
  {
    id: 2,
    roleName: "Stephen",
    description: "This role description is left",
    noOfUsers: "48",
  },
  {
    id: 3,
    roleName: "Hifza",
    description: "This role is for the management of the system",
    noOfUsers: "9",
  },
  {
    id: 4,
    roleName: "Hassio",
    description: "This role description is left",
    noOfUsers: "4",
  },
  {
    id: 5,
    roleName: "Mohsin",
    description: "Islam",
    noOfUsers: "12",
  },
  {
    id: 6,
    roleName: "Rahanat",
    description: "This role description is left",
    noOfUsers: "1",
  },
  {
    id: 7,
    roleName: "Ali",
    description: "This role description is left",
    noOfUsers: "0",
  },
  {
    id: 8,
    roleName: "Anajib",
    description: "This role description is left",
    noOfUsers: "25",
  },
];
