import { StringLiteralLike } from "typescript";
import { axiosInstance } from "../utils/axios";

export interface CalculateBuildingEmissionData {
  buildingEmissionType: string;
  buildingId: number;
  measuringValue: number;
}

export interface SaveBuildingEmissionParams {
  buildingId: number;
  data: {
    buildingEmissionType: string;
    measuringValue: number;
    protocol?: string;
  }[];
}
export interface AddNoteData {
  emissionId: number;
  note: string;
}

export const getAllBuilding = async () => {
  try {
    const response = await axiosInstance.get(`/asset/building`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get All Building failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get All Building failed.";
  }
};

export const createOrUpdateScope1BuildingPreferences = async (
  buildingId: number,
  preferences: any
) => {
  try {
    const response = await axiosInstance.put(
      `/scope-one/building-preferences/${buildingId}`,
      preferences
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Error updating building preferences",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Error updating building preferences."
    );
  }
};

export const getScope1BuildingPreferences = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/scope-one/building-preferences/${id}`
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Building Preferences By Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Building Preferences By Id failed."
    );
  }
};

export const calculateScope1BuildingEmission = async (
  data: CalculateBuildingEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      "/scope-one/calculate-building-emission",
      data
    );

    return response.data;
  } catch (error) {
    console.error("Error calculating building emission:", error);
    throw error;
  }
};

export const saveScope1BuildingEmission = async (
  data: SaveBuildingEmissionParams
) => {
  try {
    const response = await axiosInstance.post(
      `/scope-one/building-emission`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const getScope1BuildingEmission = async (
  buildingId: number,
  page?: number
) => {
  try {
    const response = await axiosInstance.get(
      `/scope-one/building-emission?buildingId=${buildingId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching building emission data:", error);
    throw error;
  }
};

export const deleteScope1BuildingEmission = async (emisssionId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-one/building-emission?emissionId=${emisssionId}`
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting building emission data:", error);
    throw error;
  }
};

export const addNoteToScope1BuildingEmission = async (data: AddNoteData) => {
  try {
    const response = await axiosInstance.post(
      `scope-one/building-emission/add-note`,
      data
    );
    console.log("response of add note in Scope 1 building", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding note to building emission:", error);
    throw error;
  }
};

export const getNoteForScope1BuildingEmission = async (emissionId: number) => {
  try {
    const response = await axiosInstance.get(
      `scope-one/building-emission/${emissionId}/view-note`
    );
    console.log("response of view note in Scope 1 building", response.data);
    return response.data;
  } catch (error) {
    console.error("Error Getting note to building emission:", error);
    throw error;
  }
};

export const addAttachmentToScope1BuildingEmission = async (
  emissionId: number,
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/scope-one/building-emission/${emissionId}/upload-attachment`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(
      "response of upload attachment in Scope 1 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading attachment to building emission:", error);
    throw error;
  }
};
export const viewScope1BuildingAttachment = async (emissionId: number) => {
  try {
    const response = await axiosInstance.post(
      `/scope-one/building-emission/${emissionId}/view-attachment`
    );
    console.log(
      "response of view attachment in Scope 1 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error viewing attachment from building emission:", error);
    throw error;
  }
};

export const deleteScope1BuildingAttachment = async (attachmentId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/scope-one/building-attachment?attachmentId=${attachmentId}`
    );
    console.log(
      "response of delete attachment in Scope 1 building",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting attachment from building emission:", error);
    throw error;
  }
};

export const saveScope1BuildingBulkImport = async (
  buildingId: string,
  file: File
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    console.log("formDate", formData);

    const response = await axiosInstance.post(buildingId, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log("Response of building bulk import:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error saving bulk import for building:", error);
    throw error;
  }
};
