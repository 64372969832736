import { Box, Grid, Typography } from "@mui/material";
import { FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronDown, InfoCircleICon } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import { useEffect, useState } from "react";
import {
  calculateFugitiveEmission,
  CalculateFugitiveEmissionData,
  getEmissionPoint,
  getFugitiveEmisionById,
  getFugitiveEmissionFactor,
  getFugitiveSource,
  updateFugitiveEmission,
} from "../../../../../api/fugitive-emission-api";
import { showToast } from "../../../../../utils/showToast.util";
import { useFugitiveEmission } from "./useFugitiveEmission";
import { Fugitiveschema } from "../../../../../validations/fugitive-schema";
import { z } from "zod";

import { useQuery } from "@tanstack/react-query";
type FormData = z.infer<typeof Fugitiveschema>;

interface AddEditFugitiveEmissionProps {
  locations: any;
  assets: any;
  methods: any;
  fugitiveEmissionId: any;
  handleRowUpdate: any;
  refetchFugitiveEmission: () => void;
  refetchUploadedFiles: () => void;
  currentPage: number;
}

const AddEditFugitiveEmission: React.FC<AddEditFugitiveEmissionProps> = ({
  fugitiveEmissionId,
  refetchFugitiveEmission,
  handleRowUpdate,
  locations,
  assets,
  methods,
  currentPage,
  refetchUploadedFiles,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen = isEditing || searchParams.get("action") === "add";
  const [calculatedEmission, setCalculatedEmission] = useState<any | number>(
    null
  );
  const [emissionFactorId, setEmissionFactorId] = useState<any | number>(null);

  useEffect(() => {
    if (!isEditing) {
      reset({
        source: "",
        emissionFrequency: null,
        duration: null,
        emissionFactor: null,
        potentialEmissionPoint: "",
        dataCollectionMethod: "",
        protocol: "",
        locationName: "",
        assetName: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isEditing, isModalOpen]);

  const { watch, setValue, reset } = methods;
  const selectedFugitiveSourceId = watch("source");
  const selectedEmissionPointId = watch("potentialEmissionPoint");
  const locationId = watch("locationId");

  const { data: fugitiveSource } = useQuery({
    queryKey: ["fugitiveSource"],
    queryFn: getFugitiveSource,
  });
  const { data: emissionPoints } = useQuery({
    queryKey: ["emissionPoints", selectedFugitiveSourceId],
    queryFn: () => getEmissionPoint(Number(selectedFugitiveSourceId)),
    enabled: !!selectedFugitiveSourceId,
  });

  const { data: emissionFactorData } = useQuery({
    queryKey: [
      "emissionFactor",
      selectedFugitiveSourceId,
      selectedEmissionPointId,
    ],
    queryFn: () =>
      getFugitiveEmissionFactor({
        fugitiveEmissionSourceId: Number(selectedFugitiveSourceId),
        emissionPointId: Number(selectedEmissionPointId),
      }),
    enabled: !!selectedFugitiveSourceId && !!selectedEmissionPointId,
  });

  useEffect(() => {
    if (emissionFactorData) {
      setEmissionFactorId(emissionFactorData.fugitiveEmissionFactorId);
      setValue("emissionFactor", emissionFactorData.factor);
    }
  }, [emissionFactorData, setValue]);

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateFugitiveEmissionData = {
        frequency: Number(data.emissionFrequency),
        duration: Number(data.duration),
        fugitiveEmissionFactorId: Number(emissionFactorId),
        measuringValue: Number(data.emissionFactor),
      };
      const response = await calculateFugitiveEmission(payload);
      setCalculatedEmission(response.data);
    } catch (error: any) {
      console.error("Calculate Emission failed", error);
      showToast(error, "error");
    }
  };

  const fetchFugitiveEmissionById = async () => {
    try {
      const data = await getFugitiveEmisionById(fugitiveEmissionId);
      reset({
        source:
          data.fugitiveEmissionSource?.fugitiveEmissionSourceId.toString() ||
          "",
        potentialEmissionPoint:
          data.emissionPointDto.emissionPointId.toString() || "",
        emissionFactor:
          data.fugitiveEmissionFactor?.fugitiveEmissionFactorId.toString() ||
          "",
        emissionFrequency: data.frequencyOfEvent,
        duration: data.durationOfEvent,
        protocol: data.protocol || "",
        locationName: data.location.id.toString(),
        assetName: data.asset.id.toString(),
        dataCollectionMethod: data.fugitiveDataCollectionMethod || "",
        reason: data.reason || "",
      });

      setCalculatedEmission(data.calculatedEmission);
    } catch (error) {
      console.error("Fetch Fugitive Emission Failed!", error);
    }
  };

  useEffect(() => {
    if (fugitiveEmissionId > 0 && isEditing) {
      fetchFugitiveEmissionById();
    }
  }, [fugitiveEmissionId, isEditing]);

  const { mutate: createFugitive } = useFugitiveEmission({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message);
      refetchFugitiveEmission();
      refetchUploadedFiles();
      handleClose();
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      const payload = {
        fugitiveEmissionSourceId: Number(data.source),
        emissionPointId: Number(data.potentialEmissionPoint),
        locationId: data.locationName ? Number(data.locationName) : 0,
        assetId: data.assetName ? Number(data.assetName) : 0,
        fugitiveEmissionFactorId: emissionFactorId,
        frequency: data.emissionFrequency || 0,
        duration: data.duration || 0,
        protocol: data.protocol.toUpperCase() || "",
        fugitiveDataCollectionMethod:
          data.dataCollectionMethod.toUpperCase() || "",
        reason: data.reason ? data.reason : "",
      };
      if (isEditing) {
        const response = await updateFugitiveEmission(
          fugitiveEmissionId,
          payload
        );
        if (handleRowUpdate) {
          const updatedRow = {
            fugitiveEmissionId,
            fugitiveEmissionSourceId: Number(data.source),
            emissionPointId: Number(data.potentialEmissionPoint),
            fugitiveEmissionFactorId: Number(data.emissionFactor),
            frequency: data.emissionFrequency,
            duration: data.duration,
            protocol: data.protocol.toUpperCase() || "",
            fugitiveDataCollectionMethod: data.dataCollectionMethod || "",
            reason: data.reason ? data.reason : "",
          };
          showToast(response.message);
          refetchFugitiveEmission();
          handleRowUpdate(updatedRow);
        }
      } else {
        await createFugitive(payload);
      }
      handleClose();
    } catch (error: any) {
      console.error("Error:", error);
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  const fields = [
    {
      name: "source",
      label: "Source",
      placeholder: "Select Fugitive Emissions",
      select: true,
      icon: <ChevronDown />,
      options: fugitiveSource?.map((source: any) => ({
        value: source.fugitiveEmissionSourceId.toString(),
        label: source.name,
      })),
      col: 12,
      onChange: (e: any) => {
        setValue("potentialEmissionPoint", "");
      },
    },
    {
      name: "emissionFrequency",
      label: "Emission Frequency",
      placeholder: "Enter Emission Frequency",
      type: "number",
      col: 6,
    },
    {
      name: "duration",
      label: "Duration",
      placeholder: "Enter Duration",
      icon: "Min",
      type: "number",
      col: 6,
    },
    {
      name: "potentialEmissionPoint",
      label: "Potential Emission Point",
      placeholder: "Select Emission Point",
      select: true,
      icon: <ChevronDown />,
      options: emissionPoints?.map((point: any) => ({
        value: point.emissionPointId.toString(),
        label: point.emissionPointName,
      })),
      col: 6,
    },
    {
      name: "emissionFactor",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      icon: "Kwh",
      type: "number",
      disabled: true,
      col: 6,
    },
    {
      name: "dataCollectionMethod",
      label: "Data Collection",
      placeholder: "Select Collection Method",
      icon: <ChevronDown />,
      select: true,
      options: [
        {
          value: "REGULAR INSPECTION FOR LEAKS",
          label: "REGULAR INSPECTION FOR LEAKS",
        },
        {
          value: "MONITORING EQUIPMENT FOR VENTING EVENTS",
          label: "MONITORING EQUIPMENT FOR VENTING EVENTS",
        },
        { value: "RECORDING OBSERVATIONS", label: "RECORDING OBSERVATIONS" },
      ],
      col: 6,
    },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "CSRD", label: "CSRD" },
        { value: "SECR", label: "SECR" },
      ],
      col: 6,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 6,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 6,
      defaultValue: locationId ? "" : watch("assetName"),
    },
  ];

  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const finalFields = fields.concat(extraFields);

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Fugitive Emissions" variant="h5" />
        <CustomSubHeading text="Please Provide the Following details to Calculate the Fugitive Emissions" />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {finalFields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>

      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            <InfoCircleICon />
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmission !== null
              ? `${calculatedEmission.calculatedEmission} ${calculatedEmission.unitName}`
              : ""}
          </Typography>
        </Box>
      </Box>
      {/* )} */}
    </CustomModal>
  );
};

export default AddEditFugitiveEmission;
