import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { CustomButton } from "../../../../../ui";
import CustomBuildingAccordion from "../../../../../ui/custom-accordion-table/custom-building-accordion-table";
import {
  addAttachmentToScope2BuildingEmission,
  addNoteToScope2BuildingEmission,
  deleteScope2BuildingEmission,
  getBuildingPreferences,
  getNoteForScope2BuildingEmission,
  getScope2BuildingEmission,
  saveScope2BuildingEmission,
} from "../../../../../api/scope2-building-api";
import { useBuildingContext } from "../../../../../context/building-context";
import EmissionsAddedTable from "../emission-table/emissions-added-table";
import {  useNavigate } from "react-router-dom";
import { showToast } from "../../../../../utils/showToast.util";
import AddNoteModal from "./scope-two-building-add-view-note";
import CardEmission from "../../../../../ui/card-emission/card-emission";
import AddViewAttachmentModal from "./scope2-building-add-view-attachment";
import BulkImportData from "./scope2-building-bulk-import";

interface BuildingEmissionProps {
  setIsConfigurationTabsView?: any;
  buildingId?: any;
}

const BuildingEmissions: FC<BuildingEmissionProps> = ({
  setIsConfigurationTabsView,
  buildingId,
}) => {
  const { setBuildingPreferences } = useBuildingContext();
  const [buildingEmissionType, setBuildingEmissionType] = useState<any>("");
  const [emissionData, setEmissionData] = useState<any[]>([]);

  const [scope2BuildingEmission, setScope2BuildingEmission] = useState<any[]>(
    []
  );
  const [isNoteModalOpen, setNoteModalOpen] = useState<boolean>(false);
  const [selectedEmissionId, setSelectedEmissionId] = useState<number | null>(
    null
  );
  const [note, setNote] = useState<string>("");
  const [isViewingNote, setIsViewingNote] = useState<boolean>(false);
  const [time, setTime] = useState<string>("");
  const [isAttachmentModalOpen, setAttachmentModalOpen] =
    useState<boolean>(false);
  const [attachmentModalMode, setAttachmentModalMode] = useState<
    "add" | "view"
  >("add");
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const methods = useForm({
    defaultValues: {
      gridElectricityAmount: "",
      onSiteRenewablesAmount: "",
    },
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getBuildingPreferences(buildingId);
        setBuildingPreferences(response.data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
    fetchBuildingData();
  }, [buildingId, buildingEmissionType,setBuildingPreferences]);

  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const columns = [
    { key: "facilityName", label: "Facility Name", flexBasis: "40%" },
    { key: "measuringValue", label: "Amount", flexBasis: "15%" },
    { key: "emissions", label: "Emissions", flexBasis: "15%" },
    { key: "actions", label: "Actions", flexBasis: "15%" },
  ];

  const columns1 = [
    { key: "buildingEmissionType", label: "Facility Name" },
    { key: "measuringValue", label: "Amount" },
    { key: "createdDate", label: "Created At" },
    { key: "calculatedEmission", label: "Emissions" },
  ];

  const rows = [
    {
      facilityName: "Electricity",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "electricity",
    },
    {
      facilityName: "Purchased Steam, heat and cooling",
      amount: "0.00",
      emissions: "0000",
      actions: "",
      detailsType: "steamCooling",
    },
  ];

  const handleConfiguration = () => {
    setIsConfigurationTabsView({ show: true, active: undefined });
    navigate("/measure/scope2-emissions?emission=building&action=config");
  };

  const fetchBuildingData = async () => {
    try {
      const response = await getScope2BuildingEmission(buildingId);
      setScope2BuildingEmission(response.data);
    } catch (error: any) {
      console.error("Error fetching building emission data:", error);
      showToast(error, "error");
    }
  };

  const onSubmit = async () => {
    try {
      const emissionBuildingData = emissionData.map((value: any) => ({
        buildingEmissionType: value.buildingEmissionType,
        measuringValue: Number(value.measuringValue),
        protocol: "CSRD",
      }));

      const response = await saveScope2BuildingEmission({
        buildingId: Number(buildingId),
        data: emissionBuildingData,
      });

      setEmissionData(response.data);
      showToast(response.message);

      await fetchBuildingData();
    } catch (error: any) {
      console.error("Error saving building emission:", error);
      showToast(error, "error");
    }
  };

  const handleAddNew = () => {
    setExpandedRowIndex((prevIndex) => (prevIndex === 0 ? null : 0));
  };

  const handleEmissionTypeChange = (type: string) => {
    setBuildingEmissionType(type);
  };

  const handleEmissionsUpdate = (updatedEmissions: any) => {
    setEmissionData(updatedEmissions);
  };
  const handleDeleteEmission = async (emissionId: number) => {
    try {
      const response = await deleteScope2BuildingEmission(emissionId);
      setScope2BuildingEmission((prevData) =>
        prevData.filter((item) => item.buildingEmissionId !== emissionId)
      );
      showToast(response.message);
    } catch (error: any) {
      showToast(error, "error");
    }
  };

  const handleSaveNote = async () => {
    if (selectedEmissionId == null || !note.trim()) return;
    try {
      const response = await addNoteToScope2BuildingEmission({
        emissionId: selectedEmissionId,
        note: note,
      });
      showToast(response.message);
      setNoteModalOpen(false);
      setNote("");
    } catch (error: any) {
      showToast(error, "error");
    }
  };
  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event?.target?.value || "");
  };
  const handleAddNoteModalOpen = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setNoteModalOpen(true);
    setNote("");
    setIsViewingNote(false);
  };
  const handleViewNote = async (emissionId: number) => {
    try {
      const response = await getNoteForScope2BuildingEmission(emissionId);
      setNote(response.data.note);
      setTime(response.data.createdTime);
      setSelectedEmissionId(emissionId);
      setIsViewingNote(true);
      setNoteModalOpen(true);
    } catch (error: any) {
      console.error("Error fetching note:", error);
      showToast(error, "error");
    }
  };

  const handleAddAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("add");
    setAttachmentModalOpen(true);
  };
  const handleViewAttachment = (emissionId: number) => {
    setSelectedEmissionId(emissionId);
    setAttachmentModalMode("view");
    setAttachmentModalOpen(true);
  };
  const handleSaveAttachment = async (files: File[], emissionId: number) => {
    try {
      if (emissionId == null) return;

      for (const file of files) {
        const response = await addAttachmentToScope2BuildingEmission(
          emissionId,
          file
        );
        setShouldRefetch(true);
        showToast(response.message);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const handleBulkImport = () => {
    setIsModalOpen(true);
    navigate("?emission=building?action=bulk-import", { replace: true });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <CustomButton
            text="Configuration"
            bgColor="transparent"
            textColor="#344054"
            rounded="8px"
            variant="outlined"
            onClick={handleConfiguration}
            sx={{
              borderColor: "#D0D5DD",
              outlineColor: "#EAECF0",
              color: "#344054",
              "&:hover": {
                borderColor: "transparent",
                backgroundColor: "#FFF",
              },
              minWidth: "auto",
              boxShadow: "none",
            }}
          />

          <CardEmission
            headerText=""
            headerBtnText="Add New"
            handleAddNew={handleAddNew}
            btnActions={[
              { text: "Bulk Import Data", onClick: handleBulkImport },
            ]}
            setIsModalOpen={setIsModalOpen}
          />
        </Box>

        <>
          <Box sx={{ margin: "14px 18px" }}>
            <CustomBuildingAccordion
              columns={columns}
              rows={rows}
              expandedRowIndex={expandedRowIndex}
              onExpandRow={setExpandedRowIndex}
              onEmissionTypeChange={handleEmissionTypeChange}
              onEmissionsUpdate={handleEmissionsUpdate}
              buildingId={buildingId}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <CustomButton
              text="Cancel"
              bgColor="transparent"
              rounded="8px"
              variant="outlined"
              onClick={() => {}}
              sx={{
                borderColor: "#D0D5DD",
                outlineColor: "#EAECF0",
                margin: "8px 16px",
                marginRight: "2px",
                padding: "10px",
                color: "#344054",
                "&:hover": {
                  borderColor: "transparent",
                  backgroundColor: "#FFF",
                },
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="submit"
              text="Save"
              textColor="#000"
              iconPosition="end"
              rounded="8px"
              sx={{
                margin: "8px 16px",
                padding: "10px",
                backgroundColor: "#FFDE59",
                color: "#000",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              Save
            </CustomButton>
          </Box>
        </>
        <Box sx={{ marginBottom: "2px" }}>
          <EmissionsAddedTable
            columns={columns1}
            rows={scope2BuildingEmission}
            emissionType={buildingEmissionType}
            tableType={"Building"}
            rowActions={[
              {
                text: "Delete Emission",
                onClick: (row: any) => {
                  handleDeleteEmission(row.buildingEmissionId);
                },
              },
              {
                text: "Add Note",
                onClick: (row: any) =>
                  handleAddNoteModalOpen(row.buildingEmissionId),
              },
              {
                text: "View Note",
                onClick: (row: any) => handleViewNote(row.buildingEmissionId),
              },
              {
                text: "Add Attachment",
                onClick: (row: any) => {
                  handleAddAttachment(row.buildingEmissionId);
                },
              },
              {
                text: "View Attachment",
                onClick: (row: any) => {
                  handleViewAttachment(row.buildingEmissionId);
                },
              },
            ]}
          />
          <BulkImportData
            isModalOpen={isModalOpen}
            onClose={handleClose}
            buildingId={buildingId}
            fileType="SCOPE_TWO_BUILDING"
          />
          <AddNoteModal
            open={isNoteModalOpen}
            onClose={() => setNoteModalOpen(false)}
            onSave={handleSaveNote}
            onNoteChange={handleNoteChange}
            noteValue={note}
            isViewingNote={isViewingNote}
            time={time}
          />
          <AddViewAttachmentModal
            open={isAttachmentModalOpen}
            onClose={() => setAttachmentModalOpen(false)}
            onSave={(files: any) =>
              handleSaveAttachment(files, selectedEmissionId!)
            }
            emissionId={selectedEmissionId!}
            mode={attachmentModalMode}
            shouldRefetch={shouldRefetch}
            onRefetchComplete={() => setShouldRefetch(false)}
          />
        </Box>
      </form>
    </FormProvider>
  );
};

export default BuildingEmissions;
