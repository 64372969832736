import { axiosInstance } from "../utils/axios";

export interface CreateFugitiveData {
  fugitiveEmissionSourceId: number;
  emissionPointId: number;
  fugitiveEmissionFactorId: number;
  frequency: number;
  duration: number;
  protocol: string;
  fugitiveDataCollectionMethod: string;
}

export interface EmissionFactorData {
  fugitiveEmissionSourceId: number;
  emissionPointId?: number;
}

export interface GetFugitiveEmissionData {
  protocol?: string;
  locationId?: number;
  assetId?: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface GetFugitiveEmissionByFileIdData {
  fileId: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface GetFuelCombustionDataById {
  fugitiveEmissionSourceId: number;
}

export interface UpdateFugitiveData {
  fugitiveEmissionSourceId: number;
  emissionPointId: number;
  fugitiveEmissionFactorId: number;
  frequency: number;
  duration: number;
  protocol: string;
  fugitiveDataCollectionMethod: string;
}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}

export interface CalculateFugitiveEmissionData {
  measuringValue: number;
  duration: number;
  frequency: number;
  fugitiveEmissionFactorId: number;
}

export const createFugitive = async (data: CreateFugitiveData) => {
  try {
    const response = await axiosInstance.post(`/fugitive-emission`, data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Fuel Combustion failed.";
  }
};

export const calculateFugitiveEmission = async (
  data: CalculateFugitiveEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      `/fugitive-emission/calculate-emission`,
      data
    );
    console.log("response of calculate Emission Data", response.data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Fuel Combustion failed.";
  }
};

export const getFugitiveSource = async () => {
  try {
    const response = await axiosInstance.get("/fugitive-emission-source");
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Fugitive Source Emission failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Fugitive Source Emission failed."
    );
  }
};

export const getEmissionPoint = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/emission-point/${id}`);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Emission Point failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Emission Point failed.";
  }
};
export const getFugitiveEmissionFactor = async (data: EmissionFactorData) => {
  try {
    const response = await axiosInstance.get(
      `/fugitive-emission-factor/${data.fugitiveEmissionSourceId}/${data.emissionPointId}`
    );
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Emission Factor failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Emission Factor failed.";
  }
};

export const getFugitiveEmission = async (data: GetFugitiveEmissionData) => {
  try {
    const response = await axiosInstance.get(`/fugitive-emission`, {
      params: {
        protocol: data.protocol,
        page: data.page,
        size: data.size,
        sort: data.sort,
        locationId: data.locationId,
        assetId: data.assetId,
      },
    });

    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fugitive Emission By Protocol failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Fugitive Emission By Protocol failed."
    );
  }
};

export const getFugitiveEmisionById = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/fugitive-emission/${id}`);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Fugitive Emission By Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Fugitive Emission By Id failed."
    );
  }
};

export const getFugitiveEmissionByFileId = async (
  data: GetFugitiveEmissionByFileIdData
) => {
  try {
    const response = await axiosInstance.get(`/fugitive-emission`, {
      params: {
        fileId: data.fileId,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fugitive Emission By File Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Fugitive Emission By File Id failed."
    );
  }
};

export const updateFugitiveEmission = async (
  id: number,
  data: UpdateFugitiveData
) => {
  try {
    const response = await axiosInstance.put(`/fugitive-emission/${id}`, data);
    console.log("response the api of fugitive update", response);
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Fugitive Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Fugitive Emission failed.";
  }
};

export const getFugitiveEmissionReason = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/fugitive-emission/${id}/reason`);
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fugitive Emission Reason failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Fugitive Emission Reason failed."
    );
  }
};

export const uploadFugitiveEmissionFile = async (file: File) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      `/fugitive-emission/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("File upload response:", response);
    return response.data;
  } catch (error: any) {
    console.error("File upload failed", error.response?.data || error.message);
    throw error.response?.data?.message || "File upload failed.";
  }
};
