import { axiosInstance } from "../utils/axios";
import { API_ENDPOINTS } from "./apiEndPoints";

export interface CreateProcessData {
  processTypeId: number;
  materialId: number;
  materialUnitId: number;
  emissionFactorId: number;
  quantityOfMaterial: number;
  processDescription: string;
  protocol: string;
  dataCollectionMethod: string;
}

export interface EmissionFactorData {
  processId: number;
  materialId?: number;
  unitId?: number;
}

export interface GetProcessEmissionData {
  protocol?: string;
  locationId?: number;
  assetId?: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface GetProcessEmissionByFileIdData {
  fileId?: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface UpdateProcessEmissionData {
  processTypeId: number;
  materialId: number;
  materialUnitId: number;
  emissionFactorId: number;
  quantityOfMaterial: number;
  processDescription: string;
  protocol: string;
  dataCollectionMethod: string;
}

export interface CalculateProcessEmissionData {
  measuringValue: number;
  emissionFactorId: number;
  materialUnitId: number;
}

export const createProcessEmission = async (data: CreateProcessData) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.PROCESS_EMISSION,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Process Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Process Emission failed.";
  }
};

export const getProcessType = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.PROCESS_TYPE);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Process Type failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Process Type failed.";
  }
};

export const getMaterial = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.MATERIAL);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Material data failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Material Data failed.";
  }
};

export const getProcessEmissionFactor = async (data: EmissionFactorData) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.PROCESS_EMISSION_FACTOR(
        data.processId,
        data.materialId,
        data.unitId
      )
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Process Emission Factor failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Process Emission Factor failed."
    );
  }
};

export const getProcessEmissionByProtocol = async (
  data: GetProcessEmissionData
) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.PROCESS_EMISSION, {
      params: {
        protocol: data.protocol,
        page: data.page,
        size: data.size,
        sort: data.sort,
        locationId: data.locationId,
        assetId: data.assetId,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Process Emission By Protocol failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Process Emission By Protocol failed."
    );
  }
};

export const getProcessEmissionByFileId = async (
  data: GetProcessEmissionByFileIdData
) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.PROCESS_EMISSION, {
      params: {
        fileId: data.fileId,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Process Emission By File Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Process Emission By File Id failed."
    );
  }
};

export const calculateProcessEmission = async (
  data: CalculateProcessEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.PROCESS_EMISSION_CALCULATE,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Calculate Process Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Calculate Process Emission failed.";
  }
};

export const getProcessEmissionById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.PROCESS_EMISSION_BY_ID(id)
    );
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Process Emission By Id failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Process Emission By Id failed.";
  }
};

export const updateProcessEmission = async (
  id: number,
  data: UpdateProcessEmissionData
) => {
  try {
    const response = await axiosInstance.put(
      API_ENDPOINTS.PROCESS_EMISSION_BY_ID(id),
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Process Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Process Emission failed.";
  }
};

export const getProcessEmissionReason = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.PROCESS_EMISSION_REASON(id)
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Reason Process Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Reason Process Emission failed.";
  }
};
