import * as React from "react";

function FileAttachmentIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.186 3.25391V6.84991C14.186 7.33691 14.38 7.80491 14.726 8.14991C15.0729 8.49528 15.5425 8.68909 16.032 8.68891H20.157"
        stroke="#090909"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.647 8.14801H10.912M7.647 12.501H16.353M7.647 16.854H16.353M20.25 9.06901V17.637C20.2301 18.1974 20.0996 18.7484 19.8658 19.2581C19.6321 19.7679 19.2997 20.2263 18.888 20.607C18.4759 20.9895 17.9925 21.287 17.4654 21.4826C16.9383 21.6782 16.3778 21.7681 15.816 21.747H8.226C7.66074 21.7728 7.09594 21.6868 6.56398 21.494C6.03201 21.3011 5.54335 21.0051 5.126 20.623C4.71027 20.2413 4.37454 19.7807 4.13833 19.2681C3.90211 18.7555 3.77011 18.2011 3.75 17.637V7.36301C3.76985 6.80258 3.90042 6.25162 4.13418 5.74189C4.36794 5.23215 4.70026 4.77372 5.112 4.39301C5.52408 4.01053 6.00748 3.71299 6.53458 3.51738C7.06169 3.32178 7.62217 3.23194 8.184 3.25301H13.898C14.7703 3.24992 15.6123 3.57267 16.259 4.15801L19.219 6.88001C19.5349 7.15208 19.7899 7.48761 19.9675 7.86475C20.1452 8.2419 20.2414 8.65221 20.25 9.06901Z"
        stroke="#090909"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default FileAttachmentIcon;
