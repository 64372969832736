import { axiosInstance } from "../utils/axios";

export interface ProfileUpdateData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryId: number;
  cityId: number;
  companyName: string;
  companySize: string;
  industrySector: string;
  headquartersLocation: string;
  companyAddress: string;
  profileImage?: File;
}

export interface SetupProfileData extends ProfileUpdateData {}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}

export const viewProfile = async () => {
  try {
    const response = await axiosInstance.post("/view-profile");
    console.log("view profile response from the api", response.data);
    return response.data;
  } catch (error: any) {
    console.error("View Profile failed", error.response?.data || error.message);
    throw error.response?.data?.message || "View Profile failed.";
  }
};

export const updateProfile = async (data: FormData) => {
  try {
    const response = await axiosInstance.put("/update-profile", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    console.log("response oupdate profile", response.data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Profile failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Profile failed.";
  }
};

export const setupProfile = async (data: FormData) => {
  try {
    const response = await axiosInstance.post("/set-up-profile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Setup Profile failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Setup Profile failed.";
  }
};

export const getCountries = async () => {
  try {
    const response = await axiosInstance.get("/countries");
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Countries failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Countries failed.";
  }
};

export const getCities = async (countryId: number) => {
  try {
    const response = await axiosInstance.get(`/cities/${countryId}`);
    return response.data;
  } catch (error: any) {
    console.error("Get Cities failed", error.response?.data || error.message);
    throw error.response?.data?.message || "Get Cities failed.";
  }
};
export const getIndustries = async () => {
  try {
    const response = await axiosInstance.get("/all-industries");
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Countries failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Countries failed.";
  }
};
