import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import { ChevronDown, InfoCircleICon } from "../../../../../icons";
import {
  CustomButton,
  CustomHeading,
  CustomModal,
  CustomSubHeading,
  CustomTextField,
} from "../../../../../ui";
import React, { useEffect, useState } from "react";
import {
  calculateProcessEmission,
  CalculateProcessEmissionData,
  getMaterial,
  getProcessEmissionById,
  getProcessEmissionFactor,
  getProcessType,
  updateProcessEmission,
} from "../../../../../api/process-api";
import { showToast } from "../../../../../utils/showToast.util";
import { useProcessEmission } from "./useProcessEmission";
import { ProcessSchema } from "../../../../../validations/process-emission-schema";

type FormData = z.infer<typeof ProcessSchema>;

interface AddEditProcessEmissionProps {
  locations: any;
  locationName: any;
  assets: any;
  methods: any;
  processEmissionId: any;
  handleRowUpdate: any;
  refetchProcessEmission: any;
  currentPage: number;
  fetchUploadedFile: any;
}
const AddEditProcessEmission: React.FC<AddEditProcessEmissionProps> = ({
  locations,
  assets,
  locationName,
  methods,
  processEmissionId,
  handleRowUpdate,
  refetchProcessEmission,
  currentPage,
  fetchUploadedFile,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const isEditing = searchParams.get("action") === "edit";
  const modalTitle = isEditing ? "Edit Emission" : "Add Data Manually";
  const isModalOpen = isEditing || searchParams.get("action") === "add";

  const [processTypes, setProcessTypes] = useState<any[]>([]);
  const [material, setMaterial] = useState<any[]>([]);
  const [calculatedEmission, setCalculatedEmission] = useState<number | null>(
    0
  );
  const [unitName, setUnitName] = useState<string>("");
  const [filteredUnits, setFilteredUnits] = useState<any[]>([]);

  const { watch, setValue, reset } = methods;

  useEffect(() => {
    if (!isEditing) {
      reset({
        processTypeId: "",
        materialId: "",
        materialUnitId: "",
        emissionFactorId: null,
        quantityOfMaterial: null,
        processDescription: "",
        protocol: "",
        dataCollectionMethod: "",
        locationName: "",
        assetName: "",
        reason: "",
      });
      setCalculatedEmission(null);
    }
  }, [isModalOpen, isEditing]);

  const processType = watch("processTypeId");
  const selectedMaterialId = watch("materialId");
  const unitId = watch("materialUnitId");

  useEffect(() => {
    const fetchProcessTypes = async () => {
      try {
        const data = await getProcessType();
        setProcessTypes(data);
      } catch (error) {
        console.error("Fetch Process Type Failed!", error);
      }
    };
    if (!processTypes.length) {
      fetchProcessTypes();
    }
  }, [processTypes]);

  useEffect(() => {
    const fetchMaterial = async () => {
      try {
        const data = await getMaterial();
        setMaterial(data);
      } catch (error) {
        console.error("Fetch Material Failed!", error);
      }
    };
    if (!material.length) {
      fetchMaterial();
    }
  }, [material]);

  useEffect(() => {
    if (selectedMaterialId) {
      const selectedMaterial = material.find(
        (mat) => mat.materialId.toString() === selectedMaterialId
      );
      if (selectedMaterial) {
        setFilteredUnits(selectedMaterial.unit);
      }
    }
  }, [selectedMaterialId, material, setValue]);

  useEffect(() => {
    const fetchEmissionFactor = async () => {
      if (processType && selectedMaterialId && unitId) {
        try {
          const response = await getProcessEmissionFactor({
            processId: parseInt(processType),
            materialId: parseInt(selectedMaterialId),
            unitId: parseInt(unitId),
          });
          setValue("emissionFactorId", response.data.factor);
        } catch (error) {
          console.error(error, "error");
        }
      }
    };
    fetchEmissionFactor();
  }, [processType, selectedMaterialId, unitId, setValue]);

  const { mutate: createProcessEmission } = useProcessEmission({
    onSuccess: (data) => {
      const { message } = data;
      showToast(message);
      refetchProcessEmission();
      fetchUploadedFile(currentPage, 8);
      handleClose();
    },
    onError: (error: any) => {
      showToast(error, "error");
    },
  });

  const calculateEmission = async (data: FormData) => {
    try {
      const payload: CalculateProcessEmissionData = {
        measuringValue: Number(data.quantityOfMaterial),
        emissionFactorId: Number(data.processTypeId),
        materialUnitId: Number(data.materialUnitId),
      };

      const response = await calculateProcessEmission(payload);
      setCalculatedEmission(response.data.calculatedEmission);
      setUnitName(response.data.unitName);
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  const fetchProcessEmissionById = async () => {
    try {
      const data = await getProcessEmissionById(processEmissionId);
      reset({
        processTypeId: data.processTypeDto?.processId.toString() || "",
        materialId: data.material.id.toString() || "",
        materialUnitId: data.unit?.id.toString() || "",
        locationName: data.location.id.toString(),
        assetName: data.asset.id.toString(),
        quantityOfMaterial: data.quantityOfRawMaterial || null,
        emissionFactorId: data.processEmissionFactorDto?.factor || null,
        dataCollectionMethod: data.dataCollectionMethod || "",
        protocol: data.protocol || "",
        processDescription: data.processDescription || "",
        reason: data.reason || "",
      });
      setCalculatedEmission(data?.data.calculatedProcessEmission || null);
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  useEffect(() => {
    if (processEmissionId > 0 && isEditing) {
      fetchProcessEmissionById();
    }
  }, [processEmissionId, isEditing]);

  const onSubmit = async (data: FormData) => {
    if (data.quantityOfMaterial === null || data.emissionFactorId === null) {
      console.error("Measurement value or emission factor is missing");
      return;
    }
    const payload = {
      ...data,
      processTypeId: Number(data.processTypeId),
      locationId: data.locationName ? Number(data.locationName) : 0,
      assetId: data.assetName ? Number(data.assetName) : 0,
      materialId: Number(data.materialId),
      materialUnitId: Number(data.materialUnitId),
      emissionFactorId: Number(data.processTypeId),
      quantityOfMaterial: data.quantityOfMaterial,
      protocol: data.protocol.toUpperCase(),
      dataCollectionMethod: data.dataCollectionMethod,
      processDescription: data.processDescription || "",
    };

    try {
      if (isEditing) {
        const response = await updateProcessEmission(
          processEmissionId,
          payload
        );

        showToast(response.message);
        handleRowUpdate?.({
          processEmissionId,
          ...data,
        });
      } else {
        await createProcessEmission(payload);
      }
      refetchProcessEmission();
      handleClose();
    } catch (error: any) {
      console.error(error, "error");
      showToast(error, "error");
    }
  };

  const handleClose = () => {
    methods.reset();
    navigate(location.pathname, { replace: true });
  };

  useEffect(() => {
    if (filteredUnits && filteredUnits.length > 0) {
      methods.setValue("materialUnitId", filteredUnits[0].id.toString());
      methods.setValue("quantityOfMaterial", null);
    }
  }, [filteredUnits, methods]);

  const fields = [
    {
      name: "processTypeId",
      label: "Type of Process",
      placeholder: "Select Process Type",
      select: true,
      icon: <ChevronDown />,
      options: processTypes.map((processType) => ({
        value: processType.processId.toString(),
        label: processType.processName,
      })),
      col: 12,
    },
    {
      name: "materialId",
      label: "Material Value",
      placeholder: "Select material",
      select: true,
      icon: <ChevronDown />,
      options: material.map((materialValue) => ({
        value: materialValue.materialId.toString(),
        label: materialValue.materialName,
      })),
      col: 12,
    },
    {
      name: "quantityOfMaterial",
      label: "Measurement Value With Unit",
      placeholder: "Enter measurement value",
      col: 12,
      disabled: !selectedMaterialId,
      customEndAdornment: (
        <InputAdornment position="end">
          <Select
            value={
              watch("materialUnitId") ||
              (filteredUnits && filteredUnits.length > 0
                ? filteredUnits[0].id.toString()
                : "")
            }
            onChange={(e) => methods.setValue("materialUnitId", e.target.value)}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
              "&:hover .MuiOutlinedInput-notchedOutline": { border: "none" },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
              ".MuiSelect-select": {
                paddingRight: "0 !important",
              },
            }}
            IconComponent={() => (
              <ChevronDown style={{ width: 30, height: 30 }} />
            )}
            renderValue={(selected) => {
              const selectedMeasure = filteredUnits?.find(
                (measure: any) => measure.id.toString() === selected
              );
              return selectedMeasure ? selectedMeasure.unitName : "";
            }}
          >
            {filteredUnits?.map((filter: any) => (
              <MenuItem key={filter.id} value={filter.id.toString()}>
                {filter.unitName}
              </MenuItem>
            ))}
          </Select>
        </InputAdornment>
      ),
    },
    // {
    //   name: "materialUnitId",
    //   label: "Unit",
    //   placeholder: "Select material unit",
    //   select: true,
    //   icon: <ChevronDown />,
    //   options: filteredUnits.map((unit) => ({
    //     value: unit.id.toString(),
    //     label: unit.unitName,
    //   })),
    //   col: 12,
    // },
    {
      name: "protocol",
      label: "Protocol",
      placeholder: "Select Protocol",
      select: true,
      icon: <ChevronDown />,
      options: [
        { value: "CSRD", label: "CSRD" },
        { value: "SECR", label: "SECR" },
      ],
      col: 12,
    },
    {
      name: "emissionFactorId",
      label: "Emission Factor",
      placeholder: "Enter Emission Factor",
      type: "number",
      disabled: true,
      col: 6,
    },
    {
      name: "dataCollectionMethod",
      label: "Data Collection Method",
      placeholder: "Select Data Collection Method",
      icon: <ChevronDown />,
      select: true,
      options: [
        { value: "METER READING", label: "Meter Reading" },
        { value: "DIRECT MEASUREMENT", label: "Direct Measurement" },
      ],
      col: 6,
    },
    {
      name: "locationName",
      label: "Location",
      placeholder: "Select Location",
      select: true,
      icon: <ChevronDown />,
      options: locations
        ? locations.map((loc: any) => ({
            value: loc.locationId.toString(),
            label: loc.locationName,
          }))
        : [],
      col: 6,
    },
    {
      name: "assetName",
      label: "Asset Name",
      placeholder: "Select Asset",
      select: true,
      icon: <ChevronDown />,
      options: assets?.map((asset: any) => ({
        value: asset.id.toString() || "",
        label: asset.assetName,
      })),
      col: 6,
      defaultValue: locationName ? "" : watch("assetName"),
    },
    {
      name: "processDescription",
      label: "Process Description",
      placeholder: "Meter Reading is used in this proces",
      type: "textarea",
      col: 12,
    },
  ];

  const extraFields = isEditing
    ? [
        {
          name: "reason",
          label: "Reason To edit",
          placeholder: "Enter The Reason why you want to edit Emission",
          type: "textarea",
          col: 12,
        },
      ]
    : [];

  const finalFields = fields.concat(extraFields);

  return (
    <CustomModal
      title={modalTitle}
      open={isModalOpen}
      onClose={handleClose}
      maxWidth="sm"
      actions={[
        { text: "Discard", onClick: handleClose, bgColor: "transparent" },
        {
          text: isEditing ? "Update Emission" : "Save Emission",
          type: "submit",
          onClick: () => {
            methods.handleSubmit(onSubmit)();
          },
        },
      ]}
    >
      <Box mt={3}>
        <CustomHeading text="Process Emissions" variant="h5" />
        <CustomSubHeading text="Please Provide the Following details to Calculate the Process Emissions" />
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid container spacing={3} mt={0}>
            {finalFields.map((field, index) => (
              <Grid item xs={12} sm={field.col} key={index}>
                <CustomTextField {...field} />
              </Grid>
            ))}
          </Grid>
        </form>
      </FormProvider>
      <Box mt={3}>
        <CustomButton
          fullWidth
          text="Calculate Emission"
          onClick={methods.handleSubmit(calculateEmission)}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
            mb: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#090909",
              fontFamily: "PoppinsMedium",
              display: "flex",
              alignItems: "center",
              gap: 1,
              lineHeight: 1,
            }}
          >
            <InfoCircleICon />
            Emission Calculated
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#090909",
              fontSize: 18,
              fontWeight: 600,
              lineHeight: 1,
            }}
          >
            {calculatedEmission !== null
              ? `${calculatedEmission} ${unitName}`
              : ""}
          </Typography>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default AddEditProcessEmission;
