import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { FC, useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadCSVIcon from "../icons/UploadIcon";

import { showToast } from "../utils/showToast.util";
import { sampleDownloadTemplate, uploadCsvFile } from "../api/fuel-api";
import CustomButton from "./custom-button/custom-button";
import { saveScope1BuildingBulkImport } from "../api/scope1-building-api";

interface UploadCsvModalProps {
  open: boolean;
  onClose: any;
  onUploadComplete?: any;
  fetchUploadedFiles?: any;
  currentPage?: any;
  uploadUrl?: any;
  fileType?: string;
  id?: any;
}

const UploadCsvModal: FC<UploadCsvModalProps> = ({
  open,
  onClose,
  onUploadComplete,
  fetchUploadedFiles,
  currentPage,
  uploadUrl,
  fileType,
  id,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
      setIsDragOver(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, [open]);

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (file: File | null) => {
    if (file && file.type === "text/csv") {
      setIsLoading(true);
      try {
        let response;
        if (id) {
          response = await saveScope1BuildingBulkImport(id, file);
        } else if (uploadUrl) {
          response = await uploadCsvFile(file, uploadUrl);
        }
        setIsLoading(false);
        onClose();
        onUploadComplete?.(response);
        showToast(response.message);
        fetchUploadedFiles?.(currentPage);
      } catch (error: any) {
        setIsLoading(false);
        console.error("Mutation Error:", error.response.data.message);
        showToast(error.response.data.message, "error");
      }
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  // const handleFileChange = async (file: File | null) => {
  //   if (file && file.type === "text/csv") {
  //     setIsLoading(true);
  //     try {
  //       const response = await uploadCsvFile(file, uploadUrl);
  //       setIsLoading(false);
  //       onUploadComplete(response);
  //       showToast(response.message);
  //       fetchUploadedFiles(currentPage);
  //     } catch (error) {
  //       setIsLoading(false);
  //       console.error(error);
  //     }
  //   } else {
  //     alert("Please upload a valid CSV file.");
  //   }
  // };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);

    const file = event.dataTransfer.files && event.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    handleFileChange(file);
  };

  const handleDownloadTemplate = async () => {
    try {
      const fileData = await sampleDownloadTemplate(fileType || "");
      if (fileData.data.url) {
        window.open(fileData.data.url, "_blank");
      }
      onClose();
    } catch (error: any) {
      console.error("Download failed", error);
      showToast(error, "error");
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            padding: "24px",
            display: "flex",
            textAlign: "center",
            flexDirection: "column",
            borderRadius: "10px",
            // alignItems: "flex-start",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              color: "#090909",
              fontFamily: "PoppinsRegular",
              fontSize: "18px",
              fontWeight: 600,
            }}
          >
            Upload CSV File
          </DialogTitle>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "#090909" }} />
          </IconButton>
        </Box>

        <DialogContent>
          <Box
            onClick={handleFileUploadClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: isDragOver ? "2px dashed #ffde59" : "2px dashed #ECECEC",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              backgroundColor: isDragOver ? "#FFF8E1" : "#FAFAFA",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#F5F5F5",
              },
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <>
                <UploadCSVIcon />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#E8CA51",
                    // marginTop: "10px",
                    textDecoration: "underline",
                  }}
                >
                  Select A CSV File to upload
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#8E98A8",
                  }}
                >
                  or drag and Drop it here
                </Typography>

                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleInputChange}
                />
              </>
            )}
          </Box>

          {id === null && (
            <Box sx={{ marginTop: "20px" }}>
              <CustomButton
                sx={{
                  backgroundColor: "#fff",
                  fontWeight: 600,
                  width: "100%",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#555555",
                  },
                }}
                onClick={handleDownloadTemplate}
              >
                Download Template
              </CustomButton>
            </Box>
          )}
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default UploadCsvModal;
