import { axiosInstance } from "../utils/axios";
import { API_ENDPOINTS } from "./apiEndPoints";

export interface CreateFuelData {
  locationId: number;
  assetId: number;
  fuelTypeId: number;
  measuringUnitId: number;
  emissionFactorId: number;
  measuringValue: number;
  protocol: string;
  dataCollectionMethod: string;
}

export interface MeasurementData {
  fuelTypeId: number;
}

export interface EmissionFactorData {
  fuelTypeId: number;
  measureId?: number;
}

export interface GetFuelCombustionData {
  protocol?: string;
  locationId?: number;
  assetId?: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface getFuelCombustionByFileIdData {
  fileId: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface UpdateFuelCombustionData {
  fuelTypeId: number;
  measuringUnitId: number;
  emissionFactorId: number;
  measuringValue: number;
  protocol: string;
  dataCollectionMethod: string;
  reason: string;
}

export interface GetUploadedFileData {
  search?: string;
  typeOfEmission: string;
  page?: number;
  size?: number;
  sort?: string;
}

export interface CalculateFuelEmissionData {
  measuringValue: number;
  emissionFactorId: number;
  measuringUnitId: number;
}

export const createFuelCombustion = async (data: CreateFuelData) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.FUEL_COMBUSTION,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Fuel Combustion failed.";
  }
};

export const calculateFuelEmission = async (
  data: CalculateFuelEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      API_ENDPOINTS.FUEL_COMBUSTION_CALCULATE,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Calculate Fuel Emission failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Calculate Fuel Emission failed.";
  }
};

export const getFuelType = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.FUEL_TYPE);
    console.log("response of fuel type", response.data);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Fuel Type failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Fuel Type failed.";
  }
};

export const getLocattions = async () => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.LOCATION);
    console.log("response of get location", response.data.data);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Location API failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Location API failed";
  }
};
export const getAssetsByLocationId = async (locationId: number) => {
  console.log("Fetching assets for locationId:", locationId);
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.ASSETS_FOR_LOCATION,
      {
        params: { locationId },
      }
    );
    console.log("response of get location", response);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Asset By Location Id API failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Asset By Location Id API failed"
    );
  }
};

export const getMeasurementValue = async (fuelTypeId: number) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.MEASUREMENT_VALUE(fuelTypeId)
    );

    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Measurement data failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Measurement Data failed.";
  }
};

export const getEmissionFactor = async (data: EmissionFactorData) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.EMISSION_FACTOR(data.fuelTypeId, data.measureId)
    );
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Emission Factor failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Emission Factor failed.";
  }
};

export const getFuelCombustion = async (data: GetFuelCombustionData) => {
  console.log({ data });
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.FUEL_COMBUSTION, {
      params: {
        protocol: data.protocol,
        page: data.page,
        size: data.size,
        sort: data.sort,
        locationId: data.locationId,
        assetId: data.assetId,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Fuel Combustion failed.";
  }
};

export const uploadCsvFile = async (file: File, uploadUrl: string) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    console.log("Uploading CSV File:", formData);
    const response = await axiosInstance.post(
      API_ENDPOINTS.UPLOAD_CSV(uploadUrl),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("File Upload Response:", response);
    return response.data;
  } catch (error: any) {
    console.error("File upload failed", error.response?.data || error.message);
    throw error.response?.data?.message || "File upload failed.";
  }
};

export const getFuelCombustionById = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.FUEL_COMBUSTION_BY_ID(id)
    );
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Fuel Combustion By Id failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Fuel Combustion By Id failed.";
  }
};

export const getFuelCombustionByFileId = async (
  data: getFuelCombustionByFileIdData
) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.FUEL_COMBUSTION_BY_FILE_ID,
      {
        params: {
          fileId: data.fileId,
          page: data.page,
          size: data.size,
          sort: data.sort,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fuel Combustion Emission By File Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Fuel Combustion Emission By File Id failed."
    );
  }
};

export const updateFuelCombustion = async (
  id: number,
  data: UpdateFuelCombustionData
) => {
  try {
    const response = await axiosInstance.put(
      API_ENDPOINTS.FUEL_COMBUSTION_BY_ID(id),
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Fuel Combustion failed.";
  }
};

export const getFuelCombustionReason = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.FUEL_COMBUSTION_REASON(id)
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Fuel Combustion Reason failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Fuel Combustion Reason failed.";
  }
};

export const getUploadedFiles = async (data: GetUploadedFileData) => {
  try {
    const response = await axiosInstance.get(API_ENDPOINTS.EMISSION_FILE, {
      params: {
        search: data.search || "",
        typeOfEmission: data.typeOfEmission,
        page: data.page || 0,
        size: data.size || 10,
        sort: data.sort || "createdDate,desc",
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Error fetching uploaded files",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Error fetching uploaded files.";
  }
};

export const sampleDownloadTemplate = async (sampleName: string) => {
  try {
    const response = await axiosInstance.get(
      API_ENDPOINTS.SAMPLE_DOWNLOAD_TEMPLATE(sampleName)
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Sample Download Template failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Sample Download Template failed.";
  }
};
