import { axiosInstance } from "../utils/axios";

export interface CreateMobileSourceData {
  typeOfVehicleId: number;
  mobileSourceFuelTypeId: number;
  mobileSourceFuelUnitId: number;
  mobileSourceEmissionFactorId: number;
  vehicleQuantity: number;
  fuelConsumption: number;
  distanceTraveled: number;
  distanceTravelledUnit: string;
  protocol: string;
  mobileSourceDataCollectionMethod: string;
}

export interface MeasurementData {
  fuelTypeId: number;
}

export interface MobileEmissionFactorData {
  vehicleTypeId?: number;
  fuelTypeId: number;
}

export interface GetMobileSourceEmissionData {
  protocol?: string;
  page?: number;
  size?: number;
  sort?: string;
}

export interface getMobileSourceEmissionByFileIdData {
  fileId: number;
  page?: number;
  size?: number;
  sort?: string;
}

export interface GetFuelCombustionDataById {
  fuelCombustionEmissionId: number;
}

export interface UpdateMobileSourceData {
  typeOfVehicleId: number;
  mobileSourceFuelTypeId: number;
  mobileSourceFuelUnitId: number;
  mobileSourceEmissionFactorId: number;
  vehicleQuantity: number;
  fuelConsumption: number;
  distanceTraveled: number;
  distanceTravelledUnit: string;
  protocol: string;
  mobileSourceDataCollectionMethod: string;
}

export interface CalculateMobileEmissionData {
  mobileSourceEmissionFactorId: number;
  distanceTraveled: number;
  fuelConsumption: number;
}

export interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
    statusText?: string;
  };
  message?: string;
}

export const createMobileSource = async (data: CreateMobileSourceData) => {
  try {
    const response = await axiosInstance.post(`/mobile-source-emission`, data);
    console.log("response of api create mobile", response);
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Fuel Combustion failed.";
  }
};

export const getMobileVehicleType = async () => {
  try {
    const response = await axiosInstance.get("/type-of-vehicle");
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Vehicle Type failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Mobile Vehicle failed.";
  }
};

export const getMobileSourceType = async () => {
  try {
    const response = await axiosInstance.get(`/mobile-source-fuel-type`);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Source Type data failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message || "Get Mobile Source Type Data failed."
    );
  }
};

export const getMobileEmissionFactor = async (
  data: MobileEmissionFactorData
) => {
  try {
    const response = await axiosInstance.get(
      `/mobile-source-emission-factor/${data.vehicleTypeId}/${data.fuelTypeId}`
    );
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Emission Factor failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Get Mobile Emission Factor failed.";
  }
};

export const getMobileSourceEmission = async (
  data: GetMobileSourceEmissionData
) => {
  try {
    const response = await axiosInstance.get(`/mobile-source-emission`, {
      params: {
        protocol: data.protocol,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Source Emission By Protocol failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Mobile Source Emission By Protocol failed."
    );
  }
};

export const getMobileSourceEmissionById = async (id: number) => {
  try {
    const response = await axiosInstance.get(`/mobile-source-emission/${id}`);
    return response.data.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Source Emission By Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Mobile Source Emission By Id failed."
    );
  }
};

export const getMobileSourceEmissionByFileId = async (
  data: getMobileSourceEmissionByFileIdData
) => {
  try {
    const response = await axiosInstance.get(`/mobile-source-emission`, {
      params: {
        fileId: data.fileId,
        page: data.page,
        size: data.size,
        sort: data.sort,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error(
      "Get Mobile Source Emission By File Id failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Get Mobile Source Emission By File Id failed."
    );
  }
};

export const updateMobileSourceEmission = async (
  id: number,
  data: UpdateMobileSourceData
) => {
  try {
    const response = await axiosInstance.put(
      `/mobile-source-emission/${id}`,
      data
    );
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Mobile Source Emission data failed",
      error.response?.data || error.message
    );
    throw (
      error.response?.data?.message ||
      "Update Mobile Source Emission Data failed."
    );
  }
};

export const getMobileEmissionReason = async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/mobile-source-emission/${id}/reason`
    );
    console.log("reason api", response);
    return response.data;
  } catch (error: any) {
    console.error(
      "Update Fuel data failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Update Fuel Data failed.";
  }
};

export const calculateMobileEmission = async (
  data: CalculateMobileEmissionData
) => {
  try {
    const response = await axiosInstance.post(
      `/mobile-source-emission/calculate-emission`,
      data
    );
    console.log("response of calculate Emission Data", response.data);
    return response.data;
  } catch (error: any) {
    console.error(
      "Create Fuel Combustion failed",
      error.response?.data || error.message
    );
    throw error.response?.data?.message || "Create Fuel Combustion failed.";
  }
};
