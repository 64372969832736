import { z } from "zod";

export const ProcessSchema = z.object({
  processTypeId: z.string().min(1, "Fuel Type is required"),
  quantityOfMaterial: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine(
        (val) => val === null || val.toString().length >= 3,
        "Minimum 3 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  materialId: z.string().min(1, "Material Id is required"),
  materialUnitId: z.string().min(1, "Material Unit Id is required"),
  emissionFactorId: z.preprocess(
    (val) => (val === "" ? null : Number(val)),
    z
      .number()
      .nullable()
      .refine((val) => val === null || val > 0, "Must be a positive number")
      .refine(
        (val) => val === null || val.toString().length >= 1,
        "Minimum 1 digits are required"
      )
      .refine(
        (val) => val === null || val.toString().length <= 15,
        "Maximum 15 digits are allowed"
      )
      .refine(
        (val) => val === null || /^\d+(\.\d{1,3})?$/.test(val.toString()),
        "Decimal places up to 3 are allowed"
      )
  ),
  dataCollectionMethod: z.string().min(1, "Data Collection Method is required"),
  processDescription: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
  protocol: z.string().min(1, "Protocol is required"),
  locationName: z.string().min(1, "Location Name is required"),
  assetName: z.string().min(1, "Asset Name is required"),
  reason: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 3 && val.length <= 50), {
      message: "Reason must be between 3 and 50 characters if provided",
    }),
});
